import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaChevronRight, FaGraduationCap } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown } from "react-icons/fa";
import { AppContext } from '../context/AppContext';


const Navbar = ({ sticky, willSticky }) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);


  const [isSubMenu1Visible, setIsSubMenu1Visible] = useState(false);

  const { branches } = useContext(AppContext);
  const { translations } = useContext(AppContext);



  return (
    <>

{/* Mobile Menu */}
<div className={isMobileMenuVisible ? "vs-menu-wrapper vs-body-visible" : "vs-menu-wrapper"}>
  <div className="vs-menu-area text-center">
    <button className="vs-menu-toggle" onClick={() => setIsMobileMenuVisible(false)}>
      <FaTimes />
    </button>
    <div className="mobile-logo">
      <a href="index.html">
        <img src="logo.png" alt="Meetods" />
      </a>
    </div>
    <div className="vs-mobile-menu">
      <ul>
        <li className="menu-item">
          <Link to="/">
          {translations?.defs.home}
            <span className="vs-mean-expand" />
          </Link>
        </li>
        <li>
          <Link to="/about-us">{translations?.defs.aboutUs}</Link>
        </li>
        <li className="menu-item-has-children vs-item-has-children">
          <a className="d-flex justify-content-between" onClick={() => setIsSubMenu1Visible
        (!isSubMenu1Visible)}>
            {translations?.defs.usageAreas}
          <span role="button" >
          <FaChevronDown className={isSubMenu1Visible ? "icon-rotated" : "icon-default"} />
            </span>
          </a>
          <ul className="sub-menu vs-submenu" style={{ display: isSubMenu1Visible ? "block" : "none"}}>

          {branches.map((branch, index) => (
            <li className="d-flex align-items-center gap-2" key={index}>
            <FaChevronRight />
              <Link to={`/branch/${branch.id}`}>{branch.name}</Link>
            </li>
            ))}


          </ul>
        </li>
        <li className="menu-item">
          <Link to="/blog">
          {translations?.defs.blog}
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/pricing">
          {translations?.defs.packages}
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/how-it-works">
            {translations?.defs.howItWorks}
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/contact">
          {translations?.defs.contact}
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>



    <div className={willSticky ? "sticky-wrapper will-sticky" : "sticky-wrapper"}>
<nav className={sticky ? "navbar navbar-home navbar-expand-lg sticky active" : "navbar navbar-home navbar-expand-lg sticky"}>
  <div className="container">
    <Link to="/" className="navbar-brand">
      <img src="/logo.png" alt="Meetods" width={190} />
    </Link>
    <button onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)} className="vs-menu-toggle d-inline-block d-lg-none"><FaBars /></button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page">{translations?.defs.home}</Link>
        </li>
        <li className="nav-item">
          <Link to="/about-us" className="nav-link">
            {translations?.defs.aboutUs}
          </Link>
        </li>
        <li className="nav-item has-children">
          <a className="nav-link" href="#">{translations?.defs.usageAreas} <FaChevronDown size={10} /></a>
          <ul className="sub-menu">
            {branches.map((branch, index) => (
              <li key={index}><Link to={`/branch/${branch.id}`}>{branch.name}</Link></li>
            ))}
          </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/pricing">
          {translations?.defs.packages}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/how-it-works">
          {translations?.defs.howItWorks}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/blog">
          {translations?.defs.blog}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/contact-us" className="nav-link">
            {translations?.defs.contact}
          </Link>
        </li>
      </ul>
      <a href="https://app.meetods.com/register" className="vs-btn text-decoration-none try-btn">
        <span className="icon-grad"><FaGraduationCap size={27} /></span>
        {translations?.defs.tryFree}
      </a>
    </div>
  </div>
</nav>
</div>
    </>
  );
}

export default Navbar;
