import React, { useState, useEffect, useContext } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import FooterInner from '../components/FooterInner';
import { AppContext } from '../context/AppContext';

import { FaCheck } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from 'react-router-dom';
import PreLoader from '../components/PreLoader';


const Tutors = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [qualifiedTutors, setQualifiedTutors] = useState([]);
    const { translations } = useContext(AppContext);
    const [loading, setLoading] = useState(true);

    
    useEffect(() => {
      const fetchData = async (url, setter) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setter(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        finally {
          setLoading(false);
      }
      };
  
      fetchData(
        "https://webapi.meetods.com/api/v1/qualified-tutors",
        setQualifiedTutors
      );
      fetchData(
        "https://webapi.meetods.com/api/v1/testimonials",
        setTestimonials
      )
    }, []);

    if(loading) {
      return (
        <PreLoader />
      )
    }
    

  return (
    <Layout>
      <PageHeader title="Tutors" />
      <section className="space-top space-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex flex-column justify-content-center align-items-start h-100 ps-0 ps-lg-3">
            <h4 className="sec-subtitle">{translations?.sections.ourTutorsTitle}</h4>
            <h1 className="fw-bold display-5">
            {translations?.sections.ourTutorsSubtitle}
            </h1>
            <p>
            {translations?.sections.ourTutorsText}
              </p>
            <div className="list-style1 vs-list mb-2 mb-lg-4 pb-1 pt-3">
              <ul className="list-unstyled">
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.ourTutorsLine1}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.ourTutorsLine2}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.ourTutorsLine3}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.ourTutorsLine4}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="picture-box6">
  <div className="img-1 mega-hover">
    <img src={translations?.sections.ourTutorsImage} alt="online-class" />
  </div>
  <div className="vs-circle" />
</div>

        </div>
      </div>
    </div>
  </section>
  <section
    className="section-background space-top space-bottom"
  >
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="sec-icon">
              <div className="vs-circle"></div>
            </div>
            <h5 className="sec-subtitle">{translations?.sections.tutorsTitle}</h5>
            <h1 className="display-4 fw-semibold text-center">
              {translations?.sections.tutorsSubtitle}
            </h1>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-4 gy-3 my-5 pb-5 tutors-inner">
        {qualifiedTutors.map((tutor, index) => {
          const stars = Array.from(
            { length: tutor.rating },
            (_, index) => index + 1
          );
          return (
            <div className="col" key={index}>
              <Link to={`/tutors/${tutor.id}`}>
              <div className="tutor-card team-style2 has-border">
                <div className="team-content">
                  <h4 className="team-name">{tutor.name}</h4>
                  <h6 className="team-degi tutor-branch text-muted text-uppercase">
                    {tutor.branch}
                  </h6>
                  <span className="team-courses">
                    {tutor.lessons_completed}+ {translations?.defs?.lessonsCompleted}
                  </span>
                <div className="team-img">
                <img
                    src={tutor.avatar}
                    alt={tutor.name}
                  />
                </div>
                  <div>
                    <div className="mt-4">
                      <span className="team-rate tutor-price">
                        ${tutor.hourly_rate}
                        <span className="price-per">/ {translations?.defs?.perHour}</span>
                      </span>
                      <span className="team-experi d-block mb-2">
                        {tutor.experience}+ {translations?.defs?.yearsExperience}
                      </span>
                      <div className="team-review d-flex gap-1">
                        {stars.map((star, index) => (
                          <FaStar className="text-warning" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>

            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col">
        <div className="text-center">
  <p className="font-body fs-md fw-medium mb-2">
  {translations?.sections.callUsTitle}
  </p>
  <a className="call-number1 h5" href={translations?.sections.callUsNumber}>
  {translations?.sections.callUsNumber}
  </a>
</div>

        </div>
      </div>
    </div>
  </section>
  <section className="overflow-hidden bgc-f6 space-top space-extra-bottom shape-mockup-wrap">
  <div class="shape-mockup jump d-none d-xxl-block" style={{left: '-14%'}}><div class="vs-border-circle"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{top: '170px', right: '-15%'}}><div class="vs-circle color2"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{bottom: '40%', left: '8%'}}><div class="shape-dotted style2"></div></div>
    <div className="container">
        <div className="row">
            <div className="col">
            <div className="d-flex flex-column align-items-center">
                <h5 className="sec-subtitle">{translations?.sections.testiTitle}</h5>
                <h1 className="display-4 fw-semibold text-center mb-3 mb-md-2 mb-lg-5">
                {translations?.sections.testiSubtitle}
                </h1>
          </div>
            </div>
        </div>


        <div className="testi1-slider-wrapper row my-5">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={true}
              slidesToShow={3}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                  },
                },
              ]}

        >
            {testimonials.map((testi, index) => (


<div className="testi-style1" key={index}>
  <div className="testi-content">
    <p className="testi-text">{testi.review}</p>
  </div>
  <div className="testi-client">
    <img src={testi.user_avatar} alt="author" />
    <h3 className="testi-name h5">{testi.name}</h3>
    <span className="testi-degi">B.Tech-Civil , 2017-2022</span>
    <div className="testi-rating">
    {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
    </div>
  </div>
</div>


            ))}
        </Slider>
        </div>

        
    </div>
  </section>
    </Layout>
  );
}

export default Tutors;
