import React, { useState, useEffect, useContext } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from '../context/AppContext';
import Tutors from '../components/sections/Tutors';
import { FaStar, FaPlus, FaMinus } from "react-icons/fa";
import PageHeader from '../components/PageHeader';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { FaP } from 'react-icons/fa6';
import PreLoader from '../components/PreLoader';

const FindProgram = ({  programs }) => {

  const [searchTerm, setSearchTerm] = useState('');

  const [testimonials, setTestimonials] = useState([]);
  const [partners, setPartners] = useState([]);
  const { translations } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [academicFaqs, setAcademicFaqs] = useState([]);


  useEffect(() => {
    const fetchData = async (url, setter) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData(
        "https://webapi.meetods.com/api/v1/academic-faqs",
        setAcademicFaqs
    );
}, []);

const [activeFaqIndex, setActiveFaqIndex] = useState(0)

const handleSearch = (e) => {
  setSearchTerm(e.target.value)
}

function searchProgramsByName(programs, searchTerm) {
  return programs.filter(program => program.name.toLowerCase().includes(searchTerm.toLowerCase()));
}

const filteredPrograms = searchProgramsByName(programs, searchTerm);

console.log(filteredPrograms)


if(loading) {
  return (
    <PreLoader />
  )
}


  return (
    <Layout>
        <>
            <PageHeader title={translations?.defs.findProgram}  description={translations?.defs.find_program_desc} />
            <>
            <>
  <section className=" space-top space-extra-bottom">
    <div className="container">
      <div className="row align-items-center justify-content-center justify-content-xl-between flex-row-reverse">
        <div
          className="col-xl-5 col-xxl-auto wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="img-box1">
            <div className="vs-circle">
              <div className="mega-hover">
                <img src="assets/about-1-1.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-xxl text-center text-xl-start">
          <form action="#" className="form-style1 form-search">
            <h2 className="form-title h1">{translations?.sections.searchProgramTitle}</h2>
            <div className="row">
              <div className="form-group col-auto">
                <input
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  defaultValue="option1"
                />
                <label htmlFor="inlineRadio1">{translations?.defs.undergra_programs}</label>
              </div>
              <div className="form-group col-auto">
                <input
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  defaultValue="option2"
                  defaultChecked=""
                />
                <label htmlFor="inlineRadio2">{translations?.defs.gra_programs}</label>
              </div>
              <div className="form-group col-12">
                <div className="form-inner">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e)}
                    placeholder={translations?.actions.search}
                  />
                  <button className="icon-btn">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="row gx-40 justify-content-center justify-content-xl-start">
            <div className="col-auto">
              <div className="media-style3">
                <div className="media-icon">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="media-body">
                  <span className="media-title mb-0">{translations?.sections.searchProgramFeature1Title}</span>
                  <p className="media-text">
                  {translations?.defs.searchProgramFeature1Text}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="media-style3">
                <div className="media-icon">
                  <i className="fas fa-user-tie" />
                </div>
                <div className="media-body">
                  <span className="media-title mb-0">{translations?.sections.searchProgramFeature2Title}</span>
                  <p className="media-text">
                  {translations?.sections.searchProgramFeature2Text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
  Program Table
  ==============================*/}
  <div className="space-extra-bottom">
    <div className="container">
      <div className="table-style2">
        <table>
          <thead>
            <tr>
              <th>
                <Link to="/">
                  {translations?.defs.including_m_m}
                </Link>
              </th>
              <th>{translations?.defs.type_of_degree}</th>
            </tr>
          </thead>
          <tbody>
            {programs?.map((program, index) => (
              <tr key={index}>
              <td>
              <Link to={`/academic-programs/${program.id}`}>
                {program.name}
              </Link>
              </td>
              <td>{program.type_of_degree}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  {/*==============================
  CTA Area
==============================*/}
  <section className="space-bottom">
    <div className="container">
      <div className="cta-style2">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="cta-content">
              <p className="cta-text">{translations?.sections.awardTutorsTitle}</p>
              <h2 className="cta-title h1">
              {translations?.sections.awardTutorsSubtitle}
              </h2>
              <Link to="/team" className="vs-btn style2">
                <i className="fa fa-angle-right" />
                {translations?.defs.findTutor}
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cta-img">
              <img src= {translations?.sections.awardTutorsVideoCover} />
              <a
                href={translations?.sections.awardTutorsVideoUrl}
                className="play-btn popup-video position-center"
              >
                <i className="fa fa-play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
  FAQ Area
==============================*/}
  <section className=" space-extra-bottom">
    <div className="container">
      <div className="row gx-50">
        <div className="col-lg-7 col-xl-8 mb-40 mb-lg-0">
          <div
            className="title-area wow fadeInUp text-center text-lg-start"
            data-wow-delay="0.1s"
          >
            <span className="sec-subtitle">{translations?.sections.academicFaqsTitle}</span>
            <h2 className="sec-title h1">{translations?.sections.academicFaqsSubtitle}</h2>
          </div>
          <div className="accordion-style1 wow fadeInUp" data-wow-delay="0.2s">
            <div className="accordion" id="faqVersion1">
              {academicFaqs.map((faq, index) => (
              <div className={activeFaqIndex === index ? "accordion-item active" : "accordion-item"} key={index}   
              >
              <div className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  onClick={() => {
                    if (activeFaqIndex === index) {
                      setActiveFaqIndex(null);
                    } else {
                      setActiveFaqIndex(index);
                    }
                  }}

                >
                  <span className={activeFaqIndex === index ? "accordion-icon text-white" : "accordion-icon text-dark"} >
                  {activeFaqIndex === index ? (
                    <FaMinus size="16" />
                  ) :( 
                  <FaPlus size="16" />)}
                  </span>
                  {faq.question}
                </button>
              </div>
              <div
                className= {activeFaqIndex === index ? "accordion-collapse collapsed" : "accordion-collapse collapse"} 
              >
                <div className="accordion-body">
                  <p>{faq.answer}</p>
                </div>
              </div>
            </div>
              ))}
            </div>
            <span className="support-link">
              {translations?.defs.support_text} {" "}
              <Link to="/contact-us">{translations?.defs.help_center_label}</Link> 
            </span>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="col-md-7 col-lg-12">
              <div
                className="img-box5 mega-hover wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="img-1">
                  <img
                    className="w-100"
                    src="assets/about-2-4.jpg"
                    alt="About Img"
                  />
                </div>
                <div className="box-content">
                  <h3 className="img-title">{translations?.sections.onlineRegiTitle}</h3>
                  <p className="img-text">
                  {translations?.sections.onlineRegiSubtitle}
                  </p>
                  <Link to="/contact-us" className="vs-btn">
                   {translations?.actions.applyToday}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-12">
              <div className="media-style5 wow fadeInUp" data-wow-delay="0.4s">
                <span className="icon">
                  <i className="fa fa-headset" />
                </span>
                <h5 className="media-title">{translations?.defs.admission_process}</h5>
                <a className="phone" href={`tel:${translations?.defs.phone_number}`}>
                  <i className="fa fa-phone-alt" />
                  {translations?.defs.phone_number}
                </a>
                <a className="mail" href={`mailto:${translations?.defs.mail}`}>
                  <i className="fa fa-envelope" />
                  {translations?.defs.mail}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

</>

        </>
    </Layout>
  );
}

export default FindProgram;
