import React, {useState, useContext, useEffect} from 'react';
import {redirect, useNavigate} from 'react-router-dom';

import {AppContext} from '../../context/AppContext';
import Select from 'react-select'
import Swal from 'sweetalert2';
import {useMask} from "@react-input/mask";
import InputMask from "react-input-mask";

const Hero = ({}) => {
    const {translations} = useContext(AppContext);
    const {user, setUser} = useContext(AppContext);
    const {participants, setParticipants} = useContext(AppContext);


    const [isUserFoundModalVisible, setIsUserFoundModalVisible] = useState(false);


    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [isEmailValid, setIsEmailValid] = useState();


 
    const handleFirstNameChange = (e) => {

        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {

        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {

        const emailInput = document.getElementById("email")
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newEmail = e.target.value;
        setEmail(newEmail)

        const isValidEmail = emailRegex.test(newEmail)
        if (!isValidEmail) {
            emailInput.classList.add("is-invalid");
            emailInput.classList.remove("is-valid");
            setIsEmailValid(false)
        } else {
            emailInput.classList.add("is-valid");
            emailInput.classList.remove("is-invalid");
            setIsEmailValid(true)
        }
    };
    const handlePhoneChange = (e) => {
        const newPhoneValue = e.target.value;
        // const newPhoneValue = e.target.value.replace(/[^0-9]/g, '');

        setPhone(newPhoneValue);
    };


    const [countryCodes, setCountryCodes] = useState([]);

    const [selectedCountryCode, setSelectedCountryCode] = useState(null);
    const handleCountryCodeChange = (selectedOption) => {
        setSelectedCountryCode(selectedOption.value);
    };
    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch(`https://app.meetods.com/api/v1/country/phones`);
                const data = await response.json();
                if (data.status) {
                    const formattedData = data.data.map((country) => ({
                        value: country.id,
                        label: country.name,
                    }));
                    const turkeyCountry = formattedData.find((country) => country.value === '+90');
                    if (turkeyCountry) {
                        setSelectedCountryCode(turkeyCountry.value);
                    }
                    setCountryCodes(formattedData);
                } else {
                    console.error('API error:', data.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchCountryCodes();
    }, []);


    useEffect(() => {
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('email', email);
        localStorage.setItem('phone_number', phone);
        localStorage.setItem('country', selectedCountryCode);
    }, [firstName, lastName, email,phone, selectedCountryCode]);


    const [isEmailExists, setIsEmailExists] = useState(false);

    const [clientUrl, setClientUrl] = useState();

    const handleApply = async (e) => {
        e.preventDefault();

        let requiredInputs;
        requiredInputs = [
            {name: "firstName", value: firstName},
            {name: "lastName", value: lastName},
            {name: "email", value: email},
            {name: "phone", value: phone},
        ];

        const apiUrl = "https://app.meetods.com/api/v1/authorize/check";

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            });

            const data = await response.json();

            if (response.status === 200 && data.status === true) {
                Swal.fire({
                    title: 'Bu kullanıcı mevcut',
                    text: "Lütfen önce giriş yapın",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Giriş Yap",
                    cancelButtonText: "Tekrar Dene",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const url = new URL(data.link);

                        if (url.origin !== window.location.origin) {
                            window.open(data.link, '_blank');
                        } else {
                            navigate(data.link);
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setEmail('');
                    }
                });
                return;
            } else if (data.status === false && data.message === "Non exists customer") {
                // Kullanıcı bulunamadı

                const isInputsValid = requiredInputs.every((input) => input.value.trim() !== "" && isEmailValid && selectedCountryCode);

                if (isInputsValid) {
                    navigate("/booking");
                } else {
                    requiredInputs.forEach((input) => {
                        if (input.value.trim() === "") {
                            document.getElementById(input.name).classList.add("is-invalid");
                        } else {
                            document.getElementById(input.name).classList.remove("is-invalid");
                        }
                    });

                    if (!isEmailValid) {
                        document.getElementById("email").classList.add("is-invalid");
                    } else {
                        document.getElementById("email").classList.remove("is-invalid");
                    }
                }
            } else {
                console.log("Bilinmeyen bir durum oluştu:", data.message);
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    const [mediaLoading, setMediaLoading] = useState(true);


    const inputRef = useMask({ mask: '(___) ___-__-__', replacement: { _: /\d/ } });

    return (
        <>
            <section className="hero-layout shape-mockup-wrap">
                <div class="shape-mockup jump-img d-none d-xxxl-block" style={{top: "250px", left: "-260px"}}>
                    <div class="vs-border-circle"></div>
                </div>
                <div class="shape-mockup jump-reverse d-none d-xxxl-block" style={{right: '17%', bottom: '50%'}}>
                    <div class="shape-dotted"></div>
                </div>
                <div class="shape-mockup rotate d-none d-xxxl-block" style={{top: '33%', left: '45%'}}><div class="vs-polygon1"></div></div>
                <div class="shape-mockup jump d-none d-xxxl-block" style={{top: '40%', left: '44%'}}><div class="vs-rounded1"></div></div>
                <div className="container">
                    <div className="row" style={{ paddingTop: 100 }}>
                        <div className="col-lg-4 z-2">
                            <div className="hero-content">
                                <h1 className="hero-title display-3 mt-5">
                                    {translations?.sections.heroTitle}
                                </h1>
                                <p className="hero-text mb-5">
                                    {translations?.sections.heroSubtitle}
                                </p>
                                <form className="form-style3 mb-3 mb-lg-0 mb-md-0">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control rounded-pill p-3 transition-none"
                                                    name="firstName"
                                                    id="firstName"
                                                    value={firstName}
                                                    onChange={handleFirstNameChange}
                                                    placeholder={translations?.defs.name}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control rounded-pill p-3 transition-none"
                                                    name="lastName"
                                                    id="lastName"
                                                    value={lastName}
                                                    onChange={handleLastNameChange}
                                                    placeholder={translations?.defs.lastName}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="mb-3">
                                        <input
                                            type="mail"
                                            className="form-control rounded-pill p-3 transition-none"
                                            name="email"
                                            id="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder={translations?.defs.enterEmail}
                                        />
                                    </div>


                                    <div
                                        className="d-flex align-items-center justify-content-start gap-2 z-3 position-relative mb-3">
                                        <div className="w-25">
                                            <Select
                                                id="countryCode"
                                                options={countryCodes}
                                                value={selectedCountryCode}
                                                onChange={handleCountryCodeChange}
                                                placeholder={selectedCountryCode ? selectedCountryCode : "Ülke"}
                                                className={"rounded-pill"}
                                            />
                                        </div>
                                        <div className="w-75">
                                        <InputMask
                        mask="(999) 999-99-99"
                                                type="text"
                                                className="form-control rounded-pill p-3 transition-none"
                                                name="phone"
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                id="phone"
                                                placeholder={translations?.defs.enterPhoneNumber}
                                                // pattern="[0-9]*"
                                            //    ref={inputRef}
                                            />
                                        </div>
                                    </div>
                                    <button className="vs-btn w-100 fw-bold" onClick={(e) => handleApply(e)}>
                                        {translations?.actions.applyToday}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8 z-1 d-flex align-items-center">
                                <div className="picture-box4 position-relative">
                                <div className="hero-banner-wrapper position-relative">
                                    <div>
                                        <img
                                            className="hero-full-banner"
                                            src="assets/computer-screen-mockup.png"
                                        />
                                        <div class="vs-circle"></div>
                                    </div>
                                </div>
                                <div class="media-style7 d-none">
                                    <h3 class="media-title">{translations?.defs.onlineTuition}</h3>
                                    <p class="media-text">{translations?.defs.clickToStart}</p>
                                    <button type="button" class="vs-btn">
                                        {translations?.actions.meetTutor}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
