import React, { useState, useEffect, useContext } from 'react';
import { FaStar } from "react-icons/fa";
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';



const Tutors = ({}) => {
  const [qualifiedTutors, setQualifiedTutors] = useState([]);
  const { translations } = useContext(AppContext);


  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(
      "https://webapi.meetods.com/api/v1/qualified-tutors",
      setQualifiedTutors
    );
  }, []);
    
  return (
    <section
    className="section-background space-top space-bottom"
    style={{ backgroundImage: "url('/assets/course-bg-pattern.jpg')" }}
  >
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="sec-icon">
              <div className="vs-circle"></div>
            </div>
            <h5 className="sec-subtitle">{translations?.sections.tutorsTitle}</h5>
            <h1 className="display-4 fw-semibold text-center">
              {translations?.sections.tutorsSubtitle}
            </h1>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-4 gy-3 my-5 pb-5">
        {qualifiedTutors.map((tutor, index) => {
          const stars = Array.from(
            { length: tutor.rating },
            (_, index) => index + 1
          );
          return (
            <div className="col" key={index}>
              <div className="card tutor-card">
                <div className="card-body">
                  <h4 className="tutor-name">{tutor.name}</h4>
                  <h6 className="tutor-branch text-muted text-uppercase">
                    {tutor.branch}
                  </h6>
                  <span className="tutor-courses">
                    {tutor.lessons_completed}+ {translations?.defs?.lessonsCompleted}
                  </span>
                  <img
                    src={tutor.avatar}
                    alt={tutor.name}
                    width={140}
                    height={140}
                    className="rounded-circle"
                  />
                  <div>
                    <div className="mt-4">
                      <span className="tutor-price">
                        {tutor.hourly_rate}+
                        <span className="price-per text-capitalize">Saat Görüşme</span>
                      </span>
                      <span className="d-block mb-2">
                        {tutor.experience}+ {translations?.defs?.yearsExperience}
                      </span>
                      <div className="tutor-review d-flex gap-1">
                        {stars.map((star, index) => (
                          <FaStar key={index} className="text-warning" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </section>
  );
}

export default Tutors;
