import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { FaCircleCheck } from "react-icons/fa6";
import { BsArrowRight, BsArrowLeft, BsFillPeopleFill } from "react-icons/bs";

const Register = () => {
const [showInformationSteps, setShowInformationSteps] = useState(false);

const handleRegister = () => {
    setShowInformationSteps(true)
}

 useEffect(() => {
   window.scrollTo(0, 0);
  }, []);

  const categories = [
    {
        id: 1,
        name: "Consulting",
    },
    {
        id: 2,
        name: "Marketing",
    },
    {
        id: 3,
        name: "IT Services",
    },
    {
        id: 4,
        name: "Education",
    },
    {
        id: 5,
        name: "Coaching & Training",
    },
    {
        id: 6,
        name: "Healthcare",
    },
    {
        id: 7,
        name: "Wellness & Fitness",
    },
    {
        id: 8,
        name: "Sales",
    }
  ]

  const [selectedCategory, setSelectedCategory] = useState();
  const [currentInformationStep, setCurrentInformationStep] = useState(1);

  const informationSteps = [
    {
        number: 1,
        title: "Let's Create Your Page!",
        description: "Help us personalize your experience by telling us your industry.",
        content: ""
    },
    {
        number: 2,
        title: "Another Title For Another Step",
        description: "Description text",
        content: ""
    },
    {
        number: 3,
        title: "Another Title For Another Stepp",
        description: "Description textp",
        content: ""
    },
    {
        number: 4,
        title: "Another Title For Another Stepp",
        description: "Description textp",
        content: ""
    }
  ]

  const handleNextStep = () => {
    setCurrentInformationStep(currentInformationStep + 1)
  }
  const handlePrevStep = () => {
    setCurrentInformationStep(currentInformationStep - 1)
  }

  let maxSteps = informationSteps.length;
  
  return (
    <Layout>
      <div className="space-top space-bottom">
        {informationSteps  
        .filter(step => step.number === currentInformationStep)
        .map((step, index) => {
            const steps = Array.from(
            { length: maxSteps },
            (_, index) => index + 1
            );
            return (

            <div className="information-step mx-auto" key={index}>
            <div className="step-indicators">
                {steps.map((item, index) => (
                    <div className={index + 1 === step.number ? "step-indicator active" : "step-indicator"} key={index}></div>
                ))}
            </div>
            <h3 className="text-center">{step.title}</h3>
            <span>{step.description}</span>
            <div className="step-content row my-3">
            {step.number === 1 && (
                <>
                {categories.map((category, index) => (
                    <div className="col-lg-6" key={index} >
                    <div className={selectedCategory === category.id ? "category-element active" : "category-element"} onClick={() => setSelectedCategory(category.id)}>
                        {category.name} 
                        <span className={selectedCategory === category.id ? "category-element-icon show" : "category-element-icon"}>
                            <FaCircleCheck />
                        </span>
                    </div>
                    </div>
                ))}
                </>
        )}
            </div>
            <div className="row">
                <div className="col">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                    <span role="button" style={{ cursor: 'pointer', visibility: step.number > 1 ? "visible" : "hidden" }} onClick={() => handlePrevStep()}>
                        <BsArrowLeft size="24" color="#000" />
                    </span>
                    {step.number === 4 ? (
                        <button className="vs-btn fw-bold">
                        Finish
                        </button>
                    ) : (
                        <button className="vs-btn fw-bold" onClick={() => handleNextStep()}>
                        Next
                        </button>
                    )}
                    </div>
                </div>
            </div>
        </div>
        )
})}
        {showInformationSteps && (
            <div className="information-step mx-auto">
                <div className="step-indicators">
                    <div className="step-indicator"></div>
                    <div className="step-indicator active"></div>
                    <div className="step-indicator"></div>
                    <div className="step-indicator"></div>
                </div>
                <h3 className="text-center">Let's Create Your Page!</h3>
                <span>Help us personalize your experience by telling us your industry.</span>
                <div className="row my-3">
                {categories.map((category, index) => (
                    <div className="col-lg-6" key={index} >
                    <div className={selectedCategory === category.id ? "category-element active" : "category-element"} onClick={() => setSelectedCategory(category.id)}>
                        {category.name} 
                        <span className={selectedCategory === category.id ? "category-element-icon show" : "category-element-icon"}>
                            <FaCircleCheck />
                        </span>
                    </div>
                    </div>
                ))}
                </div>
                <div className="row">
                    <div className="col">
                        <div className="d-flex w-100">
                            <button className="vs-btn fw-bold mx-auto">
                            Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )}
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                    <div class="form-style4 signup background-image" style={{ backgroundImage: "url('course-bg-pattern.jpg')" }}>
                        <h2 class="form-title">LOGIN</h2>
                        <div class="form-group">
                            <input type="text" autocomplete="off" name="signupemail" id="signupemail" placeholder="Email address" />
                        </div>
                        <div class="form-group">
                            <input type="text" autocomplete="off" name="signupphone" id="signupphone" placeholder="Password" />
                        </div>
                        <div class="row justify-content-between">
                            <div class="col-auto form-group">
                                <input type="checkbox" name="rememberlogin" id="rememberlogin"  />
                                <label for="rememberlogin">Remember me</label>
                            </div>
                            <div class="col-auto form-group">
                                <a class="forget-link" href="#">FORGET A PASSWORD?</a>
                            </div>
                        </div>
                        <button type="button" class="vs-btn">Login</button>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-style4 signup background-image" style={{ backgroundImage: "url('course-bg-pattern.jpg')" }}>
                        <h2 class="form-title">SIGN UP</h2>
                        <div class="form-group">
                            <input type="text" autocomplete="off" name="signupname" id="signupname" placeholder="Complete Name" />
                        </div>
                        <div class="form-group">
                            <input type="text" autocomplete="off" name="signupemail" id="signupemail" placeholder="Email address" />
                        </div>
                        <div class="form-group">
                            <input type="text" autocomplete="off" name="signupphone" id="signupphone" placeholder="Password" />
                        </div>
                        <button type="button" class="vs-btn" onClick={() => handleRegister()}>Register</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Layout>
  );
}

export default Register;
