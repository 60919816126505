import React  from 'react';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Calendar from "react-calendar";

const DatePicker = ({datePicking, selectedDate}) => {
    const formatDate = (date) => {
        // YYYY-MM-dd
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    }
    return (
        <Calendar
            minDate={new Date()}
            calendarType="gregory"
            locale="tr"
            prevLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
            onChange={(date) => datePicking(formatDate(date))}
            defaultValue={selectedDate ? new Date(selectedDate) : null}
      />
    )
}

export default DatePicker;