import React, {useContext, useEffect} from 'react';
import {FaCircleCheck} from "react-icons/fa6";


const ExpertStep = ({item, orderData}) => {
    let expert = item.item;

    return (
        <label className="col expert-label" htmlFor={`package-user-${item.id}`}>
            <div>
                <div className={"selectable-element"}>
                    <img src={item.avatar} width={70} height={70} alt=""/>
                    {item.name}
                    <span
                        className={orderData.package_user_id == item.id ? 'selectable-element-icon show' : 'selectable-element-icon'}>
                            <FaCircleCheck/>
                        </span>
                </div>
                <div className="form-check">
                    <input
                        type="radio"
                        name="package_user_id"
                        className=""
                        id={`package-user-${item.id}`}
                        value={`${item.id}`}
                        defaultChecked={orderData.package_user_id == item.id}
                    />
                </div>
            </div>
        </label>
    );
};

export default ExpertStep;
