import React, { useContext, useEffect } from 'react';
import { FiMonitor } from "react-icons/fi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiDisc } from "react-icons/fi";

const PackageStep = ({ pack, orderData }) => {
    useEffect(() => {
        const radio = document.querySelectorAll('input[name="package_id"]');
        radio.forEach((item) => {
        item.addEventListener("change", function () {
            radio.forEach((item) => {
            item.closest("label").classList.remove("border", "border-1","border-primary");
            });
            this.closest("label").classList.add("border", "border-1","border-top-3","border-primary");
            document.getElementsByClassName("dynamic-meet-package")[0].innerHTML = this.dataset.name;
            document.getElementsByClassName("dynamic-meet-duration")[0].innerHTML = this.dataset.duration;
        });
        });

        // if orderData.package_id is not null, then click the radio button
        if (orderData.package_id) {
        document.querySelector(`input[value="${orderData.package_id}"]`).click();
        }
    }, []);

  return (
    <label className="card package-card shadow-sm p-3 mb-3" htmlFor={`package-${pack.id}`}>
      <div className="d-flex flex-column">
        <h5 className="text-center mb-3 fw-normal">{pack.name}</h5>
        <div className="border-top pt-2 d-flex justify-content-between">
        <p>{pack.description}</p>
        <p className="fw-bold text-primary">${pack.hourly_rate}</p>
        </div>
        <h6 className="fw-normal"><FiMonitor size="24" className="text-primary me-2" />Online meeting</h6>
        <h6 className="fw-normal"><AiOutlineClockCircle size="24" className="text-primary me-2" />{pack.duration} mins</h6>
        
         <ul className="list-unstyled mt-2">
          {pack.features.map((feature, index) => (
            <li key={index}>
              <FiDisc className="text-primary" size={14} />
              <span className="ps-2">{feature}</span>
            </li>
          ))}
          </ul>  
        <div className="form-check">
          <input
            type="radio"
            name="package_id"
            className=""
            id={`package-${pack.id}`}
            value={`${pack.id}`}
            data-duration={pack.duration + " Dakika"}
            data-name={pack.hourly_rate + " ₺"}
          />

        </div>
      </div>
    </label>
  );
};

export default PackageStep;
