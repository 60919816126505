import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { FaArrowUp } from 'react-icons/fa';
import FooterInner from './FooterInner';


function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? 'down' : 'up';

      if (direction === 'up' && scrollY <= window.innerHeight) {
        setScrollDirection(null);
      } else {
        setScrollDirection(direction);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', updateScrollDirection);

    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, []);

  return scrollDirection;
}


const Layout = ({ children, home }) => {
  const scrollDirection = useScrollDirection();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [willSticky, setWillSticky] = useState(false);


  useEffect(() => {

    const getStickyState = () => {
      if (window.scrollY >= window.innerHeight) {
        setWillSticky(true);
        setShowBackToTop(true)
      } else {
        setWillSticky(false)
        setShowBackToTop(false)
      }
    };

    window.addEventListener('scroll', getStickyState);

    return () => {
      window.removeEventListener('scroll', getStickyState);
    };
  }, []);

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  return (
    <div>
      <Navbar sticky={scrollDirection === 'up'} willSticky={willSticky} />
        <div className="space-layout">
            { children }
        </div>
        {home ? (
          <FooterInner />
        ) : (
          <FooterInner />
        )}
      
      <span role="button" 
      className={showBackToTop ? "back-to-top z-2 on" : "back-to-top z-2"} 
      onClick={() => backToTop()}>
        <FaArrowUp color="white" />
        </span>
    </div>
  );
}

export default Layout;
