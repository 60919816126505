import React, { useState, useEffect, useContext } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import FooterInner from '../components/FooterInner';
import { AppContext } from '../context/AppContext';
import HowItWorks from '../components/sections/HowItWorks';

import { FaCheck } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreLoader from '../components/PreLoader';


const FindTutor = () => {
    const [partners, setPartners] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);

    const { translations } = useContext(AppContext);
  
  
    useEffect(() => {
        const fetchData = async (url, setter) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData(
          "https://webapi.meetods.com/api/v1/popular-partners",
          setPartners
        )
        fetchData(
          "https://webapi.meetods.com/api/v1/testimonials",
          setTestimonials
        )
      }, []);
  
      if(loading) {
        return (
          <PreLoader />
        )
      }
      
  return (
    <Layout>

<>
  <section className="background-image shape-mockup-wrap" style={{ backgroundImage: "url('tutor-bg-1.jpg')" }}>
    <div
      className="shape-mockup jump-reverse d-none d-xxxl-block"
      style={{ right: "9%", bottom: "30%"  }}
    >
      <img src="shape/dotted-1.png" alt="shape" />
    </div>
    <div
      className="shape-mockup rotate d-none d-xxxl-block"
      style={{ left: "49%", top: "26%"  }}
    >
      <div className="vs-polygon1" />
    </div>
    <div
      className="shape-mockup jump d-none d-xxxl-block"
      style={{ left: "46%", top: "26%"  }}
    >
      <div className="vs-rounded1" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xxl-5 align-self-center text-center text-md-start pb-3 pb-md-5 pt-5">
          <h2 className="sec-title2">Find a Tutor</h2>
          <p className="fs-20 col-xl-9 mb-4 pb-xl-2">
            We hand pick the right UK based tutor to suit your child’s needs.
          </p>
          <a className="vs-btn style3" href="team.html">
            <i className="fa fa-angle-right" />
            Meet Your Tutor
          </a>
        </div>
        <div className="col-md-6 col-xxl-auto align-self-end">
          <div className="picture-box4">
            <div className="img-1">
              <img src="about-tutor-1.png" alt="girl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
    About Area
==============================*/}
  <section className="space-top space-extra-bottom">
    <div className="container">
      <div className="row gx-90 align-items-center">
        <div className="col-lg-6 col-xl-auto">
          <div className="picture-box5">
            <div className="picture-1 mega-hover">
              <img src="find-tutor-1.jpg" alt="Find Tutor" />
            </div>
            <div className="picture-2 mega-hover">
              <img src="find-tutor-2.jpg" alt="Find Tutor" />
            </div>
            <div className="shape-dotted jump" />
          </div>
        </div>
        <div className="col-lg-6 col-xl-5 ">
          <span className="sec-subtitle">PERSONALISED TUTORING.</span>
          <h2 className="sec-title mb-3 mb-lg-4">
            Find the best way to learn.
          </h2>
          <p className="fs-md me-xxl-5">
            Ducamb welcomed every pain avoided but in certa mstances owing to
            the claims of igation that off bu will frequently occuthe
            obligations .
          </p>
          <div className="list-style1 vs-list  mb-4">
            <ul>
              <li className="ps-0"><FaCheck className="text-primary" /> Steady stream of new students</li>
              <li className="ps-0"><FaCheck className="text-primary" /> Smart calendar</li>
              <li className="ps-0"><FaCheck className="text-primary" /> Interactive classroom</li>
              <li className="ps-0"><FaCheck className="text-primary" /> Convenient payment methods</li>
              <li className="ps-0"><FaCheck className="text-primary" /> Professional development webinars</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
   */}
   <HowItWorks />






   <>
   <section className="space-extra-bottom">
    <div className="container">
      <div
        className="row vs-carousel"
        data-slide-show={4}
        data-md-slide-show={3}
        data-sm-slide-show={2}
        data-xs-slide-show={2}
      >
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature1Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature1Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature1Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature2Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature2Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature2Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature3Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature3Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature3Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature4Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature4Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature4Text}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
   */}
</>





   <section className="overflow-hidden bgc-f6 space-top space-extra-bottom shape-mockup-wrap">
  <div class="shape-mockup jump d-none d-xxl-block" style={{left: '-14%'}}><div class="vs-border-circle" style={{ width: 400, height: 400 }}></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{top: '170px', right: '-15%'}}><div class="vs-circle color2"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{bottom: '40%', left: '8%'}}><div class="shape-dotted style2"></div></div>
    <div className="container">
        <div className="row">
            <div className="col">
            <div className="d-flex flex-column align-items-center">
                <h5 className="sec-subtitle">{translations?.sections.testiTitle}</h5>
                <h1 className="display-4 fw-semibold text-center mb-3 mb-md-2 mb-lg-5">
                {translations?.sections.testiSubtitle}
                </h1>
          </div>
            </div>
        </div>


        <div className="testi1-slider-wrapper row my-5">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={true}
              slidesToShow={3}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                  },
                },
              ]}

        >
            {testimonials.map((testi, index) => (


<div className="testi-style1" key={index}>
  <div className="testi-content">
    <p className="testi-text">{testi.review}</p>
  </div>
  <div className="testi-client">
    <img src={testi.user_avatar} alt="author" />
    <h3 className="testi-name h5">{testi.name}</h3>
    <span className="testi-degi">B.Tech-Civil , 2017-2022</span>
    <div className="testi-rating">
    {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
    </div>
  </div>
</div>


            ))}
        </Slider>
        </div>

        
    </div>
  </section>
</>

<>
<section className="space-top space-extra-bottom">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-7 text-center text-xl-start">
          <div className="title-area">
            <span className="sec-subtitle">
            {translations?.sections.trainingTitle}
            </span>
            <h2 className="sec-title h1">{translations?.sections.trainingSubtitle}</h2>
          </div>
          <div className="row gx-80 gy-xl-4 mb-4 mb-xl-0">
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox1Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox1Title}</h5>
                <p>
                {translations?.sections.trainingBox1Text}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox2Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox2Title}</h5>
                <p>
                {translations?.sections.trainingBox2Text}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox3Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox3Title}</h5>
                <p>
                {translations?.sections.trainingBox3Text}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox4Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox4Title}</h5>
                <p>
                {translations?.sections.trainingBox4Text}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInUp" data-wow-delay="0.4s">
          <div className="position-relative">
            <form action="#" className="form-style2">
              <div className="form-inner">
                <h3 className="form-title h5">
                {translations?.sections.trainingFormTitle}
                </h3>
                <div className="row">
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={translations?.sections.trainingFormName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder={translations?.sections.trainingFormMail}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <select name="coursenames" id="coursenames">
                        <option selected="" disabled="" hidden="">
                        {translations?.sections.trainingFormSelect}
                        </option>
                        <option>Development</option>
                        <option>Ui Development</option>
                        <option>CMS Learning</option>
                        <option>Jamstack Master</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        id="phone"
                        placeholder={translations?.sections.trainingFormPhone}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="button" className="vs-btn">
                    {translations?.actions.applyToday}
                    </button>
                    <a className="form-link" href="about.html">
                    {translations?.sections.faq}
                    </a>
                  </div>
                </div>
              </div>
              <div className="vs-circle color2" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
   */}
</>
<section className="space-extra-bottom">
    <div className="container">
      <div
        className="title-area text-center wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <div className="sec-icon">
          <div className="vs-circle" />
        </div>
        <span className="sec-subtitle">{translations?.sections.topCompaniesTitle}</span>
        <h2 className="sec-title h1 mx-auto">{translations?.sections.topCompaniesSubtitle}</h2>
      </div>
      <div className="row">
      {partners.slice(0,6).map((partner, index) => (
        <div className="col-6 col-lg-4 col-xl-3" key={index}>
        <div className="brand-style1 partner-card-img">
          <img src={partner.image} alt="brand" />
        </div>
      </div>
        ))}
        <div className="col-xl-6">
          <div className="rounded-20 overflow-hidden mega-hover">
            <img
              className="w-100"
              src="about-2-1.jpg"
              alt="brand"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  );
}

export default FindTutor;
