import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../components/Layout';
import { useParams } from "react-router-dom";
import PageHeader from '../../components/PageHeader';
import { AppContext } from '../../context/AppContext';
import parse from 'html-react-parser'
import { Link, useLocation } from 'react-router-dom';
import { FaLink } from "react-icons/fa6";

const BlogDetail = ({ blogs }) => {
  const location = useLocation();

  const { id } = useParams();

    const { translations } = useContext(AppContext);
    const [blog, setBlog] = useState()
    
    useEffect(() => {
      const fetchData = async (url, setter) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setter(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      if (id && (!blog || blog.id !== parseInt(id))) {
        fetchData(
          `https://webapi.meetods.com/api/v1/blogs/${id}`,
          setBlog
        );
      }
    }, [id, blog]);

    const created = blog?.created_at;
    const dateObject = new Date(created);
    const formattedDate = dateObject.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long' });

    const htmlString = blog?.content;

    useEffect(() => {
      const currentBlog = blogs.find((item) => item.id === blog?.id);
      console.log("currentBlog:", currentBlog); 
      setBlog(currentBlog);
    }, [id]);
    
    const currentIndex = blogs.findIndex((item) => item.id === blog?.id);
    const previousBlog = blogs[currentIndex - 1];
    const nextBlog = blogs[currentIndex + 1];

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);



      const blogCategories = blogs.reduce((acc, blog) => {
        const categoryId = blog.category.id;
        acc[categoryId] = acc[categoryId] || { category: blog.category, count: 0 };
        acc[categoryId].count++;
        return acc;
      }, {});


  return (
    <Layout>
      <PageHeader title="Blog Detay" />
      <>
  <section className="vs-blog-wrapper blog-details space-top space-extra-bottom">
    <div className="container">
      <div className="row gx-40">
        <div className="col-lg-8">
          <div className="vs-blog blog-single">
            <div className="blog-img">
              <img src={blog?.image} alt="Blog Image" />
              <a className="blog-date">
             {/*   <span className="day">11</span>
                <span className="month">January</span> */}
                {formattedDate}
              </a>
            </div>
            <div className="blog-content">
              <div className="blog-meta">
                <a>
                  <i className="fa fa-user" />
                  {blog?.user.first_name}
                </a>
                <a>
                  <i className="fa fa-eye" />
                  100 {translations?.defs.views}
                </a>
              </div>
              <h2 className="blog-title">
                {blog?.title}
              </h2>
              <div>
              {htmlString && parse(htmlString)}

              </div>
            </div>
            <div className="share-links clearfix  ">
              <div className="row justify-content-between">
                <div className="col-xl-auto">
                  <span className="share-links-title">{translations?.defs.tags}:</span>
                  <div className="tagcloud">
                    {blog?.tags.map((tag, index) => (
                      <a className="text-uppercase" key={index}>{tag}</a>
                    ))}
                  </div>
                </div>
                <div className="col-xl-auto text-xl-end">
                  <span className="share-links-title">{translations?.defs.share}:</span>
                  <ul className="social-links">
                    <li>
                      <a href="#" target="_blank">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Post Pagination Style */}
            <div className="post-pagination   ">
              <div className="row justify-content-between align-items-md-center">
                <div className="col">
                  {previousBlog && (
                    <div className="post-pagi-box prev">
                      <Link to={`/blog/${previousBlog.id}`}>
                        <i className="fas fa-chevron-left" />
                      </Link>
                      <h4 className="pagi-title">
                        <Link to={`/blog/${previousBlog.id}`}>
                          {previousBlog.title}
                        </Link>
                      </h4>
                    </div>
                  )}
                </div>
                <div className="col">
                  {nextBlog && (
                  <div className="post-pagi-box next">
                      <Link to={`/blog/${nextBlog.id}`}>
                    <i className="fas fa-chevron-right" />
                  </Link>
                  <h4 className="pagi-title">
                  <Link to={`/blog/${nextBlog.id}`}>
                      {nextBlog.title}
                    </Link>
                  </h4>
                </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <aside className="sidebar-area">
            <div className="widget widget_search  ">
            <h3 className="widget_title text-capitalize">{translations?.actions.search}</h3>
              <form className="search-form">
                <input type="text" placeholder="Ara" />
                <button type="submit">
                  <i className="fas fa-search" />
                </button>
              </form>
            </div>
            <div className="widget widget_categories    ">
            <h3 className="widget_title">{translations?.sections.categories}</h3>
  <ul>
  {Object.values(blogCategories).map((categoryData) => (
      <li key={categoryData.category.id}>
        <a href={`blog.html`}>{categoryData.category.name}</a>
        <span>{categoryData.count}</span>
      </li>
    ))}
  </ul>
            </div>
            <div className="widget   ">
              <h3 className="widget_title">{translations?.sections.recentNews}</h3>
              <div className="recent-post-wrap">
              {blogs.slice(-3).map((recentBlog) => (
    <div className="recent-post" key={recentBlog.id}>
      <div className="media-img">
        <img src={recentBlog.image} alt={recentBlog.title} width={110} height={110} />
      </div>
      <div className="media-body">
        <h4 className="post-title">
          <Link to={`/blog/${recentBlog.id}`} className="text-inherit">
            {recentBlog.title}
          </Link>
        </h4>
        <div className="recent-post-meta">
          <span>{formattedDate}</span>
        </div>
      </div>
    </div>
  ))}
              </div>
            </div>
            <div className="widget">
            <h4 className="widget_title">{translations?.sections.galleryPhotos}</h4>
              <div className="sidebar-gallery">
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage1}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage1}
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage2}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage2}
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage3}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage2}
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage4}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage4}
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage5}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage5}
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage6}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    src={translations?.defs.galleryImage6}
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
              </div>
            </div>
            <div className="widget widget_meta   ">
              <h3 className="widget_title">{translations?.sections.metaLinks}</h3>
              <ul>
                <li>
                  <a href={translations?.defs.metaLink1Href}><FaLink className="me-2" />{translations?.defs.metaLink1Name}</a>
                </li>
                <li>
                  <a href={translations?.defs.metaLink2Href}><FaLink className="me-2" />{translations?.defs.metaLink2Name}</a>
                </li>
                <li>
                  <a href={translations?.defs.metaLink3Href}><FaLink className="me-2" />{translations?.defs.metaLink3Name}</a>
                </li>
                <li>
                  <a href={translations?.defs.metaLink4Href}><FaLink className="me-2" />{translations?.defs.metaLink4Name}</a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
    CTA Area
  ==============================*/}
  <section
    className="space-extra"
    data-bg-src="assets/img/bg/blog-single-divider-bg-1-1.jpg"
  >
    <div className="container">
      <div className="row justify-content-between text-center text-lg-start">
        <div className="col-lg-6 mb-40 mb-lg-0">
          <h2 className="mt-n2 h2 mb-3">
            {translations?.sections.getQuoteTitle}
          </h2>
          <p className=" mb-4 pb-2 fs-md col-xl-11">
          {translations?.sections.getQuoteText}
          </p>
          <Link to="/contact-us" className="vs-btn style2">
            <i className="fas fa-angle-right" />
            {translations?.actions.getQuote}
          </Link>
        </div>
        <div className="col-auto d-none d-lg-block">
          <div className="sec-line2" />
        </div>
        <div className="col-lg-auto">
          <h6 className="mt-n1">{translations?.defs.academicLeadTeam}</h6>
          <div className="mini-avater">
            <Link to="/about-us">
            <img src={translations?.sections.leadershipTeamMem1} alt="avatar" />
            </Link>
            <Link to="/about-us">
            <img src={translations?.sections.leadershipTeamMem2} alt="avatar" />
            </Link>
            <Link to="/about-us">
            <img src={translations?.sections.leadershipTeamMem3} alt="avatar" />
            </Link>
            <Link to="/about-us">
            <img src={translations?.sections.leadershipTeamMem4} alt="avatar" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    </Layout>
  );
}

export default BlogDetail;
