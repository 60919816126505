import React, { useState, useContext } from "react";
import { useNavigate} from 'react-router-dom';
import Header from '../components/Header';
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import { MdMonitor } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { VscRefresh } from "react-icons/vsc";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import toast, { Toaster } from 'react-hot-toast';


const Dashboard = () => {

    const navigate = useNavigate();

    const notify = () => toast('Your meeting status has been successfully changed.');


 const {user, setUser} = useContext(AppContext);
 const {currentStep, setCurrentStep} = useContext(AppContext);

 const {packageSelectionCompleted, setPackageSelectionCompleted} = useContext(AppContext);



 const startCreatingProcess = () => {
    setPackageSelectionCompleted(false);
    setCurrentStep(0)
    navigate('/create-booking')
   // navigate('/');
 }
 {/* 
 Creating Steps:
 1. package selection
 2. date picker
 3. auth
*/}

const filteringOptions = [
    "upcoming",
    "canceled",
    "past"
]

const [selectedFilteringOption, setSelectedFilteringOption] = useState(filteringOptions[0]);

const handleFilteringChange = (index) => {
    setSelectedFilteringOption(filteringOptions[index])
}

const currentDate = new Date();

const handleCancel = (bookingId) => {
    const currentBooking = user?.bookings.find((booking) => booking.id === bookingId);
    currentBooking.status = "canceled";
    setUser((prevUser) => ({
      ...prevUser,
      bookings: prevUser.bookings.map((booking) =>
        booking.id === currentBooking.id ? currentBooking : booking
      ),
    }));
    notify();
  }

  const handleReschedule = (bookingId) => {
    const currentBooking = user?.bookings.find((booking) => booking.id === bookingId);
    currentBooking.status = "upcoming";
    setUser((prevUser) => ({
      ...prevUser,
      bookings: prevUser.bookings.map((booking) =>
        booking.id === currentBooking.id ? currentBooking : booking
      ),
    }));
    notify();
  }


  return (
    <>
        <Header />
        <Toaster 
              toastOptions={{
                className: 'bg-success',
                duration: 5000,
                style: {
                  color: '#fff',
                },
            
              }}
            />
        <div className="app-main">
            <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="dropdown">
  <button className="btn btn-secondary text-capitalize px-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    {selectedFilteringOption}
  </button>
  <ul className="dropdown-menu">
    {filteringOptions.map((filtering, index) => (
        <li key={index}>
            <a 
                className="dropdown-item text-capitalize"
                onClick={() => handleFilteringChange(index)}    
            >{filtering}</a>
        </li>
    ))}
  </ul>
</div>
                <button className="btn btn-outline-primary" onClick={() => startCreatingProcess()}>Create meeting</button>
            </div>


{selectedFilteringOption === "past" && (
<>
{user?.bookings.filter((booking) => booking.date < currentDate).length < 1 && (
  <div className="alert alert-warning" role="alert">
    No meeting available. Please create a meeting.
  </div>
)}
</>
)}

{selectedFilteringOption === "upcoming" && (
<>
{user?.bookings.filter((booking) => booking.status === "upcoming").length < 1 && (
  <div className="alert alert-warning" role="alert">
    No meeting available. Please create a meeting.
  </div>
)}
</>
)}

{selectedFilteringOption === "canceled" && (
<>
{user?.bookings.filter((booking) => booking.status === "canceled").length < 1 && (
  <div className="alert alert-warning" role="alert">
    No meeting available. Please create a meeting.
  </div>
)}
</>
)}

<table className="table">
    {selectedFilteringOption === "upcoming" && (
        <>
        {user?.bookings.filter((booking) => booking.status === "upcoming").length > 0 && (       
    <thead className="border-white">
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Participants</th>
      <th scope="col">Package</th>
      <th scope="col">Date</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
     )}
        </>
    )}

{selectedFilteringOption === "canceled" && (
        <>
        {user?.bookings.filter((booking) => booking.status === "canceled").length > 0 && (       
    <thead className="border-white">
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Participants</th>
      <th scope="col">Package</th>
      <th scope="col">Date</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
     )}
        </>
    )}

{selectedFilteringOption === "past" && (
        <>
        {user?.bookings.filter((booking) => booking.date < currentDate).length > 0 && (       
    <thead className="border-white">
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Participants</th>
      <th scope="col">Package</th>
      <th scope="col">Date</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
     )}
        </>
    )}

<tbody> 

  {user?.bookings
  .filter((booking) => {
    const currentDate = new Date();
    const bookingDate = new Date(booking.date);
    const bookingStatus = booking.status;

    if (bookingDate < currentDate) {
        return true;
    }

    return (
      (selectedFilteringOption === "upcoming" && bookingDate > currentDate && bookingStatus !== 'canceled') ||
      (selectedFilteringOption === "past" && bookingDate <= currentDate && bookingStatus !== 'canceled') || 
      (selectedFilteringOption === "canceled" && bookingStatus === 'canceled')
    );
  })
  .map((booking, index) => {
    
    const currentDate = new Date();
    const bookingDate = new Date(booking.date);
    const differenceInMilliseconds = bookingDate.getTime() - currentDate.getTime();

    const oneHourInMilliseconds = 60 * 60 * 1000;

    const remainingDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let remainingTime;
    if (remainingDays > 1) {
        remainingTime = `In ${remainingDays} days`;
    } else {
        const remainingHours = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60));
        remainingTime = `In ${remainingHours} hours`;
    }

    const isCallButtonActive = differenceInMilliseconds <= oneHourInMilliseconds;

    return (
        <>
      
    <tr key={index} height="120px" className="align-middle">
      <td className="bg-white"><h5 className="text-capitalize mb-0">{booking.participants[0].firstName} {booking.participants[0].lastName}</h5></td>
      <td className="bg-white">
      <div className="avatar-group d-flex gap-1 mt-2">
                {booking.participants.slice(0,3).map((participant, index) => (
                    <div                       data-tooltip-id="participant-tooltip"
                    data-tooltip-content={participant.firstName}
                    data-tooltip-place="top" key={index} className="avatar user-circle rounded-circle">
                        <img src="texture-gray.avif" className="rounded-circle" width="32" height="32" alt={participant.firstName} />
                    </div>
                ))}
                <Tooltip id="participant-tooltip" />
            </div>
      </td>
      <td className="bg-white"><h6 className="fw-normal mb-0">{booking.package.name}</h6></td>
      <td className="bg-white">            
      <span className="d-block">
  {booking.date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
</span>
            <span className="badge bg-danger text-white">
                <FaRegClock className="me-2" />
                {remainingTime}
            </span>
            </td>
      <td className="bg-white">
      {isCallButtonActive ? (
                  <button className="btn btn-secondary fw-bold px-5" onClick={() => alert("Starting...")}>
                    Start call
                  </button>
                ) : (
                  <>
                    <span
                      data-tooltip-id="call-tooltip"
                      data-tooltip-content="Call will be available 1 hour prior the scheduled start"
                      data-tooltip-place="top"
                    >
                      <button
                        className="btn btn-secondary fw-bold px-5"
                        style={{ pointerEvents: "none" }}
                        disabled
                      >
                        Start call
                      </button>
                    </span>
                    <Tooltip id="call-tooltip" />
                  </>
                )}

                {booking.status === "upcoming" && (
                    <>
                        <a className="text-danger ms-2"                       
                        data-tooltip-id="cancel-tooltip"
                        data-tooltip-content="Cancel meeting"
                        data-tooltip-place="top"  
                        onClick={() => handleCancel(booking.id)}>
                            <IoIosRemoveCircleOutline  />
                        </a>
                        <Tooltip id="cancel-tooltip" />
                    </>
                )}

                {booking.status === "canceled" && (
                    <>
                        <a className="text-success ms-2" 
                        data-tooltip-id="reschedule-tooltip"
                        data-tooltip-content="Reschedule meeting"
                        data-tooltip-place="top"  
                        onClick={() => handleReschedule(booking.id)}>
                            <VscRefresh />
                        </a>
                        <Tooltip id="reschedule-tooltip" />
                    </>
                )}

      </td>
    </tr>

        </>
  )})
}
</tbody>
</table>



        </div>
    </>
  );
}

export default Dashboard;
