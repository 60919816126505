import React, { useContext } from 'react';
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AppContext } from '../context/AppContext';

const Header = ({ auth, userData}) => {
  const { user } = useContext(AppContext);

  return (
    <>
    <header className="App-header bg-white pt-2 d-flex flex-column gap-3 align-items-center justify-content-center mt-5">
    <img
      className="rounded-circle"
      src={auth ? userData.avatar : "https://static.vecteezy.com/system/resources/previews/021/548/095/non_2x/default-profile-picture-avatar-user-avatar-icon-person-icon-head-icon-profile-picture-icons-default-anonymous-user-male-and-female-businessman-photo-placeholder-social-network-avatar-portrait-free-vector.jpg"}
      alt="User profile picture"
      width={80}
      height={80}
    />
    <h2 className="mb-0">{auth ? userData.name : "Misafir"}</h2>

    <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container">
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a href={"https://client.meetods.com"} target={"_blank"} className="nav-link d-flex align-items-center gap-2 active">
            <FaHome size={22} />
            <span>Yönetim Paneli</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
  </header> 
    </>
  );
};

export default Header;
