import React, {useEffect, useContext} from 'react';
import {AppContext} from '../context/AppContext';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';


const AuthenticationWithOrder = ({}) => {

    const {hash} = useParams();
    const {order} = useParams();
    const navigate = useNavigate();
    const {user, setUser} = useContext(AppContext);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch('https://app.meetods.com/api/v1/authorizeWithOrder', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        hash: hash,
                        order: order,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.status) {
                    localStorage.removeItem('hash');
                    localStorage.setItem('user_id', data.user_id);
                    localStorage.setItem('hash', data.hash);
                    // data.order is object
                    localStorage.setItem('order', JSON.stringify(data.order));
                    navigate('/booking')
                } else {
                    alert("Sıkıntı var")
                    console.error('Authentication failed');
                }
            } catch (error) {
                console.error('Error during authentication:', error.message);
            }
        };
        fetchData()
    }, []);

    return (
        <>
            Authentication With Order
        </>
    );
};

export default AuthenticationWithOrder;
