import React from 'react';
import Layout from '../../components/Layout';
import { useParams } from "react-router-dom";
import { FaStar } from 'react-icons/fa';
import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
  } from "react-icons/fa";
  import FooterInner from '../../components/FooterInner';

const TutorDetail = ({ tutors }) => {
    const { id } = useParams();


    const tutor = tutors?.find(
        (tutor) => tutor.id === Number(id)
      );
    

  if (!tutors) {
    return <div>Loading...</div>; 
  }





  return (
    <Layout>
<>
<>
  <section className="space-top space-extra-bottom">
    <div className="container">
      <div className="row justify-content-center align-items-center gx-80 mb-lg-4 pb-3">
        <div className="col-lg-5 col-xl-auto order-lg-2 mb-4 mb-lg-0 pb-2 pb-lg-0">
          <div className="img-box1 style3">
            <div className="vs-circle">
              <div className="mega-hover">
                <img src="/about-1-1.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl order-lg-1 mb-4 mb-md-0">
          <div className="team-details">
            <h2 className="team-name h2">{tutor.name}</h2>
            <p className="team-degi">{tutor.branch}</p>
            <span className="team-courses">{tutor.lessons_completed} Lessons Completed</span>
            <span className="team-rate">
              <span className="team-price">${tutor.hourly_rate} </span>/ per hour
            </span>
            <p className="team-experi">{tutor.experience}+ years experience</p>
            <div className="team-review">
            {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
            </div>
            <div class="social-style1 mt-4">
              <a href={"#"} className="me-1">
                <span className="social-icon">
                  <FaFacebookF />
                </span>
              </a>
              <a href={"#"} className="me-1">
              <span className="social-icon">
                  <FaTwitter  />
                </span>
              </a>
              <a href={"#"} className="me-1">
              <span className="social-icon">
                  <FaLinkedinIn />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 col-xl order-lg-3">
          <h4 className="border-title2">Qualifications</h4>
          <div className="graduation-media">
            <h6 className="year">2001.</h6>
            <div className="media-body">
              <h6 className="media-title">Post Graduation</h6>
              <p className="media-text">Oxford Universty</p>
            </div>
          </div>
          <div className="graduation-media">
            <h6 className="year">2005.</h6>
            <div className="media-body">
              <h6 className="media-title">Graduation in English</h6>
              <p className="media-text">Oxford Universty</p>
            </div>
          </div>
          <div className="graduation-media">
            <h6 className="year">2011.</h6>
            <div className="media-body">
              <h6 className="media-title">Master of Arts</h6>
              <p className="media-text">Oxford Universty</p>
            </div>
          </div>
        </div>
      </div>
      <h2 className="border-title2 mb-4">History Tutor</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborums nisi ut aliquip ex ea commosi ut aliquip ex
        ea commodo cons magna aliqua. Ut enim ad minim veniam equatd
      </p>
      <div className="list-style1 vs-list mb-4">
        <ul>
          <li>Many professional careers impose strict A Level benchmarks</li>
          <li>Build exam confidence in a calm environment</li>
          <li>Interactive classroom</li>
          <li>Identify strengths and weaknesses</li>
          <li>
            A Levels are advanced level qualifications that act as a gateway to
            higher education
          </li>
        </ul>
      </div>
    </div>
  </section>{" "}
  {/*==============================
    Course Area
==============================*/}
  <section
    className="space-top space-extra-bottom"
    data-bg-src="assets/img/bg/course-bg-pattern.jpg"
  >
    <div className="container">
      <div
        className="title-area text-center wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <div className="sec-icon">
          <div className="vs-circle" />
        </div>
        <span className="sec-subtitle">WELCOME TO GLOBAL EDUCATION</span>
        <h2 className="sec-title">Explore Courses</h2>
      </div>
      <div
        className="row vs-carousel"
        data-slide-show={3}
        data-lg-slide-show={3}
        data-md-slide-show={2}
      >
        <div className="col-sm-6 col-xl-4">
          <div className="course-style2">
            <div className="course-img">
              <a href="course-details.html">
                <img
                  className="w-100"
                  src="/course-2-1.jpg"
                  alt="Course Img"
                />
              </a>
              <span className="course-price">$778</span>
            </div>
            <div className="course-content">
              <h3 className="h5 course-name">
                <a href="course-details.html" className="text-inherit">
                  Advance Begineer's Goal &amp; Managing Course
                </a>
              </h3>
              <div className="course-meta">
                <span>
                  <i className="fas fa-user-tie" />
                  204 Students
                </span>
                <a href="course-details.html">
                  <i className="far fa-tv" />
                  12 Leson
                </a>
                <span>
                  <i className="fal fa-clock" />
                  2h 11m
                </span>
              </div>
              <div className="course-footer">
                <div className="course-teacher">
                  <a href="team-details.html" className="text-inherit">
                    <img src="/course-2-1.png" alt="Course" />
                    By Ana Watson
                  </a>
                </div>
                <div className="course-review">
                {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="course-style2">
            <div className="course-img">
              <a href="course-details.html">
                <img
                  className="w-100"
                  src="/course-2-2.jpg"
                  alt="Course Img"
                />
              </a>
              <span className="course-price">$963</span>
            </div>
            <div className="course-content">
              <h3 className="h5 course-name">
                <a href="course-details.html" className="text-inherit">
                  Advance Technology &amp; Architecture Course
                </a>
              </h3>
              <div className="course-meta">
                <span>
                  <i className="fas fa-user-tie" />
                  779 Students
                </span>
                <a href="course-details.html">
                  <i className="far fa-tv" />
                  79 Leson
                </a>
                <span>
                  <i className="fal fa-clock" />
                  6h 36m
                </span>
              </div>
              <div className="course-footer">
                <div className="course-teacher">
                  <a href="team-details.html" className="text-inherit">
                    <img src="/course-2-1.png" alt="Course" />
                    By Vivi Marian
                  </a>
                </div>
                <div className="course-review">
                {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="course-style2">
            <div className="course-img">
              <a href="course-details.html">
                <img
                  className="w-100"
                  src="/course-2-3.jpg"
                  alt="Course Img"
                />
              </a>
              <span className="course-price">$445</span>
            </div>
            <div className="course-content">
              <h3 className="h5 course-name">
                <a href="course-details.html" className="text-inherit">
                  Basic Chemistry Programs arranged on Lab
                </a>
              </h3>
              <div className="course-meta">
                <span>
                  <i className="fas fa-user-tie" />
                  75 Students
                </span>
                <a href="course-details.html">
                  <i className="far fa-tv" />
                  78 Leson
                </a>
                <span>
                  <i className="fal fa-clock" />
                  17h 11m
                </span>
              </div>
              <div className="course-footer">
                <div className="course-teacher">
                  <a href="team-details.html" className="text-inherit">
                    <img src="/course-2-1.png" alt="Course" />
                    By Maio Polo
                  </a>
                </div>
                <div className="course-review">
                {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
</>
    </Layout>
  );
};

export default TutorDetail;
