import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../context/AppContext';
import PreLoader from '../components/PreLoader';
import Layout from '../components/Layout';
import { useSpring, animated, useScroll } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const Features = () => {
    const { translations } = useContext(AppContext);
    const [ features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(true);

    const [ref1, inView] = useInView({
      triggerOnce: true,
    })
    const [ref2, inView2] = useInView({
      triggerOnce: true,
    })
    const [ref3, inView3] = useInView({
      triggerOnce: true,
    })
    const [ref4, inView4] = useInView({
      triggerOnce: true,
    })
  
  
    const titleAnimation1 = useSpring({
      from: { backgroundPosition: "left" },
      to: { backgroundPosition: inView ? "left" : "right" }
    })
    const titleAnimation2 = useSpring({
      from: { backgroundPosition: "left" },
      to: { backgroundPosition: inView2 ? "left" : "right" }
    })
    const titleAnimation3 = useSpring({
      from: { backgroundPosition: "left" },
      to: { backgroundPosition: inView3 ? "left" : "right" }
    })
  
    const titleAnimation4 = useSpring({
      from: { backgroundPosition: "left" },
      to: { backgroundPosition: inView4 ? "left" : "right" }
    })
  
  
  

    useEffect(() => {
        const fetchData = async (url, setter) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
        }
        };
    
        fetchData(
          "https://webapi.meetods.com/api/v1/features",
          setFeatures
        )
      }, []);

      if(loading) {
        return (
          <PreLoader />
        )
      }

  return (
    <Layout>
            <div className="row space-top">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <div className="sec-icon">
              <div className="vs-circle"></div>
            </div>
            <h5 className="sec-subtitle text-black">{translations?.defs.featuresTitle}</h5>
            <h1 className="display-4 fw-semibold text-center text-black">
            {translations?.defs.featuresSubtitle}
            </h1>
          </div>
        </div>
      </div>
      <div className="feature-sections-wrapper space-bottom">
      {features?.map((feature, index) => {
const getLastTwoWords = (sentence) => {
  const words = sentence.split(' ');
  const lastTwoWords = words.slice(-2).join(' ');
  return lastTwoWords;
};

const trimLastTwoWords = (sentence) => {
  const words = sentence?.split(' ');
  if (words.length <= 1) {
    return sentence;
  }
  const lastTwoWordsIndex = words.length - 2;
  const trimmedSentence = words.slice(0, lastTwoWordsIndex).join(' ');

  return trimmedSentence;
};
        
        return(
  <section className="feature-section space-top" key={index}>
    <div className="container">
    <div className={`row ${index % 2 === 0 ? 'reverse-order' : ''}`}>
        <div className="col-lg-6">
          <img src={feature.media} alt={feature.title} className="h-100" />
        </div>
        <div className="col-lg-6">
          <h5 className="sec-subtitle mt-3 mt-lg-0">{feature.title}</h5>

          <h3 className="decorative-title display-4 fw-semibold mb-3 mb-md-2 mb-lg-5"
      ref={
      feature.id === 1
        ? ref1
        : feature.id === 2
          ? ref2
          : feature.id === 3
            ? ref3
            : feature.id === 4
              ? ref4 
              : {}
    }
    >
          {trimLastTwoWords(feature.subtitle)}
          <animated.span  
              style={
                feature.id === 1
                  ? titleAnimation1
                  : feature.id === 2
                    ? titleAnimation2
                    : feature.id === 3
                      ? titleAnimation3
                      : feature.id === 4
                        ? titleAnimation4
                        : {} 
              }
          className="ms-1 ps-2 word-bg">
            {getLastTwoWords(feature.subtitle)}
          </animated.span> 
        </h3>

          <p className="font-md">{feature.description}</p>
         {/* <a className="vs-btn style3" href={feature.featureLink}><i className="fa fa-angle-right"></i>{feature.featureActionText}</a> */}
       {/*   <Link to='/features' className='feature-btn'>
            <span className="feature-btn-icon">
            <i className="fa fa-angle-right"></i>
            </span>
            <span className="feature-btn-text">
            {feature.action_text}
            </span>
            </Link> */}
        </div>
      </div>
    </div>
  </section>
)})}

      </div>
    </Layout>
  );
}

export default Features;
