import React, {useState, useEffect} from "react";
import Header from "../../components/Header";
import PackageStep from "../../components/step-form/PackageStep";
import ExpertStep from "../../components/step-form/ExpertStep";
import {FaCircleCheck} from "react-icons/fa6";
import ReactDOMServer from 'react-dom/server';
import {BsArrowLeft} from "react-icons/bs";
import DateStep from "../../components/step-form/DateStep";
import RegisterStep from "../../components/step-form/RegisterStep";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BookingStep from "../../components/step-form/BookingStep";
import Swal from 'sweetalert2';

const Booking = () => {
    const [packages, setPackages] = useState([]);
    const [auth, setAuth] = useState(false);
    const [userData, setUserData] = useState({});
    const [userIdHash, setUserIdHash] = useState({});
    const [orderData, setOrderData] = useState(localStorage.getItem('order') ? JSON.parse(JSON.parse(localStorage.getItem('order'))) : {});

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        const hash = localStorage.getItem('hash');


        if (userId !== null && userId !== undefined && userId !== "" && hash !== null && hash !== undefined && hash !== "") {

            try {
                fetch('https://app.meetods.com/api/v1/customer_data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({user_id: userId, hash}),
                }).then(response => response.json())
                    .then(data => {
                        if (data.status === true) {
                            setAuth(true);
                            setUserData(data.data);
                            setUserIdHash({user_id: userId, hash: hash})
                            toast("Hoşgeldiniz " + data.data.name, {
                                autoClose: 2000,
                                type: toast.TYPE.SUCCESS,
                            });
                        }
                    })
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        }
    }, [setAuth, setUserData, setUserIdHash]);
    let current_step = 1;

    useEffect(() => {
        const fetchData = async (url, setter) => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }
                const data = await response.json();
                const packagesWithParsedFeatures = data.data.map(packageItem => {
                    return {
                        ...packageItem,
                        features: JSON.parse(packageItem.features),
                    };
                });
                setter(packagesWithParsedFeatures);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(
            "https://app.meetods.com/api/v1/packages",
            setPackages
        )
    }, []);

    const validateArea = (area) => {
        let required_inputs = document.querySelectorAll(".content-step[data-step='" + area + "'] input[required], .content-step[data-step='" + area + "'] select[required], .content-step[data-step='" + area + "'] textarea[required]");
        let required_inputs_filled = true;
        required_inputs.forEach((item) => {
            if (!item.value || item.value.trim() === "") {
                required_inputs_filled = false;
                item.classList.add("border-danger");
            } else {
                item.classList.remove("border-danger");
            }
        });
        if (!required_inputs_filled) {
            toast("Lütfen gerekli alanları doldurunuz", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
        }

        return required_inputs_filled;
    }
    const handleNextStep = () => {
        if (current_step === 1) {
            let package_id = document.querySelector('input[name="package_id"]:checked');
            if (!package_id) {
                toast("Lütfen paket seçiniz", {
                    autoClose: 2000,
                    type: toast.TYPE.ERROR,
                });
                return;
            }
            package_id = package_id.value;

            try {
                fetch(`https://app.meetods.com/api/v1/packages/${package_id}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === true) {
                            document.querySelector(".content-step[data-step='1']").classList.add("d-none");
                            document.querySelector(".content-step[data-step='2']").classList.remove("d-none");
                            current_step = 2;

                            let experts = data.data;

                            let expertsHtml = experts.map((expert, index) => {
                                return ReactDOMServer.renderToString(<ExpertStep item={expert} orderData={orderData}/>);
                            });

                            document.querySelector(".dynamic-user-content").innerHTML = expertsHtml.join("");
                            document.querySelectorAll('.dynamic-user-content .expert-label').forEach((item) => {
                                item.addEventListener("click", function () {
                                    document.querySelectorAll('.dynamic-user-content .selectable-element .selectable-element-icon').forEach((item) => {
                                        item.classList.remove("show");
                                    });
                                    this.querySelector('.selectable-element .selectable-element-icon').classList.add("show");
                                });
                            });

                            document.querySelector(".btn-prev-step").classList.remove("d-none");
                        } else {
                            alert("Paket bilgileri bulunamadı");
                        }
                    });

            } catch (error) {
                console.error("Error fetching data:", error);
            }


        } else if (current_step === 2) {
            let package_user_id = document.querySelector('input[name="package_user_id"]:checked');
            let package_id = document.querySelector('input[name="package_id"]:checked');

            if (!package_user_id) {
                toast("Lütfen kullanıcı seçiniz", {
                    autoClose: 2000,
                    type: toast.TYPE.ERROR,
                });
                return;
            }

            if (package_id.value !== orderData.package_id || package_user_id.value !== orderData.package_user_id) {
                document.querySelector('#dynamic-hour-picker').innerHTML = "";
                document.querySelector('#randevusaat').value = "";
            } // TODO: daha iyi bir çözüm bulunabilir

            document.querySelector(".content-step[data-step='2']").classList.add("d-none");
            document.querySelector(".content-step[data-step='3']").classList.remove("d-none");
            current_step = 3;
        } else if (current_step === 3) {
            if (!validateArea(3)) {
                return false;
            }
            if (auth) {
                document.querySelector(".content-step[data-step='3']").classList.add("d-none");
                document.querySelector(".content-step[data-step='5']").classList.remove("d-none");
                current_step = 5;
            } else {
                document.querySelector(".content-step[data-step='3']").classList.add("d-none");
                document.querySelector(".content-step[data-step='4']").classList.remove("d-none");
                current_step = 4;
            }

        } else if (current_step === 4) {
            if (!validateArea(4)) {
                return false;
            }
            document.querySelector(".content-step[data-step='4']").classList.add("d-none");
            document.querySelector(".content-step[data-step='5']").classList.remove("d-none");
            document.querySelector(".btn-next-step").innerHTML = "Ödemeye Geç";
            current_step = 5;
        } else if (current_step === 5) {
            if (!validateArea(5)) {
                return false;
            }

            let terms = document.querySelector("#terms");
            if (!terms.checked) {
                toast("Lütfen sözleşmeyi kabul ediniz", {
                    autoClose: 2000,
                    type: toast.TYPE.ERROR,
                });
                terms.closest(".form-group").querySelector("label").classList.add("text-danger");
                return false;
            } else {
                terms.closest(".form-group").querySelector("label").classList.remove("text-danger");
            }

            Swal.fire({
                icon: 'info',
                title: 'Emin misiniz?',
                text: "Bu aşamadan sonra hesabınız oluşturulacak ve kayıt alanına geri dönemeyeceksiniz.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Ödeme Yap`,
                denyButtonText: `Vazgeç`,
            }).then((result) => {
                if (result.isConfirmed) {
                    document.querySelector("#bookingForm").submit();
                }
            })
        }
    };
    const handlePrevStep = () => {
        if (current_step === 2) {
            document.querySelector(".content-step[data-step='2']").classList.add("d-none");
            document.querySelector(".content-step[data-step='1']").classList.remove("d-none");
            document.querySelector(".btn-prev-step").classList.add("d-none");
            current_step = 1;
        } else if (current_step === 3) {
            document.querySelector(".content-step[data-step='3']").classList.add("d-none");
            document.querySelector(".content-step[data-step='2']").classList.remove("d-none");
            current_step = 2;
        } else if (current_step === 4) {
            document.querySelector(".content-step[data-step='4']").classList.add("d-none");
            document.querySelector(".content-step[data-step='3']").classList.remove("d-none");
            current_step = 3;
        } else if (current_step === 5) {
            if (auth) {
                document.querySelector(".content-step[data-step='5']").classList.add("d-none");
                document.querySelector(".content-step[data-step='3']").classList.remove("d-none");
                current_step = 3;
            } else {
                document.querySelector(".content-step[data-step='5']").classList.add("d-none");
                document.querySelector(".content-step[data-step='4']").classList.remove("d-none");
                document.querySelector(".btn-next-step").innerHTML = "Sonraki Adım";
                current_step = 4;
            }
        }
    }

    return (
        <>
            <ToastContainer/>
            <Header auth={auth} userData={userData}/>
            <div className="app-main">
                <div className="row d-flex justify-content-center">
                    <div className={'col-12 mb-3 d-flex justify-content-end'}>
                        <button
                            type={"button"}
                            className={"btn btn-primary w-100 me-1 d-none btn-prev-step"}
                            onClick={handlePrevStep}
                        >Önceki Adım
                        </button>
                        <button
                            type={"button"}
                            className={"btn btn-primary w-100 btn-next-step"}
                            onClick={handleNextStep}
                        >Sonraki Adım
                        </button>
                    </div>
                    <form action="https://client.meetods.com/remote-payment/create" method={"post"} id={"bookingForm"}>
                        <div className={'col-12 content-step'} data-step={"1"}>
                            <div className={'row'}>
                                {packages?.map((item, id) => (
                                    <div className="col-12 col-lg-4 col-md-6" key={id}>
                                        <PackageStep
                                            pack={item}
                                            orderData={orderData}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'col-12 content-step d-none'} data-step={"2"}>
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="booking-step-title">Müsait Uzmanlar</h3>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row dynamic-user-content">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-12 content-step d-none'} data-step={"3"}>
                            <div className={'row'}>
                                <DateStep orderData={orderData}/>
                            </div>
                        </div>
                        <div className={'col-12 content-step d-none'} data-step={"4"}>
                            <div className={'row'}>
                                <RegisterStep auth={auth} userIdHash={userIdHash}/>
                            </div>
                        </div>
                        <div className={'col-12 content-step d-none'} data-step={"5"}>
                            <div className={'row'}>
                                <BookingStep orderData={orderData}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Booking;
