import React from 'react';

const HourPicker = ({hours, selected}) => {

    return (
        <>
            {Object.values(hours).map((hour, index) => (
                <div className="hour-card d-flex flex-column mb-3" key={index}>
                    <button type={"button"} id={"hour-picker-" + index} className={"btn " + (selected != null ? hour.time === selected ? 'btn-primary' : 'btn-secondary' : 'btn-secondary') + " fw-bold fs-6 p-3 rounded-1 btn-hour"}
                            data-time={hour.time}
                    >
                        {hour.text}
                    </button>
                </div>
            ))}
        </>
    )
}

export default HourPicker;