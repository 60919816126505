import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../components/Layout';
import { FaStar } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import PreLoader from '../../components/PreLoader';
import { Screen, EventSchedule, GroupPresentation, Money, Api  } from '@carbon/icons-react';
import { FaHeart } from "react-icons/fa";

 import { AppContext } from '../../context/AppContext';
 import { Link } from 'react-router-dom';
 import { useSpring, animated, useScroll } from '@react-spring/web'
 import { useInView } from 'react-intersection-observer';


const BranchDetail = ({branches}) => {
    const { translations } = useContext(AppContext);

  const { id } = useParams();

    const branch = branches?.find(
      (branch) => branch.id === Number(id)
    ); 


    const [ref1, inView] = useInView({
      triggerOnce: true,
    })

    const titleAnimation1 = useSpring({
      from: { backgroundPosition: "left" },
      to: { backgroundPosition: inView ? "left" : "right" }
    })

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (branches && branches.length > 0) {
        setLoading(false);
      }
    }, [branches]);

      const getLastTwoWords = (sentence) => {
        const words = sentence?.split(' ');
        const lastTwoWords = words?.slice(-2).join(' ');
        return lastTwoWords;
      };
      
      const trimLastTwoWords = (sentence) => {
        const words = sentence?.split(' ');
        if (words?.length <= 1) {
          return sentence;
        }
        const lastTwoWordsIndex = words?.length - 2;
        const trimmedSentence = words?.slice(0, lastTwoWordsIndex).join(' ');
      
        return trimmedSentence;
      };


    
      if(loading) {
        return (
          <PreLoader />
        )
      }

  return (
    <Layout>
<>
<section className="background-image shape-mockup-wrap">
    <div
      className="shape-mockup jump-reverse d-none d-xxxl-block"
      style={{ right: "9%", bottom: "30%"  }}
    >
      <img src="/shape/dotted-1.png" alt="shape" />
    </div>
    <div
      className="shape-mockup rotate d-none d-xxxl-block z-2"
      style={{ left: "49%", top: "26%"  }}
    >
      <div className="vs-polygon1" />
    </div>
    <div
      className="shape-mockup jump d-none d-xxxl-block"
      style={{ left: "46%", top: "26%"  }}
    >
      <div className="vs-rounded1" />
    </div>
    <div className="container">
      <div className="row" style={{ paddingTop: 100 }}>
        <div className="col-md-6 align-self-center text-center text-md-start pb-3 pb-md-5 pt-5">
          <h2 className="sec-title2">{branch?.branchTitle}</h2>
          <p className="fs-20 col-xl-9 mb-4 pb-xl-2">
            {branch?.branchSubtitle}
          </p>
          <a href="https://app.meetods.com/register" className="vs-btn style3">
            <i className="fa fa-angle-right" />
          {translations?.defs.tryFree}
          </a>
        </div>
        <div className="col-md-6 align-self-end">
          <div className="picture-box4">
            <div className="img-1 ms-0 me-0">
              <img src={`https://webapi.meetods.com/storage/branches/${branch?.branchImage}`} alt={branch?.branchTitle} className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<section className="space-top space-bottom">
    <div className="container">
      <div className="row mb-5">
        <div
          className="title-area text-center wow fadeInUp"
        >
          <div className="sec-icon">
            <div className="vs-circle" />
          </div>
          <span className="sec-subtitle">{branch?.section_1_title}</span>
          <h2 className="sec-title h1 mx-auto">{branch?.section_1_subtitle}</h2>
        </div>
      </div>
      <div className="row">
        {branch?.features?.map((item, index) => (
                  <div className="col-sm-6 col-lg-3" key={index}>
                  <div className="media-style8">
                    <div className="media-icon">
                      <img src={item.icon} alt="" />
                    </div>
                    <h5 className="media-title">{item.title}</h5>
                    <p className="media-text">
                    {item.text}
                    </p>
                  </div>
                </div>
        ))}
      </div>
    </div>
  </section>


  <section className="space-top space-bottom bg-smoke">
    <div className="container">
      <div className="row">
          <div className="col-lg-6">
          <img src={branch?.section_2_image} alt="" />
          </div>
          <div className="col-lg-6">
            <span className="sec-subtitle">{branch?.section_2_title}</span>
        
            <h3 className="decorative-title display-4 fw-semibold mb-3 mb-md-2 mb-lg-5"
            ref={ref1}
>
          {trimLastTwoWords(branch?.section_2_subtitle)}
          <animated.span 
    style={titleAnimation1}
                          className="ms-1 ps-2 word-bg smoke">
            {getLastTwoWords(branch?.section_2_subtitle)}
          </animated.span> 
        </h3>
        
            <p className="font-md">{branch?.section_2_text}</p>
            <Link to="/" className='feature-btn'>
            <span className="feature-btn-icon">
            <i className="fa fa-angle-right"></i>
            </span>
            <span className="feature-btn-text z-0">
            {translations?.actions.learnMore}
            </span>
          </Link>
          </div>
      </div>
    </div>
  </section>

  <section className="space-top space-bottom">
    <div className="container">
    <div className="row">
        <div
          className="title-area text-center wow fadeInUp"
        >
          <div className="sec-icon">
            <div className="vs-circle" />
          </div>
          <span className="sec-subtitle">{branch?.section_3_title}</span>
          <h2 className="sec-title h1 mx-auto">{branch?.section_3_subtitle} </h2>
        </div>
      </div>

      {branch?.steps?.map((item, index) => (
        <>
    <div className={`row py-5 ${index % 2 === 0 ? '' : 'reverse-order'}`}>
    <div className="col-lg-6">
                <img src={item.image} alt="" />
              </div>
              <div className="col-lg-6">
                <div className="d-flex flex-column justify-content-center h-100">
                  <h2>{item.name}</h2>
                  <p className="font-md">{item.content}</p>
                </div>
              </div>
          </div>
          {index === branch?.steps.length - 1 ? (
            <></>
          ) : (
            <div className="connector">
            <img src={`${index % 2 === 0 ? '../assets/connector.svg' : '../assets/connector-reverse.svg'}`} />
         </div>
          )}
          </>
      ))}




    </div>
  </section>



   <section className="overflow-hidden bgc-f6 space-top space-extra-bottom shape-mockup-wrap">
  <div class="shape-mockup jump d-none d-xxl-block" style={{left: '-14%'}}><div class="vs-border-circle" style={{ width: 400, height: 400 }}></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{top: '170px', right: '-15%'}}><div class="vs-circle color2"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{bottom: '40%', left: '8%'}}><div class="shape-dotted style2"></div></div>
    <div className="container">
    <div className="row">
            <div className="col">
            <div className="d-flex flex-column align-items-center">
                <h5 className="sec-subtitle">{branch?.section_4_title}</h5>
                <h1 className="display-4 fw-semibold text-center mb-3 mb-md-2 mb-lg-5">
                {translations?.defs.pros} <FaHeart color="red" /> {translations?.defs.appName}
                </h1>
          </div>
            </div>
        </div>


        <div className="testi1-slider-wrapper row my-5">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={true}
              slidesToShow={3}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                  },
                },
              ]}

        >
            {branch?.testimonials?.map((testi, index) => (


<div className="testi-style1" key={index}>
  <div className="testi-content">
    <p className="testi-text">{testi.review}</p>
  </div>
  <div className="testi-client">
    <img src={testi.user_avatar} alt="author" />
    <h3 className="testi-name h5">{testi.name}</h3>
    <span className="testi-degi">{testi.profession}</span>
    <div className="testi-rating">
    {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
    </div>
  </div>
</div>


            ))}
        </Slider>
        </div>

        
    </div>
  </section>
</>


    </Layout>
  );
};

export default BranchDetail;
