import React, { useContext } from 'react';
import { FaCheck } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate} from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const HowItWorks = () => {

      
  const { translations } = useContext(AppContext);
const navigate = useNavigate();


 const handleSignUp = () => {
    navigate('/register')
 }

  return (
    <section className="space-top space-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex flex-column gap-3 mb-5">
            <h5 className="sec-subtitle">{translations?.sections.howItWorksTitle}</h5>
            <h1 className="display-4 fw-semibold text-start">
              {translations?.sections.howItWorksSubtitle}
            </h1>
          </div>

          {/* Accordion */}
          <div className="faq-style1">
          <div className="accordion accordion-style2 wow fadeInUp wow-animated " id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="number">1</span>
                  <span className="title">{translations?.sections.howItWorks1Title} </span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                {translations?.sections.howItWorks1Desc}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span className="number">2</span>
                  <span className="title">{translations?.sections.howItWorks2Title} </span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                {translations?.sections.howItWorks2Desc}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <span className="number">3</span>
                  <span className="title">{translations?.sections.howItWorks3Title}</span>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                {translations?.sections.howItWorks3Desc}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <span className="number">4</span>
                  <span className="title">{translations?.sections.howItWorks4Title} </span>
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                {translations?.sections.howItWorks4Desc}
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>
        <div className="col-lg-6">
          <div className="img-box1">
            <div className="vs-circle">
              <div className="globe-img">
                <img
                  src={translations?.defs.howItWorksImage}
                  alt={translations?.sections.howItWorksTitle}
                  className="rounded-circle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default HowItWorks;
