import React, { useState, useEffect, useContext } from 'react';
import { FaCheck } from "react-icons/fa6";
import { FaChevronRight, FaLayerGroup, FaCalendarAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaRegCheckCircle, FaRegTimesCircle} from "react-icons/fa";
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';


const Packages = () => {
  const { translations } = useContext(AppContext);
  const [ pricingPackages, setPricingPackages] = useState([]);


  useEffect(() => {
      const fetchData = async (url, setter) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setter(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData(
        "https://webapi.meetods.com/api/v1/packages",
        setPricingPackages
      )
    }, []);

  return (
    <section className="space-top space-bottom bg-white">
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <div className="sec-icon">
              <div className="vs-circle"></div>
            </div>
            <h5 className="sec-subtitle text-black">{translations?.sections.pricingPlansTitle}</h5>
            <h1 className="display-4 fw-semibold text-center text-black">
            {translations?.sections.pricingPlansSubtitle}
            </h1>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 gy-3 mt-4">
        {pricingPackages?.map((packageItem, index) => {
  const calculateSavedAmount = (price, discount) => {
    const discountAmount = (price * discount) / 100;
    return discountAmount.toFixed(2); 
  };
          return (
        <div className="col" key={index}>
        <div className="card pricing-card">
          <div className="package-head">
            <h3 className="package-name">{packageItem.name}</h3>
            <p className="package-price h1">
              <span className="currency">$</span>{packageItem.price}
              <span className="month">/{packageItem.period}</span>
            </p>
            <p className="package-text">{packageItem.description}</p>
            <div className="package-icon">
              <FaLayerGroup size="32" />
            </div>
            <div className="package-list d-flex align-items-center gap-1">
              <FaStar className="text-warning" />
              <FaStar className="text-warning" />
              <FaStar className="text-warning" />
              <FaStar className="text-warning" />
              <FaStar className="text-warning" />
            </div>
          </div>
          <div className="package-body">
            <div className="package-list">
              <ul className="list-unstyled">
                {packageItem.features.map((feature, index) => (
                <li key={index}>
                  {feature.is_active === 1 ? (<FaRegCheckCircle /> ) :  (<FaRegTimesCircle /> )}{feature.name}
                </li>
                ))}


              </ul>
            </div>
            <div className="package-bottom">
              <span className="package-save">{translations?.defs?.save} ${calculateSavedAmount(packageItem.price, packageItem.discount)}</span>
              <span className="package-discount">{translations?.defs?.discount}: {packageItem.discount}%</span>
            </div>
            <Link to="/contact-us" className="vs-btn" tabindex="0">
              {translations?.actions.purchaseNow}
            </Link>
          </div>
        </div>
      </div>
          )
})}

      </div>
    </div>
  </section>
  );
}

export default Packages;
