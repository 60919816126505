import React, { useState, useEffect, useContext } from 'react';
import { useSpring, animated, config } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';
import { AppContext } from '../../context/AppContext';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Categories = ({}) => {


  {/*   const [ref2, inView2] = useInView({
        triggerOnce: true,
      });
    
      const titleAnimation = useSpring({
        from: { transform: "translateY(2000%)" },
        to: { transform: inView2 ? "translateY(0)" : "translateY(2000%)" },
        delay: 300
      }) */}

      const [categories, setCategories] = useState([]);
      useEffect(() => {
        const fetchData = async (url, setter) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData("https://webapi.meetods.com/api/v1/categories", setCategories);
      }, []);
    
      const { translations } = useContext(AppContext);

  return (
    <section className="space-top">
    <div className="container">
      <div className="row space-top-half">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="sec-icon">
              <div className="vs-circle"></div>
            </div>
            <h5 className="sec-subtitle text-uppercase">{translations?.sections.categoryTitle}</h5>
            <h1 className="sec-title display-4 fw-semibold text-center">
            {translations?.sections.categorySubtitle}
            </h1>
          </div>
        </div>
      </div>
      <div className="space-top-half">
      <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={false}
              slidesToShow={6}
              swipeToSlide={true}
              slidesToScroll={1}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 1199,
                  settings: {
                    centerMode: false,
                  },
                },
                {
                  breakpoint: 992,
                  settings: {
                    centerMode: false,
                    slidesToShow: 2,
                  },
                },
              ]}
              className="row"
        >
        {categories.map((cat, index) => (
          <div className="col" key={index}>
            <div className="text-center">
              <div className="category-icon position-relative">
                <img
                  src={cat.image}
                  className="ms-3"
                  alt={cat.name}
                />
                <div className="vs-circle"></div>
              </div>
              <Link to={cat.link}>
                <h5 className="category-name mt-5">{cat.name}</h5>
              </Link>
              <p className="category-count">{cat.count}</p>
            </div>
          </div>
        ))}
        </Slider>
      </div>
    </div>
  </section>
  );
}

export default Categories;
