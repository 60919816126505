import React from 'react';

const DataPolicy = () => {

  return (
    <div>
      <h2>Data Policy</h2>
    </div>
  );
}

export default DataPolicy;
