import React, { useState, useRef, useEffect, useContext } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import FooterInner from '../components/FooterInner';
import { AppContext } from '../context/AppContext';
import PreLoader from '../components/PreLoader';
import { FaHeart } from "react-icons/fa";

import { FaCheck } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { Link } from 'react-router-dom';
import { Screen, EventSchedule, GroupPresentation, Money, Api  } from '@carbon/icons-react';
import { useSpring, animated, useScroll } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';

const HowItWorks = ({ branches, factors }) => {
  const [testimonials, setTestimonials] = useState([]);
  const { translations } = useContext(AppContext); 
  const [loading, setLoading] = useState(true); 

    useEffect(() => {
      const fetchData = async (url, setter) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setter(data);
          setLoading(false); 
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
  
      fetchData(
        "https://webapi.meetods.com/api/v1/testimonials",
        setTestimonials
      );
    }, []);

    const [ref1, inView] = useInView({
      triggerOnce: true,
    })

    const titleAnimation1 = useSpring({
      from: { backgroundPosition: "left" },
      to: { backgroundPosition: inView ? "left" : "right" }
    })

    const renderIcon = (icon_name) => {
      if (icon_name === "screen") {
        return <Screen size="62" color="#3747FF" /> 
      } else if (icon_name === "event-schedule") {
        return <EventSchedule size="62" color="#3747FF" /> 
      } else if (icon_name === "group-presentation") {
        return <GroupPresentation size="62" color="#3747FF" /> 
      } else if (icon_name === "moneyap") {
        return <Money size="62" color="#3747FF" /> 
      } else if (icon_name === "api") {
        return <Api size="62" color="#3747FF" /> 
      }
    }

    const getLastTwoWords = (sentence) => {
      const words = sentence?.split(' ');
      const lastTwoWords = words?.slice(-2).join(' ');
      return lastTwoWords;
    };
    
    const trimLastTwoWords = (sentence) => {
      const words = sentence?.split(' ');
      if (words?.length <= 1) {
        return sentence;
      }
      const lastTwoWordsIndex = words?.length - 2;
      const trimmedSentence = words?.slice(0, lastTwoWordsIndex).join(' ');
    
      return trimmedSentence;
    };
    

    if (loading) {
      return     <PreLoader />
    }

  return (
    <Layout>
      <section className="background-image shape-mockup-wrap pt-5 space-bottom">
    <div
      className="shape-mockup jump-reverse d-none d-xxxl-block"
      style={{ right: "9%", bottom: "30%"  }}
    >
      <img src="/shape/dotted-1.png" alt="shape" />
    </div>
    <div
      className="shape-mockup rotate d-none d-xxxl-block"
      style={{ left: "49%", top: "26%"  }}
    >
      <div className="vs-polygon1" />
    </div>
    <div
      className="shape-mockup jump d-none d-xxxl-block"
      style={{ left: "46%", top: "26%"  }}
    >
      <div className="vs-rounded1" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xxl-6 align-self-center text-center text-md-start pb-3 pb-md-5 pt-5">
          <h2 className="display-4 fw-bold">{translations?.sections.howItWorksHeroTitle}</h2>
          <p className="fs-20 col-xl-9 mb-4 pb-xl-2">{translations?.sections.howItWorksHeroText}</p>
          <a href="https://app.meetods.com/register" className="vs-btn style3">
            <i className="fa fa-angle-right" />
          {translations?.defs.tryFree}
          </a>
        </div>
        <div className="col-md-6 col-xxl-6 d-flex justify-content-lg-end align-self-center">
          <div className="picture-box4">
            <div className="img-1">
              <img src={translations?.sections.howItWorksHeroImage}  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 {/*
  <section className="space-extra-bottom">
    <div className="container">
      <div
        className="title-area text-center wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <div className="sec-icon">
          <div className="vs-circle" />
        </div>
        <span className="sec-subtitle">{translations?.sections.howItWorksSection1Title}</span>
        <h2 className="sec-title h1 mx-auto">{translations?.sections.howItWorksSection1Subtitle}</h2>
      </div>
      <div className="row pt-5">
        {branches.map((branch, index) => (
              <div className="col-lg-6" key={index}>
                <div className="branch-card rounded-2">
                  <div className="row">
                    <div className="col-lg-2">
                      {branch.icon ? (
                        <><img src={branch.branch_icon} /></>
                      ) : (
                        <img src="https://webapi.meetods.com/storage/categories/1701473505-sagli.svg" />
                      )}
                      </div>
                    <div className="col-lg-10">
                      <div className="d-flex flex-column">
                        <h5>{branch.name}</h5>
                        <p className="pe-lg-3">{branch.branch_subtitle}</p>
                        <Link to={`/branch/${branch.id}`}>{translations?.actions.learnMore}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ))}
      </div>
    </div>
  </section>
  */}

  <section className="space-top space-bottom">
    <div className="container">
      <div className="row mb-5">
        <div
          className="title-area text-center wow fadeInUp"
        >
          <div className="sec-icon">
            <div className="vs-circle" />
          </div>
          <span className="sec-subtitle">{translations?.sections.howItWorksSection3Title}</span>
          <h2 className="sec-title h1 mx-auto">{translations?.sections.howItWorksSection3Subtitle}</h2>
        </div>
      </div>
      <div className="row row-cols-lg-3">
        {factors.map((factor, index) => (
          <div className="col p-5" key={index}>
             {renderIcon(factor.icon_name)}
            <h5 className="mt-4">{factor.title}</h5>
            <p>{factor.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>


  
  <section className="space-top space-bottom bg-smoke">
    <div className="container">
      <div className="row">
          <div className="col-lg-6">
          <img src={translations?.sections.howItWorksSection2Image} alt="" />
          </div>
          <div className="col-lg-6">
            <span className="sec-subtitle">{translations?.sections.howItWorksSection2Title}</span>
        
            <h3 className="decorative-title display-4 fw-semibold mb-3 mb-md-2 mb-lg-5"
            ref={ref1}
>
          {trimLastTwoWords(translations?.sections.howItWorksSection2Subtitle)}
          <animated.span 
    style={titleAnimation1}
                          className="ms-1 ps-2 word-bg smoke">
            {getLastTwoWords(translations?.sections.howItWorksSection2Subtitle)}
          </animated.span> 
        </h3>
        
            <p className="font-md">{translations?.sections.howItWorksSection2Text}</p>
            <Link to="/features" className='feature-btn'>
            <span className="feature-btn-icon">
            <i className="fa fa-angle-right"></i>
            </span>
            <span className="feature-btn-text z-0">
            {translations?.actions.learnMore}
            </span>
          </Link>
          </div>
      </div>
    </div>
  </section>

  <section className="space-top space-bottom">
    <div className="container">
    <div className="row">
        <div
          className="title-area text-center wow fadeInUp"
        >
          <div className="sec-icon">
            <div className="vs-circle" />
          </div>
          <span className="sec-subtitle">{translations?.defs.howItWorks}</span>
          <h2 className="sec-title h1 mx-auto">{translations?.defs.startInSeconds} </h2>
        </div>
      </div>
      <div className="row py-5">
          <div className="col-lg-6">
            <img src={translations?.sections.howItWorksStep1Image} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2>{translations?.sections.howItWorksStep1Title}</h2>
              <p className="font-md">{translations?.sections.howItWorksStep1Text}</p>
            </div>
          </div>
      </div>
      <div className="connector">
        <img src="assets/connector.svg" />
      </div>
      <div className="row py-5 reverse-order">
          <div className="col-lg-6">
            <img src={translations?.sections.howItWorksStep2Image} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2>{translations?.sections.howItWorksStep2Title}</h2>
              <p className="font-md">{translations?.sections.howItWorksStep2Text}</p>
            </div>
          </div>
      </div>
      <div className="connector">
        <img src="assets/connector-reverse.svg" alt="Connector" />
      </div>
      <div className="row py-5">
          <div className="col-lg-6">
            <img src={translations?.sections.howItWorksStep3Image} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2>{translations?.sections.howItWorksStep3Title}</h2>
              <p className="font-md">{translations?.sections.howItWorksStep3Text}</p>
            </div>
          </div>
      </div>
      <div className="connector">
        <img src="assets/connector.svg" />
      </div>
      <div className="row py-5 reverse-order">
          <div className="col-lg-6">
            <img src={translations?.sections.howItWorksStep4Image} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2>{translations?.sections.howItWorksStep4Title}</h2>
              <p className="font-md">{translations?.sections.howItWorksStep4Text}</p>
            </div>
          </div>
      </div>
    </div>
  </section>

  <section className="overflow-hidden bgc-f6 space-top space-extra-bottom shape-mockup-wrap">
  <div class="shape-mockup jump d-none d-xxl-block" style={{left: '-14%'}}><div class="vs-border-circle"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{top: '170px', right: '-15%'}}><div class="vs-circle color2"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{bottom: '40%', left: '8%'}}><div class="shape-dotted style2"></div></div>
    <div className="container">
        <div className="row">
            <div className="col">
            <div className="d-flex flex-column align-items-center">
                <h5 className="sec-subtitle">{translations?.sections.howItWorksTestiTitle}</h5>
                <h1 className="display-4 fw-semibold text-center mb-3 mb-md-2 mb-lg-5">
                {translations?.defs.pros} <FaHeart color="red" /> {translations?.defs.appName}
                </h1>
          </div>
            </div>
        </div>


        <div className="testi1-slider-wrapper row my-5">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={true}
              slidesToShow={3}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                  },
                },
              ]}

        >
            {testimonials.map((testi, index) => (


<div className="testi-style1" key={index}>
  <div className="testi-content">
    <p className="testi-text">{testi.review}</p>
  </div>
  <div className="testi-client">
    <img src={testi.user_avatar} alt="author" />
    <h3 className="testi-name h5">{testi.name}</h3>
    <span className="testi-degi">{testi.profession}</span>
    <div className="testi-rating">
    {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
    </div>
  </div>
</div>


            ))}
        </Slider>
        </div>

        
    </div>
  </section>

    </Layout>
  );
}

export default HowItWorks;
