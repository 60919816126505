import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../components/Layout';
import { useParams } from "react-router-dom";
import { FaStar } from 'react-icons/fa';
  import { FaCheck } from "react-icons/fa6";

  import PageHeader from '../../components/PageHeader';

  import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AppContext } from '../../context/AppContext';

const CourseDetail = ({ courses }) => {

  const { translations } = useContext(AppContext);

  const { id } = useParams();


  const [course, setCourse] = useState()
    
  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(
      `https://webapi.meetods.com/api/v1/popular-courses/${id}`,
      setCourse
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <Layout>
<>
    <PageHeader title="Course Detail" />
    <section className="course-details space-top space-extra-bottom">
  <div className="container">
    <div className="row flex-row-reverse gx-50">
      <div className="col-lg-8">
        <div className="mega-hover course-img">
          <img src={course?.top_image} alt="girl" />
        </div>
        <div className="course-category">
          <a href="course.html">{course?.category.name}</a>
        </div>
        <h2 className="course-title">
          {course?.name}
        </h2>
        <div className="course-review">
        {[...Array(course?.rating)].map((_, index) => (
    <i className="fas fa-star" key={index} />
  ))}                
                ( {Number.isInteger(course?.rating) ? course?.rating + ".0" : course?.rating} )
        </div>
        <div className="course-meta">
          <div className="meta-item">
            <i className="fa fa-hourglass" />
            <span className="meta-title text-capise">{translations?.defs.duration}:</span>
            <p className="meta-text">{course?.course_weeks} Week</p>
          </div>
          <div className="meta-item">
            <i className="fa fa-clock" />
            <span className="meta-title text-capitalize">{translations?.defs.weekly_study}:</span>
            <p className="meta-text text-capitalize">{course?.weekly_study} {translations?.defs?.hours}</p>
          </div>
          <div className="meta-item">
            <i className="fa fa-user-alt" />
            <span className="meta-title text-capitalize">{translations?.defs.students}:</span>
            <p className="meta-text text-capitalize">{course?.student_count} {translations?.defs.students}</p>
          </div>
          <div className="meta-item">
            <i className="fa fa-suitcase" />
            <span className="meta-title text-capitalize">{translations?.defs.course_type}:</span>
            <p className="meta-text">{course?.type}</p>
          </div>
        </div>
        <div className="row justify-content-between align-items-center gy-4">
          <div className="col-auto">
            <div className="course-price">
              <span className="currency">$</span>{course?.monthly_price}<small>/{translations?.defs.month}</small>
            </div>
          </div>
          <div className="col-auto">
            <a className="vs-btn" href="contact.html">
            {translations?.actions.joinCourse}
            </a>
          </div>
        </div>
        <h5 className="border-title2">{translations?.defs.overview}</h5>
        <p>
          {course?.overview}
        </p>
        <div className="list-style1 vs-list">
          <ul>
            {course?.benefits.map((benefit, index) => (
              <li className="ps-0" key={index}>
              <FaCheck className="text-primary" />
              <span className="text-muted ms-2">
                {benefit}
              </span>
            </li>
            ))}
          </ul>
        </div>
        <div className="inner-video-box">
          <img src={course?.video_cover} alt="course video" />
          <a
            href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
            className="play-btn position-center popup-video"
          >
            <i className="fas fa-play" />
          </a>
        </div>
        <h5 className="border-title2 text-capitalize">{translations?.defs.syllabus}</h5>
        <div className="accordion accordion-style4" id="faqVersion2">


        {course?.syllabus && Object.keys(course.syllabus).map((week, index) => (
          <div className="accordion-item" key={index}>
          <div className="accordion-header" id={`heading${week}`}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${week}`}
              aria-expanded="true"
              aria-controls={`collapse${week}`}
            >
        <span className="button-label text-capitalize">Week {course?.syllabus[week].week}</span>
        {course?.syllabus[week].title}
            </button>
          </div>
          
          <div id={`collapse${week}`} className="accordion-collapse collapse" aria-labelledby={`heading${week}`} data-bs-parent="#faqVersion2">
            <div className="accordion-body">
              <div className="syllabus-list">
                <div className="syllabus-img">
                  <img src={course?.syllabus[week].subject1_image} alt="" />
                </div>
                <div className="syllabus-content">
                  <h6 className="syllabustitle">{course?.syllabus[week].subject1_title}</h6>
                  <p className="syllabustext">
                  {course?.syllabus[week].subject1_description}
                  </p>
                </div>
              </div>
              <div className="syllabus-list">
                <div className="syllabus-img">
                  <img src={course?.syllabus[week].subject2_image} alt="" />
                </div>
                <div className="syllabus-content">
                  <h6 className="syllabustitle">
                  {course?.syllabus[week].subject2_title}
                  </h6>
                  <p className="syllabustext">
                  {course?.syllabus[week].subject2_description}
                  </p>
                </div>
              </div>
              <div className="syllabus-list">
                <div className="syllabus-content">
                  <h6 className="syllabustitle">{course?.syllabus[week].subject3_title}</h6>
                  <p className="syllabustext">
                  {course?.syllabus[week].subject3_description}
                  </p>
                </div>
              </div>
              <div className="syllabus-list">
                <div className="syllabus-content">
                  <h6 className="syllabustitle">
                  {course?.syllabus[week].subject4_title}
                  </h6>
                  <p className="syllabustext">
                  {course?.syllabus[week].subject4_description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
))}
</div> 
        <h5 className="border-title2">{translations?.defs.when_start}</h5>
        <p>
          Start straight away and join a global classroom of learners. If the
          course hasn’t started yet you’ll see the future date listed below.
        </p>
        <a href="contact.html" className="vs-btn">
          Join Today
        </a>
        {course?.is_avaible ? (
          <span className="available-badge">{translations?.defs?.availableNow}</span>
        ) : (
          <span className="available-badge bg-danger">{translations?.defs?.notAvailable}</span>
        )}
        <div className="mt-4 pt-lg-2">
          <h5 className="border-title2">{translations?.defs.what_achieve}</h5>
          <div className="list-style1 vs-list">
            <ul className="course-achievements">
            {course?.achievements.map((achievement, index) => (
              <li key={index} className="ps-0"> <FaCheck className="text-primary" /> {achievement}</li>
            ))}
            </ul>
          </div>
        </div>
        <h5 className="border-title2">{translations?.defs.who_learn_with}</h5>

        <div className="course-tutor-slider-wrapper">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={false}
              slidesToShow={2}
              centerMode={true}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: true,
                    slidesToShow: 1,
                  },
                },
              ]}
              className="row vs-carousel "
        >
        {course?.tutors.map((tutor, index) => (
          <div className="" key={index}>
          <div className="team-style1">
            <div className="team-img">
              <img
                className="w-100"
                src={tutor.image}
                alt="teacher"
              />
            </div>
            <div className="team-content">
              <div className="team-review">
              {[...Array(tutor.rating)].map((_, index) => (
    <i className="fas fa-star" key={index} />
  ))}      
              </div>
              <h4 className="team-name">
                <a href="team-details.html">{tutor.name}</a>
              </h4>
              <p className="team-degi">{tutor.branch}</p>
              <p className="team-text">
                {tutor.description}
              </p>
            </div>
          </div>
        </div>
        ))}
        </Slider>
        </div>

      </div>
      <div className="col-lg-4 pt-4 pt-lg-0">
        <aside>
          <div className="widget   ">
            <h3 className="widget_title">{translations?.defs.academic_courses}</h3>
            <div className="course-list">
              <ul>
                <li>
                  <a href="course-details.html">
                    <i className="fa fa-book" />
                    2023 Face to Face Courses
                  </a>
                </li>
                <li>
                  <a href="course-details.html">
                    <i className="fa fa-book" />
                    Introduction to Computer
                  </a>
                </li>
                <li>
                  <a href="course-details.html">
                    <i className="fa fa-book" />
                    Erasmus Funding course
                  </a>
                </li>
                <li>
                  <a href="course-details.html">
                    <i className="fa fa-book" />
                    Networking for Teachers
                  </a>
                </li>
                <li>
                  <a href="course-details.html">
                    <i className="fa fa-book" />
                    Naturally &amp; Safely Boost
                  </a>
                </li>
                <li>
                  <a href="course-details.html">
                    <i className="fa fa-book" />
                    Introduction to Computer
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 col-lg-12">
              <div
                className="img-box5 mega-hover wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="img-1">
                  <img
                    className="w-100"
                    src="/about-2-4.jpg"
                    alt="About Img"
                  />
                </div>
                <div className="box-content">
                  <h3 className="img-title">{translations?.sections.onlineRegiTitle}</h3>
                  <p className="img-text">
                  {translations?.sections.onlineRegiSubtitle}
                  </p>
                  <a href="contact.html" className="vs-btn">
                  {translations?.actions.applyToday}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-12">
              <div className="media-style5 wow fadeInUp" data-wow-delay="0.4s">
                <span className="icon">
                  <i className="fa fa-headset" />
                </span>
                <h5 className="media-title">{translations?.defs.admission_process}</h5>
                <a className="phone" href={translations?.defs.tel}>
                  <i className="fa fa-phone-alt" />
                  {translations?.defs.tel}
                </a>
                <a className="mail" href={translations?.defs.mail}>
                  <i className="fa fa-envelope" />
                  {translations?.defs.mail}
                </a>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </div>
</section>
</>
    </Layout>
  );
};

export default CourseDetail;
