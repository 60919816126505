import React, {useEffect, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
const dynamic_loader = event => {
    let load = event.target.dataset.load;
    let load_url = event.target.dataset.loadUrl;
    let load_id = event.target.value;
    if (load && load_url && load_id) {
        fetch(`${load_url}${load_id}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    let options = data.data.map((option, index) => {
                        return ReactDOMServer.renderToString(<option value={option.id}>{option.name}</option>);
                    });
                    options.unshift(`<option value="">Lütfen Seçin</option>`);
                    document.querySelector(`select[name="${load}"]`).innerHTML = options.join("");
                }
            });
    }
}
let dataExists = false;

const RegisterStep = ({field,name, orderData}) => {
    // look for orderData.custom_field exists
    if (orderData?.custom_field) {
        dataExists = true;
    }
    switch (field.type_id) {
        case 1:
            return (
                <div className={"col-12"}>
                    <div className="form-group">
                        <label htmlFor={name}>{field.name} <span className={field.is_required ? 'text-danger' : 'd-none'}>*</span> </label>
                        <input type={field.type} className="form-control" id={name} name={name}
                               placeholder={field.placeholder ?? 'Lütfen Doldurunuz'} required={field.is_required}
                                 defaultValue={dataExists ? orderData.custom_field[field.id] : ''}
                        />
                    </div>
                </div>
            );
        case 2:
            return (
                <div className={"col-12"}>
                    <div className="form-group">
                        <label htmlFor={name}>{field.name} <span className={field.is_required ? 'text-danger' : 'd-none'}>*</span> </label>
                        <select className="form-control" id={name} name={name} required={field.is_required}>
                            <option value={""}>{field.placeholder ?? 'Lütfen Seçiniz'}</option>
                            {field.values.map((option, index) => {
                                return <option
                                    value={option?.id}
                                    key={index}
                                    // selected={orderData.custom_field[field?.id] == option?.id}
                                    selected={dataExists ? orderData.custom_field[field.id] == option.id : ''}
                                >{option.value}</option>
                            })}
                        </select>
                    </div>
                </div>
            );
        default:
            return (
                <h1>DEFAULT</h1>
            );
    }

};

export default RegisterStep;
