import React, { useState, useEffect, useContext } from 'react';
import { FaStar } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import {
    FaUser,
    FaRegClock,
    FaRegStar
  } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppContext } from '../../context/AppContext';

// Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";

const PopularCourses = ({}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [popularCourses, setPopularCourses] = useState([]);
    const { translations } = useContext(AppContext);

    useEffect(() => {
      const fetchData = async (url, setter) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setter(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData(
        "https://webapi.meetods.com/api/v1/popular-courses",
        setPopularCourses
      );
    }, []);

  return (
    <section className="space-top space-bottom">
    <div className="container">
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <div className="sec-icon">
              <div className="vs-circle"></div>
            </div>
            <h5 className="sec-subtitle">{translations?.sections.popularCoursesTitle}</h5>
            <h1 className="display-4 fw-semibold text-center">
            {translations?.sections.popularCoursesSubtitle}
            </h1>
          </div>
        </div>
      </div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={false}
        thumbs={{
          swiper:
            thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {popularCourses.map((course, index) => {
         /* const stars = Array.from(
            { length: course.rating },
            (_, index) => index + 1
          ); */
          const stars = ["1", "2", "3", "4", "5"]
          const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
            const discount = (originalPrice * discountPercentage) / 100;
            const discountedPrice = originalPrice - discount;
            return discountedPrice.toFixed(2); 
          };
          return (
            <SwiperSlide key={index}>
              <div className="card course-style4 course-card d-flex flex-column flex-lg-row">
                    <div className="course-img">
                      <img
                        src={course.course_image}
                        alt=""
                        className="rounded-4"
                      />
                    </div>
                    <div className="course-content d-flex h-100 justify-content-center flex-column">
                      <div className="course-category"><a>{course.category.name}</a></div>
                      <h3 className="course-name fw-bold">
                        <a tabIndex={0}>{course.name}</a>
                      </h3>
                      <div className="course-meta">
                        <a href="course.html" tabindex="0">
                          <FaUser className="me-2" />
                          <span className="total-student">
                            {course.student_count} {translations?.defs.students}
                          </span>
                        </a>
                        <a href="course.html" tabindex="0">
                          <FiMonitor className="me-2" />
                          <span className="course-hour">
                            {course.lesson_count} {translations?.defs?.lesson}
                          </span>
                        </a>
                        <a href="course.html" tabindex="0">
                          <FaRegClock className="me-2" />
                          <span className="course-hour">
                            {course.duration} {translations?.defs?.minutes}
                          </span>
                        </a>
                      </div>
                      <div className="course-price mb-4">
                        <span className="price">
                          {course.discount_percentage === 0 ? (
                            <>
                              ${course.price}
                            </>
                          ) : (
                            <>
                              <del>${course.price}</del> 
                              <span className="ps-2">${calculateDiscountedPrice(course.price, course.discount_percentage)}</span>
                            </>
                          )}

                          
                        </span>
                        {course.discount_percentage === 0 ? (
                          <>
                          </>
                        ) : (
                          <small className="discount">
                          {course.discount_percentage}% {translations?.defs?.discount}
                        </small>
                        )}
                      </div>
                      <div className="course-bottom d-flex justify-content-between align-items-center">
                        <div className="course-author">
                          <a
                            href="team-details.html"
                            className="text-inherit"
                            tabIndex={0}
                          >
                            <img src={course.course_tutor_avatar} alt="" />
                             {course.course_tutor}
                          </a>
                        </div>
                        <div className="course-review d-flex align-items-center gap-1">
  {[...Array(course.rating)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
  <span className="review-avarage text-white ps-1">
    ( {Number.isInteger(course.rating) ? course.rating + ".0" : course.rating} )
  </span>
</div>


                      </div>

                      <div className="d-flex mt-5">
                        <a href={course.course_preview_link} class="vs-btn style5 popup-video" tabindex="0"><i class="fas fa-play"></i>{translations?.actions?.previewCourse}</a>
                      </div>
                    </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={20}
        breakpoints={{
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
        freeMode={false}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="courseThumbs"
      >
        {popularCourses.map((course, index) => {
        /*  const stars = Array.from(
            { length: course.rating },
            (_, index) => index + 1
          ); */
          const stars = ["1", "2", "3", "4", "5"]
          return (
            <SwiperSlide key={index}>
              <div className="course-thumb-img">
                <img src={course.course_image} className="rounded-4" />
                <div className="course-thumb-review rounded-pill px-2 bg-white d-flex align-items-center gap-1">
                {[...Array(course.rating)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  </section>
  );
}

export default PopularCourses;
