import React, { useContext, useState, useEffect } from 'react';
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineEnvelope } from "react-icons/hi2";
import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
  } from "react-icons/fa";
  import { PiCopyrightLight } from "react-icons/pi";
  import { AppContext } from '../context/AppContext';
  import { Link } from 'react-router-dom';


const FooterInner = () => {
    const { translations } = useContext(AppContext);
    const currentYear = new Date().getFullYear();

    const { blogs } = useContext(AppContext);

    return (
<>
<footer className="footer-wrapper footer-layout1">
  <div
    className="shape-mockup jump d-none d-xxxl-block"
    style={{ bottom: '0%', left: -270 }}
  >
    <div className="vs-border-circle" />
  </div>
  <div className="widget-area">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-md-6 col-xl-auto">
          <div className="widget footer-widget">
            <div className="vs-widget-about">
              <div className="footer-logo">
                {" "}
                <Link to="/">
                  <img src="/logo-white.png" alt="Meetods" />
                </Link>{" "}
              </div>
              <p className="footer-text">
                {translations?.defs.footerDesc1}
              </p>
              <p className="footer-info">
              <span className="footer-info-icon">
                  <HiOutlinePhone size={20} />
                </span>
                <a className="text-inherit" href={`tel:${translations?.defs.phone_number}`}>
                {translations?.defs.phone_number}
                </a>
              </p>
              <p className="footer-info">
              <span className="footer-info-icon">
                  <HiOutlineEnvelope size={20} />
                </span>
                <a className="text-inherit" href={`mailto:${translations?.defs.email}`}>
                {translations?.defs.email}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-xl-auto">
          <div className="widget nav_menu footer-widget">
            <h3 className="widget_title">{translations?.sections.footerCol1Title}</h3>
            <div className="menu-all-pages-container footer-menu">
              <ul className="menu">
                <li>
                  <a href={translations?.sections.footerLink1Href}>{translations?.sections.footerLink1Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.footerLink2Href}>{translations?.sections.footerLink2Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.footerLink3Href}>{translations?.sections.footerLink3Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.footerLink4Href}>{translations?.sections.footerLink4Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.footerLink5Href}>{translations?.sections.footerLink5Name}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-xl-auto">
          <div className="widget nav_menu footer-widget">
            <h3 className="widget_title">{translations?.sections.footerCol2Title}</h3>
            <div className="menu-all-pages-container footer-menu">
              <ul className="menu">
                <li>
                  <a href={translations?.sections.quickLink1Href}>{translations?.sections.quickLink1Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.quickLink2Href}>{translations?.sections.quickLink2Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.quickLink3Href}>{translations?.sections.quickLink3Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.quickLink4Href}>{translations?.sections.quickLink4Name}</a>
                </li>
                <li>
                  <a href={translations?.sections.quickLink5Href}>{translations?.sections.quickLink5Name}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-auto">
          <div className="widget  footer-widget">
            <h3 className="widget_title">{translations?.sections.footerCol3Title}</h3>
            <div className="recent-post-wrap">
              {blogs?.slice(-2).map(blog => (
                <div className="recent-course" key={blog.id}>
                <div className="media-img">
                  <a>
                    <img
                      src={blog.image}
                      alt={blog.title}
                      width={100}
                      height={100}
                    />
                  </a>
                </div>
                <div className="media-body">
                  <div className="recent-course-meta">
                    <a className="text-decoration-none">{blog.user.first_name}</a>
                  </div>
                  <h4 className="post-title">
                  <Link to={`/blog/${blog.id}`} className="text-inherit text-decoration-none">                
                      {blog.title}
                   </Link>
                  </h4>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-wrap">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="text-center col-lg-auto">
            <p class="copyright-text">
              {translations?.defs?.copyright} <PiCopyrightLight size="22" /> {currentYear}{" "}{" "}
              <a href="/">{translations?.defs?.appName}</a>. {translations?.defs?.allRightsReserv}{" "}
              <a href="https://swantro.com" target="_blank">{translations?.defs?.companyName}</a>
            </p>
          </div>
          <div class="col-auto d-none d-lg-block">
            <div class="social-style1">
              <a href={translations?.defs?.facebookLink}>
                <span className="social-icon text-white">
                  <FaFacebookF />
                </span>
                <span className="social-text text-white">{translations?.defs.social1Name}</span>
              </a>
              <a href={translations?.defs?.twitterLink}>
              <span className="social-icon text-white">
                  <FaTwitter />
                </span>
                <span className="social-text text-white">{translations?.defs.social2Name}</span>
              </a>
              <a href={translations?.defs?.linkedinLink}>
              <span className="social-icon text-white">
                  <FaLinkedinIn />
                </span>
                <span className="social-text text-white">{translations?.defs.social3Name}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</footer>

</>
    )
}

export default FooterInner;