import React, {useEffect, useState} from 'react';
import {FaMoneyCheck, FaVideo} from "react-icons/fa";
import {AiOutlineCalendar, AiOutlineClockCircle} from "react-icons/ai";
import {BsArrowLeft, BsFillPeopleFill} from "react-icons/bs";
import CustomFieldRender from "./render/CustomFieldRender";
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import ReactDOMServer from "react-dom/server";
import BookingParticipant from "./BookingParticipant";

const RegisterStep = ({orderData}) => {

    const [fields, setFields] = useState([]);
    const [field_keys, setFieldkeys] = useState([]);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchData = async (url, setter, keysetter, countrysetter) => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }
                const data = await response.json();
                if (data.status === true && Object.values(data.data.custom_fields).length) {
                    setter(Object.values(data.data.custom_fields));
                    keysetter(Object.keys(data.data.custom_fields));
                    countrysetter(Object.values(data.data.inputs.country_id.values));
                } else {
                    console.log("Fieldlar Alınamadı");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(
            "https://app.meetods.com/api/v1/register-fields",
            setFields,
            setFieldkeys,
            setCountries
        )
    }, []);
    const dynamic_select_loader = event => {
        let load = event.target.dataset.load;
        let load_url = event.target.dataset.loadUrl;
        let load_id = event.target.value;
        if (load && load_url && load_id) {
            fetch(`${load_url}${load_id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.status === true) {
                        let options = data.data.map((option, index) => {
                            return ReactDOMServer.renderToString(<option value={option.id}>{option.name}</option>);
                        });
                        options.unshift(`<option value="">Seçiniz</option>`);
                        document.querySelector(`select[name="${load}"]`).innerHTML = options.join("");
                    }
                });
        }
    }

    const billing_change = event => {
        let billing = event.target.checked;
        let billing_inputs = document.querySelectorAll(`#billing_data input`);
        let billing_textareas = document.querySelectorAll(`#billing_data textarea`);
        let billing_selects = document.querySelectorAll(`#billing_data select`);
        if (billing) {
            billing_inputs.forEach((input, index) => {
                input.required = true;
            });
            billing_selects.forEach((select, index) => {
                select.required = true;
            });
            billing_textareas.forEach((textarea, index) => {
                textarea.required = true;
            });
        } else {
            billing_inputs.forEach((input, index) => {
                input.required = false;
            });
            billing_selects.forEach((select, index) => {
                select.required = false;
            });
            billing_textareas.forEach((textarea, index) => {
                textarea.required = false;
            });
        }
    }

    useEffect(() => {
        if (orderData.billing_country_id && orderData.billing_country_id !== "") {
            document.getElementById("billing_country_id").value = orderData.billing_country_id;
            // load city
            fetch(`https://app.meetods.com/api/v1/country/${orderData.billing_country_id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.status === true) {
                        let options = data.data.map((option, index) => {
                            return ReactDOMServer.renderToString(<option value={option.id}>{option.name}</option>);
                        });
                        options.unshift(`<option value="">Seçiniz</option>`);
                        document.querySelector(`select[name="billing_city_id"]`).innerHTML = options.join("");
                        // set value
                        document.querySelector(`select[name="billing_city_id"]`).value = orderData.billing_city_id;
                    }
                });

            if (orderData.billing_city_id && orderData.billing_city_id !== "") {
                fetch(`https://app.meetods.com/api/v1/country/city/${orderData.billing_city_id}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === true) {
                            let options = data.data.map((option, index) => {
                                return ReactDOMServer.renderToString(<option value={option.id}>{option.name}</option>);
                            });
                            options.unshift(`<option value="">Seçiniz</option>`);
                            document.querySelector(`select[name="billing_state_id"]`).innerHTML = options.join("");
                            // set value
                            document.querySelector(`select[name="billing_state_id"]`).value = orderData.billing_state_id;
                        }
                    });
            }
        }
    }, [orderData]);

    return (
        <>
            <div className="row mt-3 mb-5">
                <div className="col-lg-6">
                    <div className="d-flex flex-column">
                        <div className="pe-0 pe-lg-5 me-0 me-lg-5">
                            <h5>Toplantı Bilgileri</h5>
                            <p className="text-muted">
                                Toplantı bilgileri açıklama vsvsvs
                            </p>
                        </div>
                        <div className="d-flex align-items-center gap-2 mb-3">
                            <FaVideo size="24"/>
                            <p className="mb-0">Katılım adresi email üzerinden iletilecektir.</p>
                        </div>
                        <div className="d-flex align-items-center gap-2 mb-3">
                            <AiOutlineCalendar size="24"/>
                            <p className="mb-0 dynamic-meet-date">00.00.0000 00:00</p>
                        </div>
                        <div className="d-flex align-items-center gap-2 mb-3">
                            <AiOutlineClockCircle size="24"/>
                            <p className="mb-0 dynamic-meet-duration"></p>
                        </div>

                        <div className="d-flex align-items-center gap-2 mb-3">
                            <FaMoneyCheck size="24"/>
                            <p className="mb-0 dynamic-meet-package"></p>
                        </div>

                        <div className="d-flex align-items-start gap-2 mb-3">
                            {/*<BsFillPeopleFill size="24"/>*/}
                            <table className="table table-bordored mb-0">
                                <thead>
                                <tr>
                                    <th scope="col">Katılımcı</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody id={"participant-list"}>

                                </tbody>
                            </table>

                            <div className="row ms-1 mb-0">

                            </div>
                            <ul className="list-unstyled ms-1 mb-0">

                            </ul>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className={"row"}>
                        <BookingParticipant orderData={orderData}/>
                        {fields.map((field, index) => {
                            return (
                                <div key={index}>
                                    <CustomFieldRender field={field} name={field.input_name} orderData={orderData}/>
                                </div>
                            );
                        })}
                        <div className="col-12">
                            <div className="form-group">
                                <input className="form-check-input" type="checkbox" name="terms"
                                       id="terms" required={true}/>
                                <label className="form-check-label" htmlFor="terms">
                                    Koşulları ve şartları kabul ediyorum
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <input className="form-check-input" type="checkbox" name="billing_info"
                                       id="billing_info"
                                       onChange={billing_change}
                                       defaultChecked={orderData.billing_info}
                                />
                                <label className="form-check-label" htmlFor="billing_info" data-bs-toggle="collapse"
                                       data-bs-target="#billing_data" aria-expanded="false"
                                       aria-controls="billing_data">
                                    Fatura Bilgilerim Farklı
                                </label>
                            </div>
                            <div
                                className={orderData.billing_info ? "collapse show" : "collapse"}
                                id="billing_data">
                                <div className="card card-body border-0 p-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_country_id">Fatura Ülke <span
                                                    className="text-danger">*</span></label>
                                                <select name="billing_country_id" id="billing_country_id"
                                                        className="form-control"
                                                        data-load="billing_city_id"
                                                        data-load-url="https://app.meetods.com/api/v1/country/"
                                                        onChange={dynamic_select_loader}
                                                        required={orderData.billing_info}
                                                        
                                                >
                                                    <option value="">Ülke Seçiniz</option>
                                                    {countries.map((option, index) => {
                                                        return <option
                                                            value={option.id}
                                                            key={option.id}
                                                            selected={orderData.billing_country_id == option.id}
                                                        >{option.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_city_id">Fatura Şehir <span
                                                    className="text-danger">*</span></label>
                                                <select name="billing_city_id" id="billing_city_id"
                                                        className="form-control"
                                                        data-load="billing_state_id"
                                                        data-load-url="https://app.meetods.com/api/v1/country/city/"
                                                        onChange={dynamic_select_loader}
                                                        required={orderData.billing_info}
                                                >
                                                    <option value="">Önce Ülke Seçiniz</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_state_id">Fatura İlçe <span
                                                    className="text-danger">*</span></label>
                                                <select name="billing_state_id" id="billing_state_id"
                                                        className="form-control"
                                                        required={orderData.billing_info}
                                                >
                                                    <option value="">Önce Ülke ve Şehir Seçiniz</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_address">Fatura Adres <span
                                                    className="text-danger">*</span></label>
                                                <textarea name="billing_address" id="billing_address"
                                                          rows="3"
                                                          defaultValue={orderData.billing_address}
                                                            required={orderData.billing_info}
                                                          className="form-control">
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_name">Firma Adı veya Adı Soyadı <span
                                                    className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="billing_name"
                                                       name="billing_name"
                                                       placeholder="Fatura Adı Soyadı"
                                                       defaultValue={orderData.billing_name}
                                                         required={orderData.billing_info}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_tax_office">Vergi Dairesi <span
                                                    className="text-danger">*</span></label>
                                                <input type="email" className="form-control" id="billing_tax_office"
                                                       name="billing_tax_office"
                                                       placeholder="Vergi Dairesi"
                                                       defaultValue={orderData.billing_tax_office}
                                                         required={orderData.billing_info}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="billing_tax_number">Vergi Numarası veya TC Kimlik
                                                    No <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="billing_tax_number"
                                                       name="billing_tax_number"
                                                       placeholder="Vergi Numarası veya TC Kimlik No"
                                                       defaultValue={orderData.billing_tax_number}
                                                       required={orderData.billing_info}
                                                       onKeyPress={(e) => {
                                                           const onlyNumbers = /^[0-9\b]+$/;
                                                           if (!onlyNumbers.test(e.key)) {
                                                               e.preventDefault();
                                                           }
                                                       }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterStep;
