import React, {useContext, useEffect, useState} from 'react';
import {BsArrowLeft, BsArrowRight} from "react-icons/bs";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "./date/DatePicker";
import HourPicker from "./date/HourPicker";
import ReactDOMServer from "react-dom/server";

const DateStep = ({orderData}) => {
    const datePicking = (date) => {
        document.getElementById("randevutarih").value = date;
        document.getElementsByClassName('dynamic-meet-date')[0].innerHTML = date;
        document.getElementById("selectedDateSpan").innerHTML = date;
        document.getElementById("randevusaat").value = "";

        fetch('https://app.meetods.com/api/v1/calendar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: document.querySelector('input[name="package_user_id"]:checked').value,
                package_id: document.querySelector('input[name="package_id"]:checked').value,
                date: date,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == true) {
                    let hours = data.meet_days;
                    document.getElementById("dynamic-hour-picker").innerHTML = "";
                    document.getElementById("dynamic-hour-picker").innerHTML = ReactDOMServer.renderToString(<HourPicker hours={hours} selected={null}/>);
                }else{
                    toast("Bu tarih için randevu alınamaz", {
                        autoClose: 2000,
                        type: toast.TYPE.ERROR,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    document.addEventListener("click", function (event) {
        if (event.target.matches(".btn-hour")) {
            let time = event.target.dataset.time;
            if (document.getElementById("randevutarih").value === "") {
                toast("Lütfen önce tarih seçiniz", {
                    autoClose: 2000,
                    type: toast.TYPE.ERROR,
                });
                return;
            }
            document.getElementById("randevusaat").value = time;
            document.getElementsByClassName('dynamic-meet-date')[0].innerHTML = document.getElementById("randevutarih").value + ' ' + time;
            let btnHours = document.querySelectorAll(".btn-hour");
            btnHours.forEach(btnHour => {
                btnHour.classList.remove("btn-primary");
                btnHour.classList.add("btn-secondary");
            });
            event.target.classList.remove("btn-secondary");
            event.target.classList.add("btn-primary");
        }
    });

    useEffect(() => {
        if (orderData.meet_date !== null && orderData.meet_hour !== null && orderData.meet_date !== "" && orderData.meet_hour !== "" && orderData.meet_date !== undefined && orderData.meet_hour !== undefined) {
            document.getElementsByClassName('dynamic-meet-date')[0].innerHTML = orderData.meet_date + ' ' + orderData.meet_hour;
            document.getElementById("selectedDateSpan").innerHTML = orderData.meet_date;
            fetch('https://app.meetods.com/api/v1/calendar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: orderData.package_user_id,
                    package_id: orderData.package_id,
                    date: orderData.meet_date,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == true) {
                        let hours = data.meet_days;
                        document.getElementById("dynamic-hour-picker").innerHTML = "";
                        document.getElementById("dynamic-hour-picker").innerHTML = ReactDOMServer.renderToString(<HourPicker hours={hours} selected={orderData.meet_hour}/>);

                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

    }, []);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="booking-step-title">Randevu Tarihi Belirleyin</h3>
            </div>
            <div className="row mt-3">
                <div className="col-lg-8">
                    <div className="card picker-card border-0 shadow">
                        <div className="card-body d-flex flex-column align-items-center justify-content-center p-0">
                            <div className={"w-100 h-100"}>
                                <DatePicker datePicking={datePicking} selectedDate={orderData.meet_date ?? null}/>
                                <div>
                                    <input type="date" name="meet_date" id="randevutarih"
                                           className={"d-none"} readOnly={true} required={true}
                                           defaultValue={orderData.meet_date ?? null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card bg-white mt-4 mt-lg-0 mt-md-0 border-0 shadow">
                        <div className="cards-column card-body overflow-y-scroll">
                            <p>Seçilen Tarih: <span id="selectedDateSpan" className={"fs-7"}>Seçiniz</span></p>
                            <div id="dynamic-hour-picker">
                            </div>
                        </div>
                        <div>
                            <input type="time" name="meet_hour" id="randevusaat" className={"d-none"} readOnly={true} required={true}
                                      value={orderData.meet_hour ?? null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DateStep;