import React, { useState, useEffect, useContext } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import FooterInner from '../components/FooterInner';
import { AppContext } from '../context/AppContext';
import PreLoader from '../components/PreLoader';

import { FaCheck } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const BecomeTutor = () => {
    const [partners, setPartners] = useState([]);
    const { translations } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
  
  
    useEffect(() => {
        const fetchData = async (url, setter) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData(
          "https://webapi.meetods.com/api/v1/popular-partners",
          setPartners
        )
      }, []);

      if(loading) {
        return (
          <PreLoader />
        )
      }
    
  
  return (
    <Layout>
<>
  <section className="py-4" style={{ backgroundImage: "url('course-bg-pattern.jpg')" }}>
    <div className="container">
      <div className="row flex-row-reverse justify-content-between">
        <div className="col-md-6 col-auto">
          <div className="img-box1">
            <div className="vs-circle">
              <div className="mega-hover">
                <img src={translations?.sections.becomeTutorImage} alt=" banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-5 align-self-center text-center text-md-start pb-5 pb-md-0">
          <h2 className="sec-title2 text-uppercase">{translations?.sections.becomeTutorTitle}</h2>
          <p className="fs-20 col-xl-10 mb-4 pb-xl-2">
          {translations?.sections.becomeTutorText}
          </p>
          <a className="vs-btn" href="contact.html">
          {translations?.sections.becomeTutorButtonText}
          </a>
        </div>
      </div>
    </div>
  </section>
  <>
  <section className="space">
    <div className="container">
      <div className="title-area text-center">
        <div className="sec-icon">
          <div className="vs-circle" />
        </div>
        <span className="sec-subtitle">{translations?.sections.whyBecomeTutorTitle}</span>
        <h2 className="sec-title mx-auto">{translations?.sections.whyBecomeTutorSubtitle}</h2>
      </div>
      <div className="row gx-80 mb-5 pb-4">
        <div className="col-lg-6 col-xl-auto mb-30 mb-lg-0">
          <div className="mega-hover rounded-20 img-shadow1">
            <img src={translations?.sections.whyBecomeTutorRow1Image} alt="tutor" />
          </div>
        </div>
        <div className="col-lg-6 col-xl align-self-center">
          <h3 className="fs-45">{translations?.sections.whyBecomeTutorRow1Title}</h3>
          <p className="fs-md mb-0 me-3">
          {translations?.sections.whyBecomeTutorRow1Text}
          </p>
        </div>
      </div>
      <div className="row gx-80 flex-row-reverse pt-2">
        <div className="col-lg-7 col-xl-auto mb-30 mb-lg-0">
          <div className="mega-hover rounded-20 img-shadow1">
            <img src={translations?.sections.whyBecomeTutorRow2Image} alt="tutor" />
          </div>
        </div>
        <div className="col-lg-5 col-xl align-self-center">
          <h3 className="h2 fs-45">{translations?.sections.whyBecomeTutorRow2Title}</h3>
          <div className="list-style1 vs-list  mb-0">
            <ul>
            <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.whyBecomeTutorRow2List1}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.whyBecomeTutorRow2List2}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.whyBecomeTutorRow2List3}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.whyBecomeTutorRow2List4}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.whyBecomeTutorRow2List5}
                  </span>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
  Service Area
  ==============================*/}
  <section className="space" style={{ backgroundImage:'url("course-bg-pattern.jpg")'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-6 m-auto">
          <div
            className="title-area text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="sec-icon">
              <div className="vs-circle" />
            </div>
            <span className="sec-subtitle">{translations?.sections.benefitsOfClassesTitle}</span>
            <h2 className="sec-title h1">
            {translations?.sections.benefitsOfClassesSubtitle}
            </h2>
          </div>
        </div>
      </div>
      <div className="row wow fadeInUp" data-wow-delay="0.4s">
        <div className="col-sm-6 col-xl-4">
          <div className="media-style9">
            <div className="media-icon">
              <img src={translations?.sections.benefitBox1Icon} alt="icon" />
            </div>
            <h5 className="media-title">{translations?.sections.benefitBox1Title}</h5>
            <p>
            {translations?.sections.benefitBox1Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="media-style9">
            <div className="media-icon">
              <img src={translations?.sections.benefitBox2Icon} alt="icon" />
            </div>
            <h5 className="media-title">{translations?.sections.benefitBox2Title}</h5>
            <p>
            {translations?.sections.benefitBox2Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="media-style9">
            <div className="media-icon">
              <img src={translations?.sections.benefitBox3Icon} alt="icon" />
            </div>
            <h5 className="media-title">{translations?.sections.benefitBox3Title} </h5>
            <p>
            {translations?.sections.benefitBox3Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="media-style9">
            <div className="media-icon">
              <img src={translations?.sections.benefitBox4Icon} alt="icon" />
            </div>
            <h5 className="media-title">{translations?.sections.benefitBox4Title} </h5>
            <p>
            {translations?.sections.benefitBox4Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="media-style9">
            <div className="media-icon">
              <img src={translations?.sections.benefitBox5Icon} alt="icon" />
            </div>
            <h5 className="media-title">{translations?.sections.benefitBox5Title}</h5>
            <p>
            {translations?.sections.benefitBox5Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="media-style9">
            <div className="media-icon">
              <img src={translations?.sections.benefitBox6Icon} alt="icon" />
            </div>
            <h5 className="media-title">{translations?.sections.benefitBox6Title}</h5>
            <p>
            {translations?.sections.benefitBox6Text}
            </p>
          </div>
        </div>
      </div>
      <div className="text-center pt-lg-4">
        <p className="font-body fs-md fw-medium mb-2">
        {translations?.sections.callUsTitle}
        </p>
        <a className="call-number1 h5" href={translations?.sections.callUsNumber}>
        {translations?.sections.callUsNumber}
        </a>
      </div>
    </div>
  </section>
  {/*==============================
  Work Process Area 
  ==============================*/}
  <section className="space-top space-extra-bottom">
    <div className="container">
      <div className="title-area text-center">
        <div className="sec-icon">
          <div className="vs-circle" />
        </div>
        <span className="sec-subtitle">{translations?.sections.howItWorks_alt_Title}</span>
        <h2 className="sec-title h1 mx-auto">{translations?.sections.howItWorks_alt_Subtitle}</h2>
      </div>
      <div className="row align-items-center">
        <div className="col-md-6 col-lg process-inner1 order-2 order-lg-1">
          <div className="process-style1">
            <span className="process-number">1</span>
            <div className="process-content">
              <h3 className="process-title">{translations?.sections.howItWorks_alt_1Title}</h3>
              <p className="process-text">
              {translations?.sections.howItWorks_alt_1Desc}
              </p>
            </div>
          </div>
          <div className="process-style1">
            <span className="process-number">2</span>
            <div className="process-content">
              <h3 className="process-title">
              {translations?.sections.howItWorks_alt_2Title}
              </h3>
              <p className="process-text">
              {translations?.sections.howItWorks_alt_2Desc}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-5 order-1 order-lg-2 mb-30 mb-md-5 mb-lg-0">
          <div className="img-box1 style2">
            <div className="vs-circle">
              <div className="mega-hover">
                <img src="about-2-1.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg process-inner2 order-3">
          <div className="process-style1">
            <span className="process-number">3</span>
            <div className="process-content">
              <h3 className="process-title">{translations?.sections.howItWorks_alt_3Title}</h3>
              <p className="process-text">
              {translations?.sections.howItWorks_alt_3Desc}
              </p>
            </div>
          </div>
          <div className="process-style1">
            <span className="process-number">4</span>
            <div className="process-content">
              <h3 className="process-title">{translations?.sections.howItWorks_alt_4Title}</h3>
              <p className="process-text">
              {translations?.sections.howItWorks_alt_4Desc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>{" "}
  {/*==============================
Brand Area
  ==============================*/}
  <section className="space-extra-bottom">
    <div className="container">
      <div
        className="title-area text-center wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <div className="sec-icon">
          <div className="vs-circle" />
        </div>
        <span className="sec-subtitle">{translations?.sections.topCompaniesTitle} </span>
        <h2 className="sec-title h1 mx-auto">{translations?.sections.topCompaniesSubtitle} </h2>
      </div>
      <div className="row">
      {partners.slice(0,6).map((partner, index) => (
        <div className="col-6 col-lg-4 col-xl-3" key={index}>
        <div className="brand-style1 partner-card-img">
          <img src={partner.image} alt="brand" />
        </div>
      </div>
        ))}
        <div className="col-xl-6">
          <div className="rounded-20 overflow-hidden mega-hover">
            <img
              className="w-100"
              src="about-2-1.jpg"
              alt="brand"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</>




</>

    </Layout>
  );
}

export default BecomeTutor;
