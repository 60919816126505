import React, { useState, useEffect, useContext } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import FooterInner from '../components/FooterInner';
import { AppContext } from '../context/AppContext';

import { FaCheck } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from 'react-router-dom';
import PreLoader from '../components/PreLoader';

const Courses = ({ courses }) => {
  const [loading, setLoading] = useState(true);


    const [testimonials, setTestimonials] = useState([]);
    const [qualifiedTutors, setQualifiedTutors] = useState([]);
    const { translations } = useContext(AppContext);
    
    useEffect(() => {
      const fetchData = async (url, setter) => {
          try {
              const response = await fetch(url);
              if (!response.ok) {
                  throw new Error("Network response was not ok.");
              }
              const data = await response.json();
              setter(data);
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchData(
          "https://webapi.meetods.com/api/v1/qualified-tutors",
          setQualifiedTutors
      );
  }, []);

  useEffect(() => {
      const fetchData = async (url, setter) => {
          try {
              const response = await fetch(url);
              if (!response.ok) {
                  throw new Error("Network response was not ok.");
              }
              const data = await response.json();
              setter(data);
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchData(
          "https://webapi.meetods.com/api/v1/testimonials",
          setTestimonials
      )
  }, []);

  if(loading) {
    return (
      <PreLoader />
    )
  }
  return (
    <Layout>
      <PageHeader title="Courses" />
      <section className="space-top space-extra-bottom">
  <div className="container">
    <div className="row">
        {courses.map((course, index) => {
          const created = course.created_at;
          const dateObject = new Date(created);
          const formattedDate = dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
          return (
            <div className="col-sm-6 col-xl-4" key={index}>
                        <div className="course-style1 has-border">
          <div className="course-img">
            <a href="/">
              <img
                className="w-100"
                src={course.cover_image}
                alt="Course Img"
              />
            </a>
            <div className="course-category">
              <a href="course.html">{course.category.name}</a>
            </div>
            <a
              href={course.video_url}
              className="vs-btn style2 popup-video"
            >
              <i className="fas fa-play" />
              {translations?.actions.previewCourse}
            </a>
          </div>
          <div className="course-content">
            <div className="course-top">
              <div className="course-review">
              {[...Array(course.rating)].map((_, index) => (
    <i className="fas fa-star" key={index} />
  ))}                
                ( {Number.isInteger(course.rating) ? course.rating + ".0" : course.rating} )
              </div>
              <span className="course-price">${course.price}</span>
            </div>
            <h3 className="h5 course-name">
            <Link to={`/courses/${course.id}`} className="text-inherit">                
              {course.name}
            </Link>
            </h3>
            <div className="course-teacher">
              <a className="text-inherit" href="team-details.html">
                {course.course_tutor}
              </a>
            </div>
          </div>
          <div className="course-meta">
            <span>
              <i className="fa fa-users" />
              {course.student_count} {translations?.defs.students}
            </span>
            <span>
              <i className="fa fa-clock" />
              {course.duration}m
            </span>
            <span>
              <i className="fa fa-calendar-alt" />
              {formattedDate}
            </span>
          </div>
        </div>
            </div>
          )
        })}

    </div>
    <div className="vs-pagination">
      <ul>
        <li className="prev">
          <a>{translations?.actions.prev}</a>
        </li>
        <li>
          <a>1</a>
        </li>
        <li>
          <a>2</a>
        </li>
        <li>
          <a>.....</a>
        </li>
        <li>
          <a>10</a>
        </li>
        <li className="next">
        <a>{translations?.actions.next}</a>
        </li>
      </ul>
    </div>
  </div>
</section>
    </Layout>
  );
}

export default Courses;
