import React, {useContext} from 'react';
import { IoHome } from "react-icons/io5";
import { RxSlash } from "react-icons/rx";
import { AppContext } from '../context/AppContext';
import { Link } from 'react-router-dom';

const PageHeader = ({ title, description }) => {

  const { translations } = useContext(AppContext);

  return (
<div
  className="breadcrumb-wrapper background-image"
  style={{ backgroundImage: 'url("assets/breadcrumb-bg.png")' }}
  >
  <div className="container z-index-common">
    <div className="breadcrumb-content">
      <h1 className="breadcrumb-title">{title}</h1>
      <p className="breadcrumb-text">
        {description}
      </p>
      <div className="breadcrumb-menu-wrap">
        <ul className="breadcrumb-menu align-items-center">
          <li>
            <Link to="/" className="text-decoration-none"><IoHome />{translations?.defs.home}</Link>
          </li>
          <li><RxSlash color="white" size="20" /></li>
          <li>{title}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

  );
}

export default PageHeader;
