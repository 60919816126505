import React, {useEffect, useState} from 'react';
import ReactDOMServer from "react-dom/server";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useMask} from "@react-input/mask";
import Swal from "sweetalert2";

const BookingParticipant = ({orderData}) => {
    const [participants, setParticipants] = useState([]);
    const [phones, setPhones] = useState([]);
    console.log("participants", participants)

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch(`https://app.meetods.com/api/v1/country/phones`);
                const data = await response.json();
                if (data.status) {
                    const formattedData = data.data.map((country) => ({
                        value: country.id,
                        label: country.name,
                    }));
                    setPhones(formattedData);
                } else {
                    console.error('API error:', data.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        fetchCountryCodes();
    }, []);

    const addParticipant = () => {

        let participant_name = document.querySelector("#participant_name").value;
        let participant_surname = document.querySelector("#participant_surname").value;
        let participant_email = document.querySelector("#participant_email").value;
        let participant_country = document.querySelector("#participant_country").value;
        let participant_phone = document.querySelector("#participant_phone").value;

        if (participant_name.trim() === ""
            || participant_surname.trim() === ""
            || participant_email.trim() === ""
            || participant_country.trim() === ""
            || participant_phone.trim() === ""
        ) {
            toast("Lütfen gerekli alanları doldurunuz", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
            return false;
        }

        // make email validation
        let emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(participant_email)) {
            toast("Lütfen geçerli bir e-posta adresi giriniz", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
            return false;
        }

        // look for email exists
        let exists = false;
        Object.values(participants).map((participant, index) => {
            if (participant.participant_email === participant_email) {
                exists = true;
            }
        })
        if (exists) {
            toast("Bu e-posta adresi zaten ekli", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
            return false;
        }

        participant_name = participant_name.trim();
        participant_surname = participant_surname.trim();
        participant_email = participant_email.trim();
        participant_phone = participant_phone.trim();
        let participant = {
            participant_name,
            participant_surname,
            participant_email,
            participant_country,
            participant_phone
        };

        participants.push(participant);
        setParticipants(participants);
        document.querySelector("#participant_edit_key").value = "";
        document.querySelector("#participant_name").value = "";
        document.querySelector("#participant_surname").value = "";
        document.querySelector("#participant_email").value = "";
        document.querySelector("#participant_country").value = "+90";
        document.querySelector("#participant_phone").value = "";

        document.querySelector("#participantOpener").classList.remove("d-none");
        document.querySelector("#participantCreator").classList.add("d-none");
        document.querySelector("#participantCollapse").classList.remove("show");

        renderParticipants();

        toast("Katılımcı başarıyla eklendi", {
            autoClose: 2000,
            type: toast.TYPE.SUCCESS,
        });
    }
    const saveParticipant = () => {
        let participant_name = document.querySelector("#participant_name").value;
        let participant_surname = document.querySelector("#participant_surname").value;
        let participant_email = document.querySelector("#participant_email").value;
        let participant_country = document.querySelector("#participant_country").value;
        let participant_phone = document.querySelector("#participant_phone").value;
        let key = document.querySelector("#participant_edit_key").value;

        if (participant_name.trim() === ""
            || participant_surname.trim() === ""
            || participant_email.trim() === ""
            || participant_country.trim() === ""
            || participant_phone.trim() === ""
        ) {
            toast("Lütfen gerekli alanları doldurunuz", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
            return false;
        }

        // make email validation
        let emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(participant_email)) {
            toast("Lütfen geçerli bir e-posta adresi giriniz", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
            return false;
        }

        // look for email exists
        let exists = false;
        Object.values(participants).map((participant, index) => {
            if (participant.participant_email === participant_email && key != index) {
                exists = true;
            }
        });
        if (exists) {
            toast("Bu e-posta adresi zaten ekli", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
            });
            return false;
        }
        let participant = {
            participant_name,
            participant_surname,
            participant_email,
            participant_country,
            participant_phone
        };

        participants[key] = participant;
        setParticipants(participants);
        document.querySelector("#participant_edit_key").value = "";
        document.querySelector("#participant_name").value = "";
        document.querySelector("#participant_surname").value = "";
        document.querySelector("#participant_email").value = "";
        document.querySelector("#participant_country").value = "+90";
        document.querySelector("#participant_phone").value = "";

        document.querySelector("#participantOpener").classList.remove("d-none");
        document.querySelector("#participantCreator").classList.add("d-none");
        document.querySelector("#participantSaver").classList.add("d-none");
        document.querySelector("#participantCollapse").classList.remove("show");

        renderParticipants();

        toast("Katılımcı başarıyla güncellendi", {
            autoClose: 2000,
            type: toast.TYPE.SUCCESS,
        });
    }
    const renderParticipants = () => {
        let participantList = document.querySelector("#participant-list");
        let participantList2 = document.querySelector(".dynamic-participants");
        participantList.innerHTML = "";
        participantList2.innerHTML = "";

        Object.values(participants).map((participant, index) => {

            let participantItem = document.createElement("tr");
            // participantItem.className = "col-12 d-flex justify-content-between mt-1";
            participantItem.innerHTML = ReactDOMServer.renderToString(
                <>
                    <td>
                        <a className="link-dark mb-0 pe-2">
                            {participant.participant_email}
                        </a>
                    </td>
                    <td>
                        <div className={"btn-group"}>
                            <a href="#" className="btn btn-danger btn-sm text-white btn-participant-remove"
                               data-key={index}>Sil</a>
                            <a href="#" className="btn btn-warning btn-sm text-white btn-participant-edit"
                               data-key={index}>Düzenle</a>
                        </div>
                    </td>
                </>
            );
            participantList.appendChild(participantItem);

            // let participantItem = document.createElement("div");
            // participantItem.className = "col-12 d-flex justify-content-between mt-1";
            // participantItem.innerHTML = ReactDOMServer.renderToString(
            //     <>
            //         <a className="link-dark mb-0 pe-2">
            //             {participant.participant_email}
            //         </a>
            //         <div className={"btn-group"}>
            //             <a href="#" className="btn btn-danger btn-sm text-white btn-participant-remove"
            //                data-key={index}>Sil</a>
            //             <a href="#" className="btn btn-warning btn-sm text-white btn-participant-edit"
            //                data-key={index}>Düzenle</a>
            //         </div>
            //     </>
            // );
            // participantList.appendChild(participantItem);

            // let participantItem = document.createElement("li");
            // participantItem.className = "list-group-item d-flex justify-content-between align-items-center";
            // participantItem.innerHTML = ReactDOMServer.renderToString(
            //     <a className="link-dark mb-0 pe-2">
            //         {participant.participant_email}
            //
            //         <a href="#" className="btn btn-danger btn-sm text-white me-2 btn-participant-remove"
            //            data-key={index}>Sil</a>
            //         <a href="#" className="btn btn-warning btn-sm text-white btn-participant-edit"
            //            data-key={index}>Düzenle</a>
            //     </a>
            // );
            // participantList.appendChild(participantItem);

            // .dynamic-participants
            let participantItem2 = document.createElement("div");
            participantItem2.className = "list-group-item d-flex justify-content-between align-items-center";
            participantItem2.innerHTML = ReactDOMServer.renderToString(
                <>
                    <input type={"hidden"} name={"participants[" + index + "][name]"}
                           value={participant.participant_name}/>
                    <input type={"hidden"} name={"participants[" + index + "][surname]"}
                           value={participant.participant_surname}/>
                    <input type={"hidden"} name={"participants[" + index + "][email]"}
                           value={participant.participant_email}/>
                    <input type={"hidden"} name={"participants[" + index + "][country]"}
                           value={participant.participant_country}/>
                    <input type={"hidden"} name={"participants[" + index + "][phone]"}
                           value={participant.participant_phone}/>
                </>
            );
            participantList2.appendChild(participantItem2);
        });
    };

    useEffect(() => {
        if (orderData.participants) {
            // alert("buraya girdi?")
            Object.values(orderData.participants).map((participant_fresh, index) => {
                let exists = false;
                Object.values(participants).map((participant, index) => {
                    if (participant.participant_email === participant_fresh.email) {
                        exists = true;
                    }
                });
                if (exists) {
                    return false;
                }
                participants.push({
                    participant_name: participant_fresh.name,
                    participant_surname: participant_fresh.surname,
                    participant_email: participant_fresh.email,
                    participant_country: participant_fresh.country,
                    participant_phone: participant_fresh.phone,
                });
            });
            setParticipants(participants);
            // renderParticipants();
        } else {
            // alert("yok yok bura")
            let first_name = localStorage.getItem('firstName');
            let last_name = localStorage.getItem('lastName');
            let email = localStorage.getItem('email');
            let country_code = localStorage.getItem('country');
            let phone_number = localStorage.getItem('phone_number');

            if ((first_name !== undefined && first_name !== null && first_name !== '') && (last_name !== undefined && last_name !== null && last_name !== '') && (email !== undefined && email !== null && email !== '') && (phone_number !== undefined && phone_number !== null && phone_number !== '') && (country_code !== undefined && country_code !== null && country_code !== '')) {
                let array = [];
                let participant = {
                    participant_name: first_name,
                    participant_surname: last_name,
                    participant_email: email,
                    participant_country: country_code,
                    participant_phone: phone_number,
                };
                array.push(participant);
                setParticipants(array);
            }
        }
    }, [orderData.participants, setParticipants]);


    useEffect(() => {
        renderParticipants();


        const handleRemoveClick = (event) => {
            if (event.target.matches(".btn-participant-remove")) {
                Swal.fire({
                    icon: 'info',
                    title: 'Emin misiniz?',
                    text: "Bu katılımcıyı silmek istediğinize emin misiniz ?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Sil`,
                    denyButtonText: `Vazgeç`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let key = event.target.dataset.key;
                        let newParticipants = [...participants];
                        newParticipants.splice(key, 1);
                        setParticipants(newParticipants);
                        renderParticipants();
                        toast("Katılımcı başarıyla silindi", {
                            autoClose: 2000,
                            type: toast.TYPE.SUCCESS,
                        });
                    }
                })
            }
        };

          document.addEventListener("click", handleRemoveClick);


          const handleEditClick = (event) => {
            if (event.target.matches(".btn-participant-edit")) {
                let key = event.target.dataset.key;
                let participant = Object.values(participants)[key];
                if (participant !== undefined) {
                    document.querySelector("#participant_name").value = participant.participant_name;
                    document.querySelector("#participant_surname").value = participant.participant_surname;
                    document.querySelector("#participant_email").value = participant.participant_email;
                    document.querySelector("#participant_country").value = participant.participant_country;
                    document.querySelector("#participant_phone").value = participant.participant_phone;
                    document.querySelector("#participant_edit_key").value = key;
    
                    document.querySelector("#participantOpener").classList.add("d-none");
                    document.querySelector("#participantCreator").classList.add("d-none");
                    document.querySelector("#participantSaver").classList.remove("d-none");
                    document.querySelector("#participantCollapse").classList.add("show");
                }
            }
        };
    
        document.addEventListener("click", handleEditClick);

        return () => {
            document.removeEventListener("click", handleRemoveClick);
            document.removeEventListener("click", handleEditClick);
        };
    }, [participants, renderParticipants]);


    const inputRef = useMask({ mask: '(___) ___-__-__', replacement: { _: /\d/ } });
    const select_style = {
        maxWidth: "5rem",
        backgroundPosition: "calc(100% - 5px) center",
    }

    return (
        <>
            <div className="col-12">
                <div className="collapse" id="participantCollapse">
                    <div className="card card-body border-0 p-0">
                        <div className="row">
                            <input type="hidden" id="participant_edit_key"/>
                            <div className="col-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="participant_name" className={"m-0 p-0"}>Ad <span
                                        className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="participant_name" placeholder="Ad"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="participant_surname" className={"m-0 p-0"}>Soyad <span
                                        className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="participant_surname"
                                           placeholder="Soyad"/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mb-3">
                                    <label htmlFor="participant_email" className={"m-0 p-0"}>E-Posta <span
                                        className="text-danger">*</span></label>
                                    <input type="email" className="form-control" id="participant_email"
                                           placeholder="E-Posta"/>
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="participant_phone" className={"m-0 p-0"}>Telefon <span
                                    className="text-danger">*</span></label>
                                <div className="input-group mb-3">

                                    <select className="form-control" id="participant_country" style={select_style}>
                                        {phones.map((phone) => (
                                            <option key={phone.value} value={phone.value}
                                                    selected={phone.value === "+90"}>{phone.value}</option>
                                        ))}
                                    </select>
                                    <input type="text" className="form-control" id="participant_phone"
                                           placeholder="Telefon" ref={inputRef}/>
                                </div>
                            </div>
                            {/*<div className="col-12">*/}
                            {/*    <div className="form-group mb-3">*/}
                            {/*        <label htmlFor="participant_phone" className={"m-0 p-0"}>Telefon <span*/}
                            {/*            className="text-danger">*</span></label>*/}
                            {/*        <input type="text" className="form-control" id="participant_phone"*/}
                            {/*               placeholder="Telefon" ref={inputRef}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <button type={"button"} className={"btn btn-light fw-bold mb-4"}
                        id={"participantOpener"}
                        data-bs-toggle="collapse"
                        data-bs-target="#participantCollapse" aria-expanded="false"
                        aria-controls="participantCollapse"
                        onClick={() => {
                            document.querySelector("#participantOpener").classList.add("d-none");
                            document.querySelector("#participantCreator").classList.remove("d-none");
                        }}
                >+ Katılımcı Ekle
                </button>
                <button type={"button"} className={"btn btn-light fw-bold mb-4 d-none"}
                        id={"participantCreator"}
                        onClick={addParticipant}>Oluştur
                </button>
                <button type={"button"} className={"btn btn-light fw-bold mb-4 d-none"}
                        id={"participantSaver"}
                        onClick={saveParticipant}>Güncelle
                </button>
            </div>
            <div className="col-12 dynamic-participants">

            </div>
        </>

    );
};

export default BookingParticipant;
