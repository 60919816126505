import React, { useState, useEffect, useContext } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from '../context/AppContext';
import Tutors from '../components/sections/Tutors';
import { FaStar } from "react-icons/fa";
import { FaCheck } from 'react-icons/fa';
import PageHeader from '../components/PageHeader';
import Layout from '../components/Layout';
import PreLoader from '../components/PreLoader';
import { Link } from 'react-router-dom';

const AboutUs = ({  }) => {

  const [testimonials, setTestimonials] = useState([]);
  const { translations } = useContext(AppContext);
  const [loading, setLoading] = useState(true); 


  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setter(data);
        setLoading(false); 
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData(
      "https://webapi.meetods.com/api/v1/testimonials",
      setTestimonials
    );
  }, []);

  if (loading) {
    return     <PreLoader />
  }


  return (
    <Layout>
<>
<PageHeader title={translations?.defs.aboutUs} description={translations?.defs.about_desc} />
  <section className="space-top">
    <div className="container">
      <div className="row">
        <div className="col-xl-9">
          <div className="title-area mb-3 mb-xl-5">
            <span className="sec-subtitle">{translations?.sections?.aboutTitle}</span>
            <h2 className="sec-title">
            {translations?.sections?.aboutSubtitle}
            </h2>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 mb-30 mb-xl-0">
          <p className="fs-md mt-n1">
          {translations?.sections.aboutTestiText}
          </p>
          <div className="media-style1">
            <div className="media-img">
              <img src={translations?.sections.aboutTestiImage} alt="About Author" />
            </div>
            <div className="media-body">
              <span className="media-label">{translations?.sections.aboutTestiName}</span>
              <p className="media-info">{translations?.sections.aboutTestiRole}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="list-style1 vs-list ">
            <ul>
            <li className="ps-0"><FaCheck className="text-primary me-2" />{translations?.sections?.aboutLine1}</li>
            <li className="ps-0"><FaCheck className="text-primary me-2" />{translations?.sections?.aboutLine2}</li>
            <li className="ps-0"><FaCheck className="text-primary me-2" />{translations?.sections?.aboutLine3}</li>
            <li className="ps-0"><FaCheck className="text-primary me-2" />{translations?.sections?.aboutLine4}</li>
            </ul>
          </div>
        </div>
        <div className="col-xl-4 mt-n5 pt-5 pt-xl-0">
          <div className="img-box3">
            <div className="img-1 mega-hover">
              <img
                className="w-100"
                src={translations?.defs.about_img}
                alt="About Img"
              />
            </div>
            <div className="shape-dotted jump" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
  Features Area
  ==============================*/}
  <section className=" space-top space-extra-bottom">
    <div className="container">
      <div
        className="row vs-carousel"
        data-slide-show={4}
        data-md-slide-show={3}
        data-sm-slide-show={2}
        data-xs-slide-show={2}
      >
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature1Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature1Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature1Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature2Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature2Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature2Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature3Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature3Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature3Text}
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="media-style8">
            <div className="media-icon">
              <img src={translations?.sections.aboutFeature4Icon} alt="" />
            </div>
            <h5 className="media-title">{translations?.sections.aboutFeature4Title}</h5>
            <p className="media-text">
            {translations?.sections.aboutFeature4Text}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Tutors />
  <>
  <section style={{ backgroundImage: "url('assets/divider-bg-1-1.jpg')" }}>
    <div className="container">
      <div className="row align-items-center text-center text-lg-start">
        <div className="col-lg-5 col-xl-6 space-extra">
          <h2 className="sec-title text-white mb-3">{translations?.sections.aboutCtaTitle}</h2>
          <p className="fs-md text-white">
          {translations?.sections.aboutCtaSubtitle}
          </p>
          <div className="row gx-60 mb-4 pb-xl-3 text-start justify-content-center justify-content-lg-start">
            <div className="col-auto col-lg-12 col-xl-auto">
              <div className="list-style4 vs-list ">
                <ul className="list-unstyled m-0">
                  <li>{translations?.sections.aboutCtaFeature1}</li>
                  <li>{translations?.sections.aboutCtaFeature2}</li>
                </ul>
              </div>
            </div>
            <div className="col-auto col-lg-12 col-xl-auto">
              <div className="list-style4 vs-list ">
                <ul className="list-unstyled m-0">
                  <li>{translations?.sections.aboutCtaFeature3}</li>
                  <li>{translations?.sections.aboutCtaFeature4}</li>
                </ul>
              </div>
            </div>
          </div>
          <Link to="/" className="vs-btn style5">
            <i className="fa fa-angle-right" />
            {translations?.sections.aboutCtaButtonText}
          </Link>
        </div>
        <div className="col-lg-7 col-xl-6 align-self-end">
          <div className="img-box2">
            <div className="vs-circle" />
            <img
              className="img-1"
              src={translations?.defs.cta_bg}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
  Features Area
  ==============================*/}
  <section className="space-top">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-7 text-center text-xl-start">
          <div className="title-area">
            <span className="sec-subtitle">
            {translations?.sections.trainingTitle}
            </span>
            <h2 className="sec-title h1">{translations?.sections.trainingSubtitle}</h2>
          </div>
          <div className="row gx-80 gy-xl-4 mb-4 mb-xl-0">
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox1Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox1Title}</h5>
                <p>
                {translations?.sections.trainingBox1Text}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox2Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox2Title}</h5>
                <p>
                {translations?.sections.trainingBox2Text}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox3Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox3Title}</h5>
                <p>
                {translations?.sections.trainingBox3Text}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-6 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="media-style4">
                <div className="media-icon">
                  <img src={translations?.sections.trainingBox4Icon} alt="" />
                </div>
                <h5 className="media-title">{translations?.sections.trainingBox4Title}</h5>
                <p>
                {translations?.sections.trainingBox4Text}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInUp" data-wow-delay="0.4s">
          <div className="position-relative">
            <form action="#" className="form-style2">
              <div className="form-inner">
                <h3 className="form-title h5">
                {translations?.sections.trainingFormTitle}
                </h3>
                <div className="row">
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="w-100"
                        name="name"
                        id="name"
                        placeholder={translations?.sections.trainingFormName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="w-100"
                        name="email"
                        id="email"
                        placeholder={translations?.sections.trainingFormMail}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-12">
                  <div className="form-group">
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder={translations?.defs.subject}
                className="w-100"
              />
            </div>
                   {/* <div className="form-group">
                      <select name="coursenames" id="coursenames">
                        <option selected="" disabled="" hidden="">
                        {translations?.sections.trainingFormSelect}
                        </option>
                        <option>Development</option>
                        <option>Ui Development</option>
                        <option>CMS Learning</option>
                        <option>Jamstack Master</option>
                      </select>
                    </div> */}

                  </div>
                  <div className="col-md-6 col-xl-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="w-100"
                        name="email"
                        id="phone"
                        placeholder={translations?.sections.trainingFormPhone}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="button" className="vs-btn">
                    {translations?.actions.applyToday}
                    </button>
                    <Link className="form-link" to="/about-us">
                    {translations?.sections.faq}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="vs-circle color2" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="overflow-hidden bgc-f6 space-top space-extra-bottom shape-mockup-wrap">
  <div class="shape-mockup jump d-none d-xxl-block" style={{left: '-14%'}}><div class="vs-border-circle"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{top: '170px', right: '-15%'}}><div class="vs-circle color2"></div></div>
  <div class="shape-mockup jump-img d-none d-xxl-block" style={{bottom: '40%', left: '8%'}}><div class="shape-dotted style2"></div></div>
    <div className="container">
        <div className="row">
            <div className="col">
            <div className="d-flex flex-column align-items-center">
                <h5 className="sec-subtitle">{translations?.sections.testiTitle}</h5>
                <h1 className="display-4 fw-semibold text-center mb-3 mb-md-2 mb-lg-5">
                {translations?.sections.testiSubtitle}
                </h1>
          </div>
            </div>
        </div>


        <div className="testi1-slider-wrapper row my-5">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={true}
              slidesToShow={3}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                  },
                },
              ]}

        >
            {testimonials.map((testi, index) => (


<div className="testi-style1" key={index}>
  <div className="testi-content">
    <p className="testi-text">{testi.review}</p>
  </div>
  <div className="testi-client">
    <img src={testi.user_avatar} alt="author" />
    <h3 className="testi-name h5">{testi.name}</h3>
    <span className="testi-degi">{testi.profession}</span>
    <div className="testi-rating">
    {[...Array(5)].map((_, index) => (
    <FaStar className="text-warning" key={index} />
  ))}
    </div>
  </div>
</div>


            ))}
        </Slider>
        </div>

        
    </div>
  </section>
</>

</>

    </Layout>
  );
}

export default AboutUs;
