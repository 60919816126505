import React from 'react';

const Terms = () => {
  const termsData = [
    {
      id: 1,
      title: "Koşul 1",
      content: "Bu, ilk koşulun içeriğidir...",
    },
    {
      id: 2,
      title: "Koşul 2",
      content: "Bu, ikinci koşulun içeriğidir...",
    },
    {
      id: 3,
      title: "Koşul 3",
      content: "Bu, üçüncü koşulun içeriğidir...",
    },
  ];

  return (
    <div>
      <h2>Terms and Conditions</h2>
      {termsData.map((term) => (
        <div key={term.id}>
          <h4>{term.title}</h4>
          <p>{term.content}</p>
        </div>
      ))}
    </div>
  );
}

export default Terms;
