import { useEffect, useState } from "react";
import "./styles/bootstrap.css";
import "./styles/main.css";
import './styles/theme.css';
import "react-calendar/dist/Calendar.css";

import { BrowserRouter, BrowserRouter as Router, Routes, Route, Switch, Navigate} from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import Confirmation from "./pages/Confirmation";
import Terms from "../src/pages/Terms";
import DataPolicy from "../src/pages/DataPolicy"; 
import Tutors from "./pages/Tutors";
import Home from "./pages/Home";
import BecomeTutor from "./pages/BecomeTutor";
import FindTutor from "./pages/FindTutor";
import TutorDetail from "./pages/dynamic-pages/TutorDetail";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import FindProgram from "./pages/FindProgram";
import Courses from "./pages/Courses";
import CourseDetail from "./pages/dynamic-pages/CourseDetail";
import HowItWorks from "./pages/HowItWorks";
import ProgramDetail from "./pages/dynamic-pages/ProgramDetail";


import PreLoader from "./components/PreLoader";


import { AppContext } from "./context/AppContext";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/dynamic-pages/BlogDetail";
import Authentication from "./pages/Authentication";
import BookingForm from './pages/booking/Booking';
import AuthenticationWithOrder from "./pages/AuthenticationWithOrder";
import BranchDetail from "./pages/dynamic-pages/BranchDetail";
import Pricing from "./pages/Pricing";
import Features from "./pages/Features";

function App() {


  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState(null);


  useEffect(() => {
    const fetchLanguageData = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchLanguageData("https://webapi.meetods.com/api/v1/language");
  }, []);


  const [qualifiedTutors, setQualifiedTutors] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [branches, setBranches] = useState([]);
  const [features, setFeatures] = useState([]);
  const [factors, setFactors] = useState([]);



  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData("https://webapi.meetods.com/api/v1/qualified-tutors", setQualifiedTutors);
    fetchData("https://webapi.meetods.com/api/v1/blogs", setBlogs);
    fetchData("https://webapi.meetods.com/api/v1/popular-courses", setCourses)
    fetchData("https://webapi.meetods.com/api/v1/programs", setPrograms);
    fetchData("https://webapi.meetods.com/api/v1/branches", setBranches);
    fetchData("https://webapi.meetods.com/api/v1/features", setFeatures);
    fetchData("https://webapi.meetods.com/api/v1/factors", setFactors);

  }, []);


  if(loading) {
    return (
      <PreLoader />
    )
  }

  return (
    <div className="app">
      <AppContext.Provider 
        value={{ 
         blogs, factors, features, branches, translations, setTranslations }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home features={features} factors={factors} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/home" element={<Home features={features} factors={factors} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/authentication/:hash" element={<Authentication />} />
            <Route path="/authentication/:hash/:order" element={<AuthenticationWithOrder />} />
            <Route path="/confirmation/:bookingId" element={<Confirmation />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/data-policy" element={<DataPolicy />} />
            <Route path="/tutors" element={<Tutors />} />
            <Route path="/tutors/:id" element={<TutorDetail tutors={qualifiedTutors} />} />
            <Route path="/become-tutor" element={<BecomeTutor />} />
            <Route path="/find-tutor" element={<FindTutor />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/find-program" element={<FindProgram programs={programs} />} />
            <Route path="/courses" element={<Courses courses={courses} />} />
            <Route path="/courses/:id" element={<CourseDetail courses={courses} />} />
            <Route path="/how-it-works" element={<HowItWorks branches={branches} factors={factors} />} />
            {/* <Route path="/academic-programs/:id" element={<ProgramDetail programs={programs} />} /> */}
            <Route path="/blog" element={<Blog blogs={blogs} />} />
            <Route path="/blog/:id" element={<BlogDetail blogs={blogs} />} />
            <Route path="/branch/:id" element={<BranchDetail branches={branches} />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/features" element={<Features />} />
            <Route path="/booking" element={<BookingForm />} />

          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;
