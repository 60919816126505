import React, {useEffect, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useMask } from '@react-input/mask';
const dynamic_loader = event => {
    let load = event.target.dataset.load;
    let load_url = event.target.dataset.loadUrl;
    let load_id = event.target.value;
    if (load && load_url && load_id) {
        fetch(`${load_url}${load_id}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    let options = data.data.map((option, index) => {
                        return ReactDOMServer.renderToString(<option value={option.id}>{option.name}</option>);
                    });
                    options.unshift(`<option value="">Lütfen Seçin</option>`);
                    document.querySelector(`select[name="${load}"]`).innerHTML = options.join("");
                }
            });
    }
}
const RegisterStep = ({field, name, index}) => {

    let first_name = localStorage.getItem('firstName');
    let last_name = localStorage.getItem('lastName');
    let email = localStorage.getItem('email');

    switch (field.type) {
        case "text":
        case "number":
        case "email":
        case "password":
            return (
                <div className="form-group">
                    <label htmlFor={name}>{field.name} <span
                        className={field.required ? 'text-danger' : 'd-none'}>*</span> </label>
                    <input type={field.type}
                           className={"form-control " + ((name === 'first_name' && first_name !== undefined) || (name === 'last_name' && last_name !== undefined) || (name === 'email' && email !== undefined) ? 'bg-secondary' : '')}
                           id={name} name={name}
                           defaultValue={name === 'first_name' ? first_name : name === 'last_name' ? last_name : name === 'email' ? email : ''}
                           placeholder={field.placeholder} required={field.required}
                           readOnly={(name === 'first_name' && first_name !== undefined) || (name === 'last_name' && last_name !== undefined) || (name === 'email' && email !== undefined)}
                    />
                </div>
            );
        case "select":
            return (
                <div className="form-group">
                    <label htmlFor={name}>{field.name} <span
                        className={field.required ? 'text-danger' : 'd-none'}>*</span> </label>
                    <select className="form-control" id={name} name={name}
                            onChange={field.load && field.load_url ? dynamic_loader : null}
                            data-load={field.load} data-load-url={field.load_url}
                            required={field.required}
                    >
                        <option value={""}>{field.placeholder}</option>
                        {field.values.map((option, index) => {
                            return <option value={option.id} key={option.id}>{option.name}</option>
                        })}
                    </select>
                </div>
            );
        case "textarea":
            return (
                <div className="form-group">
                    <label htmlFor={name}>{field.name} <span
                        className={field.required ? 'text-danger' : 'd-none'}>*</span> </label>
                    <textarea className="form-control" id={name} name={name} rows={6}
                              placeholder={field.placeholder} required={field.required}/>
                </div>
            );
        default:
            return (
                <h1>DEFAULT</h1>
            );
    }

};

export default RegisterStep;
