import React, { useState, useEffect, useRef} from "react";
import Layout from "../components/Layout";
import Welcome from "../components/sections/Welcome";
import Hero from "../components/sections/Hero";
import Categories from "../components/sections/Categories";
import Tutors from "../components/sections/Tutors";
import Services from "../components/sections/Services";
import PopularCourses from "../components/sections/PopularCourses";
import HowItWorks from "../components/sections/HowItWorks";
import Packages from "../components/sections/Packages";
import Blog from "../components/sections/Blog";
import Partners from "../components/sections/Partners";
import CallToAction from "../components/sections/CallToAction";
import Testimonials from "../components/sections/Testimonials";
import { AppContext } from "../context/AppContext";
import PreLoader from "../components/PreLoader";
import { useSpring, animated, useScroll } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';

import { Screen, EventSchedule, GroupPresentation, Money, Api  } from '@carbon/icons-react';
import { Link } from 'react-router-dom';



const Home = ({ features, factors }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
   }, []);

  const [ref1, inView] = useInView({
    triggerOnce: true,
  })
  const [ref2, inView2] = useInView({
    triggerOnce: true,
  })
  const [ref3, inView3] = useInView({
    triggerOnce: true,
  })
  const [ref4, inView4] = useInView({
    triggerOnce: true,
  })


  const titleAnimation1 = useSpring({
    from: { backgroundPosition: "left" },
    to: { backgroundPosition: inView ? "left" : "right" }
  })
  const titleAnimation2 = useSpring({
    from: { backgroundPosition: "left" },
    to: { backgroundPosition: inView2 ? "left" : "right" }
  })
  const titleAnimation3 = useSpring({
    from: { backgroundPosition: "left" },
    to: { backgroundPosition: inView3 ? "left" : "right" }
  })

  const titleAnimation4 = useSpring({
    from: { backgroundPosition: "left" },
    to: { backgroundPosition: inView4 ? "left" : "right" }
  })


  const renderIcon = (icon_name) => {
    if (icon_name === "screen") {
      return <Screen size="62" color="#3747FF" /> 
    } else if (icon_name === "event-schedule") {
      return <EventSchedule size="62" color="#3747FF" /> 
    } else if (icon_name === "group-presentation") {
      return <GroupPresentation size="62" color="#3747FF" /> 
    } else if (icon_name === "moneyap") {
      return <Money size="62" color="#3747FF" /> 
    } else if (icon_name === "api") {
      return <Api size="62" color="#3747FF" /> 
    }
  }
  

  return (
    <>
      <Layout home={true}>
      <Hero  />
      <div className="feature-sections-wrapper">
      {features?.map((feature, index) => {
const getLastTwoWords = (sentence) => {
  const words = sentence.split(' ');
  const lastTwoWords = words.slice(-2).join(' ');
  return lastTwoWords;
};

const trimLastTwoWords = (sentence) => {
  const words = sentence?.split(' ');
  if (words.length <= 1) {
    return sentence;
  }
  const lastTwoWordsIndex = words.length - 2;
  const trimmedSentence = words.slice(0, lastTwoWordsIndex).join(' ');

  return trimmedSentence;
};
        
        return(
  <section className="feature-section space-top" key={index}>
    <div className="container">
    <div className={`row ${index % 2 === 0 ? 'reverse-order' : ''}`}>
        <div className="col-lg-6">
          <img src={feature.media} alt={feature.title} className="h-100 object-fit-contain" />
        </div>
        <div className="col-lg-6">
          <h5 className="sec-subtitle mt-3 mt-lg-0">{feature.title}</h5>

          <h3 className="decorative-title display-4 fw-semibold mb-3 mb-md-2 mb-lg-5"
    ref={
      feature.id === 1
        ? ref1
        : feature.id === 2
          ? ref2
          : feature.id === 3
            ? ref3
            : feature.id === 4
              ? ref4 
              : {}
    }
>
          {trimLastTwoWords(feature.subtitle)}
          <animated.span 
    style={
      feature.id === 1
        ? titleAnimation1
        : feature.id === 2
          ? titleAnimation2
          : feature.id === 3
            ? titleAnimation3
            : feature.id === 4
              ? titleAnimation4
              : {} 
    }
                          className="ms-1 ps-2 word-bg">
            {getLastTwoWords(feature.subtitle)}
          </animated.span> 
        </h3>

          <p className="font-md">{feature.description}</p>
         {/* <a className="vs-btn style3" href={feature.featureLink}><i className="fa fa-angle-right"></i>{feature.featureActionText}</a> */}
          <Link to="/features" className='feature-btn'>
            <span className="feature-btn-icon">
            <i className="fa fa-angle-right"></i>
            </span>
            <span className="feature-btn-text">
            {feature.action_text}
            </span>
          </Link>
        </div>
      </div>
    </div>
  </section>
)})}

      </div>
      <Categories />
    
      <section className="space-top mt-5">
        <div className="container">
          <div className="app-features flex-column flex-lg-row">
            {factors?.map((factor, index) => (
            <div className="app-feature-item d-flex flex-column align-items-center justify-content-center" key={index}>
            {/* todo: create a reusable icon component */}
            {renderIcon(factor.icon_name)}
            <h5 className="app-feature-title fw-normal mt-4">{factor.title}</h5>
          </div>
            ))}
          </div>
        </div>
  </section> 

      <Testimonials />
      <Welcome /> 
      <Tutors />
     {/* <Services /> */}
      {/* <PopularCourses /> */}
      <HowItWorks /> 
     {/* <Packages /> */}
     {/* <Blog /> */}
     <Partners  /> 
      <CallToAction />
    </Layout>
    </>
  );
};

export default Home;
