import React, {useContext} from 'react';
import { FaCheck } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate} from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const Welcome = () => {
  const { selectedLanguage } = useContext(AppContext);
  const { translations } = useContext(AppContext);

const navigate = useNavigate();


 const handleSignUp = () => {
    navigate('/register')
 }

  return (
    <section className="space-top space-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex flex-column flex-lg-row flex-md-row w-100 justify-content-end position-relative">
            <div className="mega-hover mb-3 mb-lg-0 mb-md-0">
              <img
                src={translations?.sections.welcomeImage}
                className="rounded-4"
                width={460}
                height={560}
              />
              <div className="shape-dotted jump"></div>
            </div>
            <div className="media-boxes-container d-flex d-lg-block justify-content-between mb-4 mb-lg-0">
            <div className="media-box box-1">
              <h3 className="media-title fw-bold">{translations?.sections.welcomeBox1Title}</h3>
              <p className="media-desc">
              {translations?.sections.welcomeBox1Desc}
              </p>
            </div>
            <div className="media-box active box-2">
              <h3 className="media-title fw-bold">{translations?.sections.welcomeBox2Title}</h3>
              <p className="media-desc">
              {translations?.sections.welcomeBox2Desc}
              </p>
            </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column justify-content-center align-items-start h-100 ps-0 ps-lg-3">
            <h4 className="sec-subtitle">{translations?.sections.welcomeTitle}</h4>
            <h1 className="fw-bold display-5">
            {translations?.sections.welcomeSubtitle}
            </h1>
            <div className="list-style1 vs-list mb-2 mb-lg-4 pb-1 pt-3">
              <ul className="welcome-list list-unstyled">
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                    {translations?.sections.welcomeList1}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.welcomeList2}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.welcomeList3}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.welcomeList4}
                  </span>
                </li>
                <li className="ps-0">
                  <FaCheck className="text-primary" />
                  <span className="text-muted ms-2">
                  {translations?.sections.welcomeList5}
                  </span>
                </li>
              </ul>
            </div>
            <div className="d-flex">
            <a href="https://app.meetods.com/register" className="vs-btn style3">
                  <i className="fa fa-angle-right"></i>
                {translations?.actions.signTeach}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Welcome;
