import React, { useState, useEffect, useContext, useRef} from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from '../context/AppContext';
import { FaPlus, FaMinus } from "react-icons/fa";
import PageHeader from '../components/PageHeader';
import Layout from '../components/Layout';
import PreLoader from '../components/PreLoader';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useMask} from "@react-input/mask";
import InputMask from "react-input-mask";


const ContactUs = ({  }) => {

  const { translations } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const [academicFaqs, setAcademicFaqs] = useState([]);

  useEffect(() => {
    const fetchData = async (url, setter) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData(
        "https://webapi.meetods.com/api/v1/academic-faqs",
        setAcademicFaqs
    );
}, []);

  const [activeFaqIndex, setActiveFaqIndex] = useState(0)

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [contactPreference, setContactPreference] = useState('');

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    const newPhoneValue = e.target.value;
    const numericPhoneValue = newPhoneValue.replace(/\D/g, '');
    setPhone(numericPhoneValue);
};
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleRadioChange = (value) => {
    setContactPreference(value)
  }


  const handleApply = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://webapi.meetods.com/api/v1/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fullName,
          email,
          phone,
          subject,
          message,
          contactPreference,
        }),
      });

      if (response.ok) {
        console.log('Contact form submission successful');
        toast(translations?.defs.contactSuccess, {
          autoClose: 2000,
          type: toast.TYPE.SUCCESS,
      });
      } else {
        console.error('Contact form submission failed');
        toast(translations?.defs.contactFail, {
          autoClose: 2000,
          type: toast.TYPE.ERROR,
      });
      }
    } catch (error) {
      console.error('Error submitting contact form', error);
      toast(translations?.defs.contactFail, {
        autoClose: 2000,
        type: toast.TYPE.ERROR,
    });
    }
  };

  const inputRef = useMask({ mask: '(___) ___-__-__', replacement: { _: /\d/ } });



  if(loading) {
    return (
      <PreLoader />
    )
  }



  return (
    <Layout>
        <>
        <ToastContainer />
            <PageHeader title={translations?.defs.contact}  description={translations?.defs.contact_desc} />
            <>
  <section className=" space-top space-extra-bottom">
    <div className="container">
      <div className="row gx-80">
        <div className="col-lg-6 col-xl-6 mb-30 mb-lg-0">
          <h2 className="h1 mt-n2">{translations?.sections.contactTitle}</h2>
          <p className="fs-md mb-4 pb-2">
          {translations?.sections.contactSubtitle}
          </p>
          <h3 className="border-title2 h5">{translations?.defs.regional_office}</h3>
          <p className="contact-info">
            <i className="fas fa-clock" />
            {translations?.defs.office_hrs_text}
          </p>
          <p className="contact-info">
            <i className="fas fa-map-marker-alt" />
            {translations?.defs.address}
          </p>
          <p className="contact-info">
            <i className="fas fa-phone-alt" />
            <a className="text-inherit" href="tel:+11234562228">
            {translations?.defs.tel}
            </a>
          </p>
          <p className="contact-info">
            <i className="fas fa-envelope" />
            <a className="text-inherit" href={`mailto:${translations?.defs.mail}`}>
            {translations?.defs.mail}
            </a>
          </p>
          <div className="mega-hover rounded-20 mt-4 mt-lg-5 mb-4">
            <img
              src={translations?.defs.office_img}
              alt="office"
              className="w-100"
            />
          </div>
          <p className="font-title text-title fs-md fw-medium pt-xl-2 mb-2">
            {translations?.defs.enrollLabel}{" "}
            <a href={`tel:${translations?.defs.membershipTel}`} className="text-decoration-underline">
              {translations?.defs.membershipTel}
            </a>
          </p>
          <p className="font-title text-title fs-md fw-medium mb-4">
          {translations?.defs.supportLabel}{" "}
            <a href={`tel:${translations?.defs.supportTel}`} className="text-decoration-underline">
            {translations?.defs.supportTel}
            </a>
          </p>
        </div>
        <div className="col-lg-6 col-xl-6">
          <form className="form-style5 ajax-contact contact-form" onSubmit={handleApply}>
            <div className="vs-circle" />
            <h3 className="form-title">{translations?.sections.enquireFormTitle}</h3>
            <p className="form-text">
            {translations?.sections.enquireFormSubtitle}
            </p>
            <div className="form-group">
              <input
                type="text"
                name="name"
                id="name"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder={translations?.defs.fullName}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder={translations?.defs.enterEmail}
                required
              />
            </div>
            <div className="form-group">
              <InputMask
                mask="(999) 999-99-99"
                type="text"
                name="phone"
                id="phone"
                value={phone}
                onChange={handlePhoneChange}
                placeholder={translations?.sections.trainingFormPhone}
                className="w-100"
               // ref={inputRef}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="subject"
                id="subject"
                value={subject}
                onChange={handleSubjectChange}
                placeholder={translations?.defs.subject}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                value={message}
                onChange={handleMessageChange}
                placeholder={translations?.defs.writeMsg}
                defaultValue={""}
                required
              />
            </div>
            <p className="form-text2">{translations?.sections.enquireFormQuestion}</p>
            <div className="row">
              <div className="col-auto form-group">
                <input
                  type="radio"
                  name="callchoice"
                  id="1"
                  defaultValue="1"
                  onChange={(e) => handleRadioChange(e.target.value)}
                />
                <label htmlFor="1">{translations?.sections.enquireFormOption1}</label>
              </div>
              <div className="col-auto form-group">
                <input
                  type="radio"
                  name="callchoice"
                  id="2"
                  defaultValue="2"
                  onChange={(e) => handleRadioChange(e.target.value)}
                />
                <label htmlFor="2">{translations?.sections.enquireFormOption2}</label>
              </div>
            </div>
            <button className="vs-btn" type="submit">
              {translations?.actions.applyToday}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
  <div className="overflow-hidden rounded-20 space-bottom">
    <div className="container">
    {/*  <iframe
        className="bdrs20"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d50437.32487690385!2d144.96230200000002!3d-37.805673!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sbd!4v1677062621439!5m2!1sen!2sbd"
        width={600}
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
  /> */}
    </div>
  </div>
  {/* FAQ Area */}
  <section className="space-extra-bottom">
    <div className="container">
      <div
        className="title-area wow fadeInUp text-center"
        data-wow-delay="0.1s"
      >
        <span className="sec-subtitle">{translations?.sections.academicFaqsTitle}</span>
        <h2 className="sec-title h1 mx-auto">{translations?.sections.academicFaqsSubtitle}</h2>
      </div>
      <div className="accordion-style1">
        <div className="accordion" id="faqVersion1">
        {academicFaqs.map((faq, index) => (
              <div className={activeFaqIndex === index ? "accordion-item active" : "accordion-item"} key={index}   
              >
              <div className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  onClick={() => {
                    if (activeFaqIndex === index) {
                      setActiveFaqIndex(null);
                    } else {
                      setActiveFaqIndex(index);
                    }
                  }}

                >
                  <span className={activeFaqIndex === index ? "accordion-icon text-white" : "accordion-icon text-dark"} >
                  {activeFaqIndex === index ? (
                    <FaMinus size="16" />
                  ) :( 
                  <FaPlus size="16" />)}
                  </span>
                  {faq.question}
                </button>
              </div>
              <div
                className= {activeFaqIndex === index ? "accordion-collapse collapsed" : "accordion-collapse collapse"} 
              >
                <div className="accordion-body">
                  <p>{faq.answer}</p>
                </div>
              </div>
            </div>
              ))}
        </div>
      </div>
    </div>
  </section>
</>

        </>
    </Layout>
  );
}

export default ContactUs;
