import React, { useState, useEffect, useContext } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from '../../context/AppContext';

const Partners = ({  }) => {

  const [partners, setPartners] = useState([]);
  const { translations } = useContext(AppContext);


  useEffect(() => {
      const fetchData = async (url, setter) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setter(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData(
        "https://webapi.meetods.com/api/v1/popular-partners",
        setPartners
      )
    }, []);

  return (
    <section className="py-5">
    <div className="">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <h5 className="sec-subtitle2 fw-normal text-center">
              {translations?.sections.partnersTitle}
            </h5>
          </div>
        </div>
      </div>
    <div className="container">
    <div className="row my-5">
        <Slider
             speed={1000}
              arrows={false}
              fade={false}
              dots={false}
              slidesToShow={6}
              slidesToScroll={1}
              swipeToSlide={true}
              infinite={true}
              responsive= {[
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                  },
                },
              ]}
              className="partner-slider"
        >
        {partners.map((partner, index) => (
          <div className="col-auto" key={index}>
            <div className="partner-card-img">
              <img
                src={partner.image}
                alt={partner.name}
              />
            </div>
          </div>
        ))}
        </Slider>
      </div>
    </div>
    </div>
  </section>
  );
}

export default Partners;
