import React from 'react';
import Layout from '../components/Layout';
import Packages from '../components/sections/Packages';

const Pricing = () => {

  return (
    <Layout>
        <Packages />
    </Layout>
  );
}

export default Pricing;
