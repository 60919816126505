import React, { useState, useCallback, useContext } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import { AppContext } from '../context/AppContext';
import { FaLink } from "react-icons/fa6";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from 'react-router-dom';


const Blog = ({ blogs }) => {
    const { translations } = useContext(AppContext);
    const blogCategories = blogs.reduce((acc, blog) => {
      const categoryId = blog.category.id;
      acc[categoryId] = acc[categoryId] || { category: blog.category, count: 0 };
      acc[categoryId].count++;
      return acc;
    }, {});
  
  
  return (
    <Layout>
      <PageHeader title={translations?.defs.blog}  description={translations?.defs.blog_desc} />
      <>
  <section className="vs-blog-wrapper space-top space-extra-bottom">
    <div className="container">
      <div className="row gx-40">
        <div className="col-lg-8">
          {blogs?.map((blog, index) => {
            const created = blog.created_at;
            const dateObject = new Date(created);
            const formattedDate = dateObject.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long' });

            return (
          <div className="vs-blog blog-single" key={index}>
          <div className="blog-img">
            <img src={blog.image} alt="Blog Image" />
            <a className="blog-date">
           {/*   <span className="day">11</span>
              <span className="month">January</span> */}
              {formattedDate}
            </a>
          </div>
          <div className="blog-content">
            <div className="blog-meta">
              <a>
                <i className="fa fa-user" />
                {blog.user.first_name}
              </a>
              <a>
                <i className="fa fa-eye" />
                100 Görüntülenme
              </a>
            </div>
            <h2 className="blog-title">
            <Link to={`/blog/${blog.id}`}>
                {blog.title}
              </Link>
            </h2>
            <p>
              {blog.description}
            </p>
            <Link to={`/blog/${blog.id}`} className="vs-btn style3">
              <i className="fa fa-angle-right" />
              Daha Fazla Oku
            </Link>
          </div>
        </div>
            )
          })}

{/*
<div className="vs-pagination">
      <ul>
        <li className="prev">
          <a>{translations?.actions.prev}</a>
        </li>
        <li>
          <a>1</a>
        </li>
        <li>
          <a>2</a>
        </li>
        <li>
          <a>.....</a>
        </li>
        <li>
          <a>10</a>
        </li>
        <li className="next">
        <a>{translations?.actions.next}</a>
        </li>
      </ul>
    </div>
  */}


        </div>
        <div className="col-lg-4">
          <aside className="sidebar-area">
            <div className="widget widget_search  ">
              <h3 className="widget_title text-capitalize">{translations?.actions.search}</h3>
              <form className="search-form">
                <input type="text" placeholder={translations?.actions.search} />
                <button type="submit">
                  <i className="fa fa-search" />
                </button>
              </form>
            </div>
            <div className="widget widget_categories    ">
  <h3 className="widget_title">{translations?.sections.categories}</h3>
  <ul>
  {Object.values(blogCategories).map((categoryData) => (
      <li key={categoryData.category.id}>
        <a href="/">{categoryData.category.name}</a>
        <span>{categoryData.count}</span>
      </li>
    ))}
  </ul>


            </div>
            <div className="widget   ">
              <h3 className="widget_title">{translations?.sections.recentNews}</h3>
              <div className="recent-post-wrap">
              {blogs.slice(-3).map((recentBlog) => {
                    const created = recentBlog.created_at;
                    const dateObject = new Date(created);
                    const formattedDate = dateObject.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long' });
                return(
    <div className="recent-post" key={recentBlog.id}>
      <div className="media-img">
        <img src={recentBlog.image} alt={recentBlog.title} width={110} height={110} />
      </div>
      <div className="media-body">
        <h4 className="post-title">
          <Link to={`/blog/${recentBlog.id}`} className="text-inherit">
            {recentBlog.title}
          </Link>
        </h4>
        <div className="recent-post-meta">
          <span>{formattedDate}</span>
        </div>
      </div>
    </div>
)})}
              </div>
            </div>
            <div className="widget">

            <h4 className="widget_title">{translations?.sections.galleryPhotos}</h4>
              <div className="sidebar-gallery">
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage1}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage1}
                    target="_blank"
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage2}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage2}
                    target="_blank"
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage3}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage2}
                    target="_blank"
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage4}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage4}
                    target="_blank"
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage5}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage5}
                    target="_blank"
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div className="gallery-thumb">
                  <img
                    src={translations?.defs.galleryImage6}
                    alt="Gallery Image"
                    className="w-100"
                  />
                  <a
                    href={translations?.defs.galleryImage6}
                    target="_blank"
                    className="popup-image gal-btn"
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
              </div>
            </div>
            <div className="widget widget_meta   ">
              <h3 className="widget_title">{translations?.sections.metaLinks}</h3>
              <ul>
                <li>
                  <a href={translations?.defs.metaLink1Href}><FaLink className="me-2" />{translations?.defs.metaLink1Name}</a>
                </li>
                <li>
                  <a href={translations?.defs.metaLink2Href}><FaLink className="me-2" />{translations?.defs.metaLink2Name}</a>
                </li>
                <li>
                  <a href={translations?.defs.metaLink3Href}><FaLink className="me-2" />{translations?.defs.metaLink3Name}</a>
                </li>
                <li>
                  <a href={translations?.defs.metaLink4Href}><FaLink className="me-2" />{translations?.defs.metaLink4Name}</a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </section>
  {/*==============================
    CTA Area
  ==============================*/}
  <section
    className="space-extra"
    data-bg-src="assets/img/bg/blog-single-divider-bg-1-1.jpg"
  >
    <div className="container">
      <div className="row justify-content-between text-center text-lg-start">
        <div className="col-lg-6 mb-40 mb-lg-0">
          <h2 className="mt-n2 h2 mb-3">
            {translations?.sections.getQuoteTitle}
          </h2>
          <p className=" mb-4 pb-2 fs-md col-xl-11">
          {translations?.sections.getQuoteText}
          </p>
          <a href="contact.html" className="vs-btn style2">
            <i className="fa fa-angle-right" />
            {translations?.actions.getQuote}
          </a>
        </div>
        <div className="col-auto d-none d-lg-block">
          <div className="sec-line2" />
        </div>
        <div className="col-lg-auto">
          <h6 className="mt-n1">{translations?.defs.academicLeadTeam}</h6>
          <div className="mini-avater">
            <a href="team-details.html">
              <img src={translations?.sections.leadershipTeamMem1} alt="avatar" />
            </a>
            <a href="team-details.html">
              <img src={translations?.sections.leadershipTeamMem2} alt="avatar" />
            </a>
            <a href="team-details.html">
              <img src={translations?.sections.leadershipTeamMem3} alt="avatar" />
            </a>
            <a href="team-details.html">
              <img src={translations?.sections.leadershipTeamMem4} alt="avatar" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
    </Layout>
  );
}

export default Blog;
