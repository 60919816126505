import React from "react";

const PreLoader = () => {
    return (
        <div className="preloader  ">
        {/* <button className="vs-btn preloaderCls">Cancel Preloader </button> */}
         <div className="preloader-inner">
             <div className="loader"></div>
         </div>
     </div>
    )
}

export default PreLoader;