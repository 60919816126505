import React, { useState, useEffect, useContext } from 'react';
import { FaChevronRight } from "react-icons/fa";
import { useNavigate} from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const CallToAction = () => {

const navigate = useNavigate();
const { translations } = useContext(AppContext);


 const handleSignUp = () => {
    navigate('/register')
 }

  return (
    <section className="space-top background-image position-relative" style={{     backgroundImage: `url(${translations?.defs.ctaImage})`  }}>
    <div className="container space-bottom">
      <div className="d-flex flex-column flex-lg-row ms-4 gap-4">
        <img
          src="/assets/cta-ico-3-1.svg"
          alt=""
          width={116}
          height={116}
          className="position-relative z-2 mt-4 me-auto ms-auto me-lg-5 me-md-3 ms-lg-0 ms-md-0"
        />
        <div className="ms-0 ms-lg-4 ms-md-2">
          <div className="row">
            <div className="col-lg-8">
            <h1 className="cta-title fw-semibold position-relative z-2">
            {translations?.sections.ctaTitle}
          </h1>
          <h4 className="position-relative z-2 mt-3">
          {translations?.sections.ctaSubtitle}
          </h4>
          <a
            href="/about"
            class="vs-btn style2 position-relative z-2 mt-4"
          >
            <span className="btn-icon">
              <FaChevronRight />
            </span>
            {translations?.actions.findOutMore}
          </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default CallToAction;
