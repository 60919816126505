import React, {useEffect, useState} from 'react';
import InputRender from "./render/InputRender";
import {BsArrowLeft, BsFillPeopleFill} from "react-icons/bs";
const RegisterStep = ({auth, userIdHash}) => {
    const [fields, setFields] = useState([]);
    const [field_keys, setFieldkeys] = useState([]);

    useEffect(() => {
        const fetchData = async (url, setter, keysetter) => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }
                const data = await response.json();
                if (data.status === true && Object.values(data.data.inputs).length) {
                    setter(Object.values(data.data.inputs));
                    keysetter(Object.keys(data.data.inputs));
                } else {
                    console.log("Fieldlar Alınamadı");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(
            "https://app.meetods.com/api/v1/register-fields",
            setFields,
            setFieldkeys
        )
    }, []);
    if (!auth) {
        return (
            <>
                <div className="row mt-3 mb-5">
                    <div className="col-lg-6">
                        <div className="d-flex flex-column">
                            <div className="pe-0 pe-lg-5 me-0 me-lg-5">
                                <h5>Hesap Bilgileriniz</h5>
                                <p className="text-muted">
                                    Girdiğiniz bilgiler oluşturulacak olan hesabınızda kullanılacaktır.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={"row"}>
                            {fields.map((field, index) => {
                                return (
                                    <div className={"col-" + field.col} key={index}>
                                        <InputRender field={field} name={field_keys[index]} index={index}/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>

        );
    } else {
        return (
            <>
                <input type={"text"} name={"user_id"} id={"auth_user_id"} value={userIdHash.user_id} required={true} readOnly={true}/>
                <input type={"text"} name={"user_hash"} id={"auth_user_hash"} value={userIdHash.hash} required={true} readOnly={true}/>
            </>
        );
    }
};

export default RegisterStep;
