import React, { useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { useNavigate} from 'react-router-dom';
import { useParams} from 'react-router-dom';




const Authentication = ({  }) => {

    const { hash } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useContext(AppContext);

    useEffect(() => { 

            const fetchData = async () => {
                try {
                  const hashValue = hash;
                  const response = await fetch('https://app.meetods.com/api/v1/authorize', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ hash: hashValue }),
                  });
          
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
          
                  const data = await response.json();
          
                  if (data.status) {
                    localStorage.removeItem('hash');
                      localStorage.setItem('user_id', data.user_id);
                      localStorage.setItem('hash', data.hash);
                      localStorage.setItem('firstName', data.first_name);
                      localStorage.setItem('lastName', data.last_name);
                      localStorage.setItem('email', data.email);
                      localStorage.setItem('phone_number', data.phone_number);
                    navigate('/booking')
                  } else {
                   console.error('Authentication failed');
                  }
                } catch (error) {
                  console.error('Error during authentication:', error.message);
                }
              };
              fetchData()
    
      }, []);

  return (
    <>
        Authentication
    </>
  );
};

export default Authentication;
