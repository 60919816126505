import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BsFillPeopleFill } from "react-icons/bs";
import {
  AiOutlineCalendar,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import { FaVideo } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { MdRestartAlt } from "react-icons/md";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { AppContext } from "../context/AppContext";
import Header from "../components/Header";

const Confirmation = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams();

  
  const { user, setUser } = useContext(AppContext);

  const { setCurrentStep } = useContext(AppContext);
  const selectedBooking = user?.bookings.find((booking) => booking.id == bookingId);

  
  const selectedDate = new Date(selectedBooking?.date);
  const selectedTime = selectedBooking?.time;
  const timezone = selectedBooking?.timezone;
  const participants = selectedBooking?.participants;


  const formattedDate = selectedDate?.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false, 
  });

  const currentDate = new Date();
  const timestamp1 = selectedDate?.getTime();
  const timestamp2 = currentDate.getTime();

  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);
  const oneHourInMilliseconds = 60 * 60 * 1000;

  if (differenceInMilliseconds <= oneHourInMilliseconds) {
    console.log("within 1 hour");
  } else {
    console.log("not");
  }

  const [isCallButtonActive, setIsCallButtonActive] = useState(
    differenceInMilliseconds <= oneHourInMilliseconds
  );

  const handleReschedule = () => {
    navigate("/");
    setCurrentStep(0);
  };

  const handleCancel = () => {
    selectedBooking.status = "canceled";
    setUser((prevUser) => ({
      ...prevUser,
      bookings: prevUser.bookings.map((booking) =>
        booking.id === selectedBooking.id ? selectedBooking : booking
      ),
    }));
  }



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Header />
        <div className="row">
      <div className="col-12 col-lg-6 mx-auto">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span className="text-success mb-4">
            <AiFillCheckCircle size="24" />
          </span>
          <h5>Confirmed! You are scheduled with Team</h5>
          <p>
            A calendar invite has been sent to participants.
          </p>
        </div>
        <div className="card bg-white border-0 shadow mb-5">
          <div className="card-body d-flex flex-column align-items-center">

            <div className="meeting-info-header w-100 w-lg-75">
              <h5 className="mb-3">Meeting Details</h5>
            </div>

           <div className="w-100 w-lg-75">
              <div className="d-flex align-items-center gap-2 mb-3">
                <AiOutlineCalendar size="24" />
                <p className="mb-0">{formattedDate}</p>
  </div> 
  <div className="d-flex align-items-start gap-2 mb-3">
                <FaVideo size="24" />

                <div className="d-flex flex-column">
                  <p className="mb-0">Link to video call:</p>
                  <a
                    href="https://meetlink.com/code/2301-203"
                    className="link-primary"
                    target="_blank"
                  >
                    meetlink.com/code/2301-203
                  </a>
                </div>
              </div>
                            
              {participants?.length > 0 && (
                  <div className="d-flex align-items-start gap-2 mb-3">
                  <BsFillPeopleFill size="24" />
                  <ul className="list-unstyled ms-1 mb-0">
                  {participants.map((participant, index) => (
                    <li key={index}>
                      <a href={`mailto:${participant.email}`} className="link-dark mb-0">
                        {participant.email}
                        </a>
                    </li>
                  ))}
                  </ul>
                </div>
                  )}   

              <div className="d-flex align-items-center gap-2 mb-3">
                <BiWorld size="24" />
                <p className="mb-0">Timezone:</p>
                <p className="mb-0">{timezone}</p>
                </div> 
  </div>

              <div className="w-100 d-flex justify-content-center my-4">
                {isCallButtonActive ? (
                  <button className="btn btn-secondary fw-bold px-5" onClick={() => alert("Starting...")}>
                    Start call
                  </button>
                ) : (
                  <>
                    <span
                      data-tooltip-id="call-tooltip"
                      data-tooltip-content="Call will be available 1 hour prior the scheduled start"
                      data-tooltip-place="top"
                    >
                      <button
                        className="btn btn-secondary fw-bold px-5"
                        style={{ pointerEvents: "none" }}
                        disabled
                      >
                        Start call
                      </button>
                    </span>
                    <Tooltip id="call-tooltip" />
                  </>
                )}
                </div> 


                <div className="w-100 d-flex flex-column flex-lg-row justify-content-between">
              <div className="d-flex">
                <div className="support-logo bg-primary text-white me-3 d-flex align-items-center justify-content-center">
                  logo
                </div>
                <div className="d-flex flex-column justify-content-start gap-2">
                  Support Team
                  <a href="mailto:support@gmail.com" className="link-dark">
                    support@gmail.com
                  </a>
                </div>
              </div>
              <div className="d-flex flex-row flex-lg-column gap-1">
                <button
                  className="btn btn-light d-flex align-items-center gap-2"
                  onClick={() => handleReschedule()}
                >
                  <MdRestartAlt size="24" />
                  Reschedule
                </button>
                <button className="btn btn-light d-flex align-items-center gap-2" onClick={() => handleCancel()}>
                  <AiFillCloseCircle size="24" />
                  Cancel
                </button>
                </div> 

            </div>


            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
