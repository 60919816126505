import React, { useState, useEffect, useRef, useContext } from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from '../../context/AppContext';


const Testimonials = ({}) => {

    const [testimonials, setTestimonials] = useState([]);
    const { translations } = useContext(AppContext);


    useEffect(() => {
        const fetchData = async (url, setter) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            const data = await response.json();
            setter(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData(
          "https://webapi.meetods.com/api/v1/testimonials",
          setTestimonials
        )
      }, []);

    var settings1 = {
        speed: 1000,
        arrows: false,
        fade: false,
        dots: false,
        vertical: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        infinite: true,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                vertical: false,
                centerMode: false,
              },
            },
            {
              breakpoint: 992,
              settings: {
                vertical: false,
                centerMode: false,
                slidesToShow: 2,
              },
            },
          ],
      };

      var settings2 = {
        speed: 1000,
        arrows: false,
        fade: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        infinite: true,
        asNavFor: '.testi-slide1',
        responsive: [
            {
              breakpoint: 992,
              settings: {
                dots: false,
              },
            },
          ],
      };




      const [nav1, setNav1] = useState(null);
      const [nav2, setNav2] = useState(null);
      const slider1 = useRef(null);
      const slider2 = useRef(null);
    
      useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
      }, []);

      



  return (
    <section className="space-top shape-mockup-wrap">
    <div className="container">
    <div class="shape-mockup dotted jump d-none d-xxl-block" style={{right: '14%', bottom: '34%'}}>
            <div class="shape-dotted"></div>
        </div>
        <div class="shape-mockup rotate d-none d-xxl-block" style={{top: '17%', right: '48%'}}>
            <div class="vs-polygon1"></div>
        </div>
        <div class="shape-mockup jump-reverse d-none d-xxl-block" style={{top: '21%', right: '51%'}}>
            <div class="vs-rounded1"></div>
        </div>
      <div className="row my-5">
        <div className="col-lg-5">
        <div className="d-flex flex-column align-items-start">
            <h5 className="sec-subtitle">{translations?.sections.testiTitle}</h5>
            <h1 className="display-4 fw-semibold text-start mb-3 mb-md-2 mb-lg-5">
            {translations?.sections.testiSubtitle}
            </h1>
          </div>







<Slider asNavFor={nav2} ref={slider1}
      speed={1000}
      arrows={false}
      fade={false}
      dots={false}
        vertical={true}
              slidesToShow={3}
              slidesToScroll={1}
              focusOnSelect={true}
              infinite={true}
              centerMode={true}
              centerPadding={'0'}
              className="testi-slide1"
              responsive= {[
                {
                  breakpoint: 1199,
                  settings: {
                    vertical: false,
                    centerMode: false,
                  },
                },
                {
                  breakpoint: 992,
                  settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 2,
                  },
                },
              ]}
      
      >
      {testimonials.map((testi, index) => (
                                  <div key={index}>
                                    <div class="media-style6">
                                      <div class="media-img">
                                        <img src={testi.user_avatar} alt={testi.name} />
                                      </div>
                                      <div class="media-content">
                                        <h5 class="media-name">{testi.name}</h5>
                                        <p class="media-degi">{testi.profession}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
      </Slider>


            </div> 


        <div className="col-lg-7 d-flex justify-content-center justify-content-lg-end">
            <div className="testi-style2">
                <div className="testi-shape1 d-none d-lg-block"></div>
<Slider
        asNavFor={nav1}
        ref={slider2}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        dots={true}
        speed={1000}
        arrows={false}
        fade={true}
        slidesToScroll={1}
        infinite={true}
      >
            {testimonials.map((testi, index) => (
              <div key={index}>
                <div class="testi-content">
                  <span class="testi-icon">
                    <img src="assets/quote.svg" alt="Testimonial Icon" width={108} height={64} />
                  </span>
                  <p class="testi-text">{testi.review}</p>
                  <h4 class="testi-name">{testi.name}</h4>
                  <p class="testi-degi">{testi.profession}</p>
                </div>
              </div>
            ))}
      </Slider>

          </div>
        </div>
            


      </div>
    </div>
  </section>
  );
}

export default Testimonials;
